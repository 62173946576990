<template>
<div>
  <!-- Intro -->
  <div class="mb-lg">
    <p class="has-text-weight-bold has-text-info has-text-centered mb-md">Import Results</p>
  </div>
  <div v-if="!loadingFeed && detail !== null">
    <div class="mb-lg">
      <Progress :feed="detail" size="is-large" :emitRefresh="true" @refresh="loadFeed(true)" />
    </div>
    <!-- Feed Details -->
    <div class="mb-lg">
      <p class="has-text-centered mb-sm is-size-7">
        <a href="#" class="has-text-info" @click.prevent="showDetails = !showDetails">View additional import details</a>
      </p>
      <div v-if="showDetails">
        <Results :feed="detail" />
      </div>
    </div>
  </div>
  <div v-else>
    <loading message="Loading import details..." />
  </div>
  <!-- Navigate -->
  <div class="pt-lg">
    <div class="columns is-centered">
      <div class="column">
        <b-button
          type="is-light rounded-lg"
          size="is-medium"
          @click="goBack"
          expanded
          ><b-icon icon="arrow-left"></b-icon>
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-primary rounded-lg"
          size="is-medium"
          expanded
          :disabled="!ready || saving"
          :loading="saving"
          @click="goForward"
        >Continue</b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Progress from '@/components/Models/FeedQueue/Presenters/Progress'
import Results from '@/components/Models/FeedQueue/Presenters/Results'

export default {
  components: {
    Progress,
    Results
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      if (this.feed === null || this.detail == null || this.detail.status === 'failure') return false
      return (
        this.detail.status === 'success' && (this.detail.num_created > 0 || this.detail.num_updated > 0)
      )
    }
  },
  data () {
    return {
      loadingFeed: true,
      detail: null,
      showDetails: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.$emit('navigate', 'Compatibility')
      // this.saving = true
      // const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      // this.$http.put(endpoint).then(() => {
      //   this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
      //   this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      //   this.saving = false
      // })
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.detail = response.data
        this.loadingFeed = false
      })
    }
  },
  mounted () {
    this.loadFeed()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    feed: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object
    }
  }
}
</script>
