<template>
<div>
  <!-- Content -->
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Integrations" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Integrations</h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="">
        <div class="has-background-white pa-md rounded-lg mb-xs">
          <div class="columns is-vcentered space-between">
            <div class="column">
              <p class="has-text-grey-light">{{ accounts.length }} {{ (accounts.length) === 1 ? 'Integration' : 'Integrations' }}</p>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" outlined @click="showIntegrationModal = true">
                Add Integration
              </b-button>
            </div>
          </div>
        </div>
        <!-- Loaded -->
        <div v-if="!loading && accounts.length > 0">
          <div v-for="account in accounts" :key="account.id" class="has-background-white rounded-lg pa-lg my-xxs">
            <div v-if="account.platform.code === 'returnly'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'" style="width:120px" alt="returnly logo."/>
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.returnly', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.returnly.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-if="account.platform.code === 'fabric'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'" style="width:120px" alt="fabric logo."/>
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.returnly', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.returnly.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-if="account.platform.code === 'bill'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/static/integrations/billcom.png'" style="width:120px" alt="bill.com logo."/>
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.returnly', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.returnly.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-else-if="account.platform.code === 'shipstation'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'" style="width:120px" alt="shipstation logo."/>
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.shipstation', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.shipstation.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.shipstation.utilities', params: { id: account.id} }">Utilities</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-else-if="account.platform.code === 'shopify'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'" style="width:120px" />
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown  position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.shopify', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.shopify.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'shopify.templates', params: { id: account.id} }">Shopify Templates</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.shopify.utilities', params: { id: account.id} }">Utilities</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-else-if="account.platform.code === 'bigcommerce'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'" style="width:120px" />
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown  position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.bigcommerce', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="context.isMerchant()" aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.bigcommerce.utilities', params: { id: account.id} }">Utilities</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.bigcommerce.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-else-if="account.platform.code === 'woocommerce'">
              <div class="columns is-vcentered">
                <div class="column">
                  <figure class="image is-3x1">
                    <img :src="'https://res.cloudinary.com/revcascade/image/upload/v1673974346/static/integrations/woocommerce.png'" style="width:120px" />
                  </figure>
                </div>
                <div class="column is-narrow">
                  <b-dropdown  position="is-bottom-left">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Options</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item aria-role="listitem" has-link>
                    <router-link :to="{ name: 'settings.integrations.woocommerce', params: { id: account.id} }">Configuration</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" has-link>
                      <router-link :to="{ name: 'settings.integrations.woocommerce.webhooks', params: { id: account.id} }">Webhook History</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Loaded (Empty) -->
        <div v-else-if="!loading && accounts.length === 0">
          <div class="has-background-white pa-md rounded-lg mb-xs">
            <div class="my-xxl has-text-grey-light has-text-centered">
              <p>No integrations added yet.</p>
              <p>Click "Add Integration above to add integration credentials.</p>
              <p class="py-lg">
                <b-button type="is-primary" @click="showIntegrationModal = true">
                  Add Integration
                </b-button>
              </p>
            </div>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <div class="has-background-white pa-md mb-xs rounded-xl" v-for="index in 3" :key="index">
            <div class="placeholder placeholder-title" style="width:66%"></div>
            <div class="placeholder placeholder-sentence" style="width:50%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Integration Modal -->
  <b-modal
    :active.sync="showIntegrationModal"
    :can-cancel="['escape', 'x']"
    has-modal-card
    scroll="keep"
    @close="account = null">
    <PlatformAccountModal @updated="load" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountModal from '@/components/Models/PlatformAccount/PlatformAccountModal'
export default {
  components: {
    PlatformAccountModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/platform-accounts/'
    }
  },
  data () {
    return {
      loading: true,
      accounts: [],
      showIntegrationModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        const validIntegrations = this.context.isMerchant() ? ['returnly', 'fabric', 'bigcommerce', 'shopify'] : ['returnly', 'shipstation', 'bigcommerce', 'shopify']
        if (response.data.results && response.data.count > 0) {
          this.accounts = response.data.results.filter(el => validIntegrations.includes(el.platform.code))
        }
        this.accounts = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
