<template>
  <div>
      <div class="columns">
        <div class="column">
          <!-- Tag Delimiter -->
          <form v-if="this.fieldsType === 'tag-mappings'" @submit.prevent="updateDelimiter">
            <div class="has-background-white rounded-lg pa-md mb-lg">
              <div v-if="!loading">
                <div class="columns is-vcentered" style="align-items:flex-end">
                  <div class="column">
                    <div class="mb-sm">
                      <p><label class="label">Tag Delimiter (optional)</label></p>
                      <p class="is-size-7 mb-lg">
                        When adding tags to Shopify, it can be useful to separate a tag into a key:value format
                        separated by a delimiter (e.g. "::" or "=>"). You can configure your delimiter below (or just leave it blank)
                      </p>
                    </div>
                    <b-field label="">
                      <b-input
                        name="tagDelimiter"
                        v-model="tagDelimiter"
                        @input="isDirty = true"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      native-type="submit"
                      type="is-primary rounded-lg"
                      :disabled="!ready || saving || !isDirty"
                      :loading="saving"
                    >Update Delimiter</b-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-grey-light is-size-7">
                  <span v-if="!loading">{{ mappings.length }} {{ (mappings.length === 1) ? 'Mapping' : 'Mappings' }}</span>
                  <span v-else>Loading...</span>
                </p>
              </div>
              <div class="column is-narrow">
                <b-button
                  type="is-primary"
                  size="is-small"
                  outlined
                  @click="showEditMappingModal = true"
                >Add Mapping</b-button>
              </div>
            </div>
          </div>
          <div class="has-background-white rounded-lg pa-md">
            <b-table
              class="is-size-7"
              narrowed
              hoverable
              style="cursor: pointer"
              :data="mappings"
              :loading="loading"
              :mobile-cards="false"
              @click="editMapping($event, 'showEditMappingModal')">
              <template>
                <!-- Marketplace Attribute -->
                <b-table-column
                  :label="app.platform + ' Attribute'"
                  cell-class="nowrap"
                  v-slot="props">
                  <p class="has-text-weight-bold has-text-grey">{{ props.row.attribute.name }}</p>
                </b-table-column>
                <!-- Integration Type Attribute -->
                <b-table-column
                  v-if="fieldsType!='metafield-mappings'"
                  :label="getAttributeLabel"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ props.row.title || props.row.name }}</p>
                </b-table-column>
                <!-- Tag Mappings - Preview -->
                <b-table-column
                  v-if="fieldsType==='tag-mappings'"
                  label="Preview"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ (props.row.title || props.row.name) + tagDelimiter + props.row.attribute.name }}</p>
                </b-table-column>
                <!-- Metafield Mappings - Namespace -->
                <b-table-column
                  v-if="fieldsType==='metafield-mappings'"
                  label="Metafield Namespace"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ props.row.namespace }}</p>
                </b-table-column>
                <!-- Metafield Mappings - Key -->
                <b-table-column
                  v-if="fieldsType==='metafield-mappings'"
                  label="Metafield Key"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ props.row.key }}</p>
                </b-table-column>
                <!-- Metafield Mappings - Type -->
                <b-table-column
                  v-if="fieldsType==='metafield-mappings'"
                  label="Metafield Type"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ props.row.type }}</p>
                </b-table-column>
                <!-- Remove options -->
                <b-table-column
                  cell-class="nowrap has-text-centered"
                  v-slot="props"
                  width="60">
                  <p>
                    <a href="#" @click.stop="editMapping(props.row, 'showDeleteMappingModal')">
                      <b-icon icon="close" />
                    </a>
                  </p>
                </b-table-column>
              </template>
              <template #empty>
                <p class="has-text-grey-light has-text-centered py-lg">No mappings added</p>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    <!-- Edit Mapping Modal -->
    <b-modal
      has-modal-card
      :active.sync="showEditMappingModal"
      :can-cancel="['escape', 'x']"
      @close="mapping = null"
      ><EditExportMappingModal :mapping="mapping" @mapping:saved="load" :templateType="templateType" :fieldsType="fieldsType" :attributeLabel="getAttributeLabel"/>
    </b-modal>
    <!-- Delete Mapping Modal -->
    <b-modal
      has-modal-card
      :active.sync="showDeleteMappingModal"
      :can-cancel="['escape', 'x']"
      @close="mapping = null"
      ><DeleteMappingModal :mapping="mapping" @mapping:saved="load" :templateType="templateType" :fieldsType="fieldsType"/>
    </b-modal>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import EditExportMappingModal from './EditExportMappingModal'
import DeleteMappingModal from './DeleteMappingModal'
export default {
  components: {
    EditExportMappingModal,
    DeleteMappingModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return this.isDirty
    },
    endpoint () {
      return this.context.route + `/${this.templateType}/${this.$route.params.id}/templates/${this.$route.params.templateId}/${this.fieldsType}/`
    },
    updateDelimiterEndpoint () {
      return this.context.route + `/${this.templateType}/${this.$route.params.id}/templates/${this.$route.params.templateId}/`
    },
    getAttributeLabel () {
      const templateTypeCapitalized = this.templateType.charAt(0).toUpperCase() + this.templateType.slice(1)

      switch (this.fieldsType) {
        case 'tag-mappings':
          return 'Prefix'
        default:
          return `${templateTypeCapitalized} Attribute`
      }
    }
  },
  data () {
    return {
      loading: false,
      mappings: [],
      mapping: null,
      showEditMappingModal: false,
      showDeleteMappingModal: false,
      tagDelimiter: null,
      isDirty: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const query = {
        params: {
          pagination: 0
        }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.mappings = response.data.results
        this.loading = false
      })
      this.tagDelimiter = this.template.tag_delimiter
    },
    editMapping (mapping, action) {
      this.mapping = mapping
      this[action] = true
    },
    updateDelimiter () {
      this.saving = true
      const body = {
        tag_delimiter: this.tagDelimiter
      }
      this.$http.patch(this.updateDelimiterEndpoint, body).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Tag Delimiter Updated!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: {
    template: {
      type: Object,
      required: true
    },
    templateType: {
      type: String,
      enum: ['bigcommerce', 'shopify'],
      default: ''
    },
    fieldsType: {
      type: String,
      enum: ['mappings', 'custom-fields-mappings', 'tag-mappings', 'metafield-mappings'],
      default: 'mappings'
    }
  }
}
</script>
