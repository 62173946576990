<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add {{ platform.name }} Integration</p>
      </header>
      <section class="modal-card-body">
        <div class="has-text-centered">
          <img :src="platform.logo" width="180" height="60" />
        </div>
        <div class="mb-lg">
          <!-- Shop Name -->
          <b-field label="Shopify Store URL">
            <p class="control">
              <span class="button is-static is-medium">https://</span>
            </p>
            <b-input v-model="storeName" @input="isDirty = true" expanded size="is-medium" placeholder="Shopify Store Name"></b-input>
            <p class="control">
              <span class="button is-static is-medium">.myshopify.com</span>
            </p>
          </b-field>
          <!-- Shop Token -->
          <b-field label="API Key">
            <b-input v-model="storeToken" expanded size="is-medium" placeholder="Shopify API Key"></b-input>
          </b-field>
          <!-- Key Documentation -->
          <div class="mt-xl has-text-centered">
            <p v-if="context.isMerchant()"><a class="has-text-info" target="_blank" href="https://res.cloudinary.com/revcascade/image/upload/v1664948385/static/resources/fabricMarketplace_MerchantShopifyAppSetup.pdf" downloadclass="has-text-info">Learn how to obtain an API Key</a></p>
            <p v-if="context.isSupplier()"><a class="has-text-info" target="_blank" href="https://res.cloudinary.com/revcascade/image/upload/v1664948385/static/resources/fabricMarketplace_ShopifyAppSetup.pdf" downloadclass="has-text-info">Learn how to obtain an API Key</a></p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$emit('canceled')">Back</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Add Integration</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context', 'services']),
    ready () {
      return (this.storeName && this.storeToken)
    },
    redirectUrl () {
      return this.app.url + '/onboarding'
    },
    installUrl () {
      if (this.account === null) return null
      return this.api.baseUrl + '/shopify/request-permission/?shop=' + this.storeName + '.myshopify.com'
    }
  },
  data () {
    return {
      storeName: null,
      storeToken: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'shopify' },
        credentials: [{
          credential: { code: 'shopify_shop_name' },
          value: this.storeName
        }, {
          credential: { code: 'shopify_token' },
          value: this.storeToken
        }, {
          credential: { code: 'shopify_private_app' },
          value: 1
        }]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('account:saved')
        this.$parent.close()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['platform']
}
</script>
