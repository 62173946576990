<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Onboarding Tasks -->
      <div class="mb-lg">
        <div class="columns is-centered">
          <div class="column is-three-fifths-desktop is-half-widescreen">
            <!-- Has Tasks -->
            <div v-if="!loading && platformTasks.length > 0">
              <!-- Welcome Task Not Done -->
              <div v-if="welcomeTask && welcomeTask.completed_at === null" class="mb-lg">
                <Welcome :connection="connection" :task="welcomeTask" @completed="loadPlatformTasks" />
              </div>
              <div v-else>
                <!-- Platform Onboarding Tasks -->
                <div class="mb-xl">
                  <div class="mb-lg">
                    <div class="columns is-vcentered">
                      <p class="column has-text-weight-bold is-size-5">Onboarding Tasks</p>
                      <div class="column has-text-right is-clickable" @click.prevent="showMessages()">
                        <span class="has-text-weight-bold mb-lg">Messages </span>
                        <span :class="(hasUnread) ? 'has-text-danger' : ''">
                          ({{ connection.memos.length }})
                        </span>
                      </div>
                    </div>
                  </div>
                  <TaskList :tasks="platformTasks" />
                </div>
                <!-- Complete Onboarding -->
                <div class="mb-lg">
                  <!-- Onboarding is Completed -->
                  <div v-if="connection.onboarded_at" class="py-md has-text-centered mb-lg has-text-weight-bold">
                    <p>Completion Date</p>
                    <p class="has-text-success is-size-5 mb-sm">{{ $dates.utc(connection.onboarded_at).local().format('MMM D, YYYY') }}</p>
                    <p class="is-size-7 has-text-weight-normal has-text-grey">
                      {{ connection.retailer.name }} is reviewing your account and should be activating your connection soon.
                    </p>
                  </div>
                  <!-- Onboarding is Completed -->
                  <div v-else-if="connection.onboarding_target_date" class="py-md has-text-centered mb-lg has-text-weight-bold">
                    <p>Expected Completion Date</p>
                    <p class="has-text-warning is-size-5 mb-sm">{{ $dates.utc(connection.onboarding_target_date).local().format('MMM D, YYYY') }}</p>
                    <p class="is-size-7 has-text-weight-normal has-text-grey">
                      {{ connection.retailer.name }} is expecting to launch your products
                      on or around the date above. To avoid unexpected delays, please try to complete onboarding
                      by the expected completion date.
                    </p>
                  </div>
                  <!-- Reset Welcome Task -->
                  <div class="has-text-centered mb-lg has-text-weight-bold">
                    <p class="has-text-grey-light has-text-centered is-size-7">
                      <a href="#" @click.prevent="showResetTaskModal = true" class="has-text-info">Click here</a> to reset the initial onboarding setup.
                    </p>
                  </div>
                  <!-- Complete -->
                  <div class="columns is-centered">
                    <div class="column is-half">
                      <b-button
                        type="is-primary"
                        :loading="saving"
                        :disabled="!ready || saving || connection.onboarded_at"
                        size="is-medium rounded-lg"
                        expanded
                        @click="completeOnboarding"
                      >Complete Onboarding</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- No Tasks -->
            <div v-else-if="!loading && platformTasks.length === 0">
              <div class="has-background-white rounded-lg px-md py-xxl mb-xxs has-text-centered">
                <p class="has-text-success has-text-weight-bold is-size-5">Onboarding Complete!</p>
                <p>No outstanding onboarding tasks found!</p>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <div class="mb-lg">
                <div class="columns is-centered">
                  <div class="column is-half">
                    <b-skeleton />
                  </div>
                </div>
              </div>
              <div v-for="index in 5" :key="index">
                <div class="has-background-white rounded-lg px-md py-md mb-xxs">
                  <div class="columns">
                    <div class="column">
                      <b-skeleton width="50%" />
                      <b-skeleton />
                    </div>
                    <div class="column is-one-quarter">
                      <b-skeleton />
                      <b-skeleton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Welcome Task Modal -->
  <b-modal
    v-if="!loading && welcomeTask && platformTasks.length"
    :has-modal-card="true"
    :active.sync="showResetTaskModal"
    :can-cancel="['x', 'esc']">
    <ResetTaskModal :taskId="welcomeTask.id" @task:reset="onTaskReset" />
  </b-modal>
  <!-- Messages Modal -->
  <b-modal
    v-if="!loading && connection.id"
    :has-modal-card="true"
    :active.sync="showMessagesModal"
    :can-cancel="['x', 'esc']">
    <MemosModal :connection="connection"/>
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
import MemosModal from '@/components/Models/Connection/MemosModal'
import TaskList from '../components/Tasks/TaskList'
import Welcome from './Welcome'

export default {
  components: {
    TaskList,
    Welcome,
    ResetTaskModal,
    MemosModal
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return this.loadingPlatformTasks
    },
    ready () {
      let ready = true
      this.platformTasks.forEach(task => {
        if (task.completed_at === null) {
          ready = false
        }
      })
      return ready
    },
    hasUnread () {
      const unRead = this.$utils.find(this.connection.memos, (o) => {
        return o.acknowledged_at === null
      })
      return unRead
    }
  },
  data () {
    return {
      loadingPlatformTasks: true,
      platformTasks: [],
      welcomeTask: null,
      feedbackTask: null,
      saving: false,
      errors: {},
      showResetTaskModal: false,
      showMessagesModal: false
    }
  },
  methods: {
    loadPlatformTasks () {
      const endpoint = this.context.route + '/assigned-tasks/'
      this.loadingPlatformTasks = true
      this.$http.get(endpoint).then(response => {
        this.platformTasks = []
        response.data.results.forEach(task => {
          if (task.onboarding_task.code === 'welcome') {
            this.welcomeTask = task
          // } else if (task.onboarding_task.code === 'feedback') {
          //   this.feedbackTask = task
          } else {
            this.platformTasks.push(task)
          }
        })
        this.loadingPlatformTasks = false
      })
    },
    completeOnboarding () {
      this.saving = true
      const endpoint = this.context.route + '/connections/' + this.connection.id + '/complete-onboarding/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Onboarding completed!' })
        this.$router.push({ name: 'onboarding.tasks.complete' })
      })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.loadPlatformTasks()
    },
    showMessages () {
      this.showMessagesModal = true
    }
  },
  mounted () {
    this.loadPlatformTasks()
  },
  props: ['connection']
}
</script>
