<template>
<div>
  <div class="pa-md">
    <!-- Task is loaded -->
    <div v-if="!loading && task !== null">
      <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">Import Products </p>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component
                :is="step"
                :connection="connection"
                :task="task"
                :feed="feed"
                :proposal="proposal"
                @proposal:created="trackProposal"
                @feed:queued="trackFeed"
                @proposal:refresh="loadProposal(true)"
                @feed:refresh="loadFeedQueue(true)"
                @task:udpated="trackTask"
                @task:reset="resetTaskInfo"
                @navigate="navigate"
              ></component>
            </transition>
            <!-- Cancel Task Option -->
            <div class="pt-lg">
              <p class="has-text-grey-light has-text-centered is-size-7">
                This onboarding task was added based on your preferences. <br/>
                If you no longer need this task, you can remove it
                by <a href="#" @click.prevent="showDeleteTaskModal = true" class="has-text-info">clicking here.</a>
              </p>
            </div>
          </div>
          <!-- Return & Reset Links -->
          <div class="columns is-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="column is-size-7 ml-sm" :class="!isCompleted && 'has-text-centered'">
              Back to Tasks
            </router-link>
            <a v-if="isCompleted" href="#" class="column is-size-7 has-text-right mr-sm" @click="showResetTaskModal=true">
              Reset Task
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Skeleton -->
    <div v-else>
      <div class="mb-md">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <b-skeleton />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <loading message="Loading..." />
          </div>
          <!-- Return Link -->
          <div class="has-text-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7">
              Back to Tasks
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Task Modal -->
  <b-modal
    v-if="!loading && task !== null"
    :has-modal-card="true"
    :active.sync="showDeleteTaskModal"
    :can-cancel="['x', 'esc']">
    <RemoveTaskModal :taskId="task.id" @task:removed="returnToTasks" />
  </b-modal>
  <!-- Reset Task Modal -->
  <b-modal
    v-if="!loading && task !== null"
    :has-modal-card="true"
    :active.sync="showResetTaskModal"
    :can-cancel="['x', 'esc']">
    <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import RemoveTaskModal from '@/components/Models/Task/Modals/RemoveTaskModal'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
import Intro from '../components/ImportTemplate/Intro'
import Progress from '../components/ImportTemplate/Progress'
import Compatibility from '../components/ImportTemplate/Compatibility'
import ProposalSubmitted from '../components/ImportTemplate/ProposalSubmitted'

export default {
  components: {
    RemoveTaskModal,
    ResetTaskModal,
    Intro,
    Progress,
    Compatibility,
    ProposalSubmitted
  },
  computed: {
    ...mapGetters(['app', 'context']),
    isCompleted () {
      return this.task && this.task.completed_at !== null
    },
    loading () {
      return (this.loadingTask || this.loadingProposal || this.loadingFeedQueue)
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingProposal: false,
      loadingFeedQueue: false,
      task: null,
      step: 'Intro',
      feed: null,
      proposal: null,
      showDeleteTaskModal: false,
      showResetTaskModal: false
    }
  },
  methods: {
    loadTask (refresh = false) {
      if (!refresh) this.loadingTask = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.task = response.data
        if (typeof this.task.info === 'object' && 'proposal_id' in this.task.info) {
          this.loadingProposal = true
          this.loadProposal(refresh)
        }
        if (typeof this.task.info === 'object' && 'feed_queue_id' in this.task.info) {
          this.loadingFeedQueue = true
          this.loadFeedQueue(refresh)
        }
        this.loadingTask = false
      })
    },
    loadProposal (refresh = false) {
      if (!('proposal_id' in this.task.info)) return false
      if (!refresh) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.task.info.proposal_id + '/'
      this.$http.get(endpoint).then(response => {
        this.proposal = response.data
        this.loadingProposal = false
      })
    },
    loadFeedQueue (refresh = false) {
      if (!('feed_queue_id' in this.task.info)) return false
      if (!refresh) this.loadingFeedQueue = true
      const endpoint = this.context.route + '/feed-queue/' + this.task.info.feed_queue_id + '/'
      this.$http.get(endpoint).then(response => {
        this.feed = response.data
        this.loadingFeedQueue = false
      })
    },
    resetTaskInfo () {
      this.proposal = null
      this.feed = null
      this.loadTask()
    },
    returnToTasks () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task removed.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    },
    trackTask (task) {
      this.task = task
    },
    trackProposal (proposal) {
      this.proposal = proposal
    },
    trackFeed (feed) {
      this.feed = feed
    },
    navigate (step) {
      this.step = step
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  mounted () {
    this.loadTask()
  },
  props: {
    connection: {
      type: Object
    }
  }
}
</script>
