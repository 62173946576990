<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Rule Validators</p>
      </header>
      <section class="modal-card-body">
        <div v-if="errors && 'non_field_errors' in errors" class="mb-lg">
          <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
        </div>
        <div>
          <b-field label="Name" horizontal>
            <!-- for reference only cannot edit -->
            <b-input :disabled="true" v-model="name"></b-input>
          </b-field>
          <b-field label="Type" horizontal>
            <b-select placeholder="Select a type" v-model="option" expanded>
              <option
                v-for="option in allTypes"
                :value="option"
                :key="option.id">
                {{ option.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Value" horizontal>
            <b-input
              v-if="option && option.id !== 'in'"
              type="number"
              autocomplete="off"
              v-model="value"
              :controls="false"
              :min="0"
              step="0.01"
              :disabled="isDisabled"
              >
            </b-input>
            <div v-if="option && option.id === 'in'" label="Value" horizontal>
              <b-input v-model="value" type="textarea"></b-input>
              <p class="is-size-7 has-text-grey">Separate values by comma</p>
            </div>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <div class="space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            v-if="isEdit"
            :disabled="saving"
            :loading="saving"
            @click="onDelete"
            >Delete</b-button>
        </div>
        <div>
          <b-button
            :disabled="saving || !isReady"
            :loading="saving"
            type="is-primary"
            @click="save"
          >Save</b-button>
        </div>
      </footer>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + `/compatibility/rulesets/${this.$route.params.ruleset}/rules/${this.rule.id}/`
    },
    isReady () {
      const isReady = this.name && this.name !== null && this.option
      if (isReady && this.booleanType.includes(this.option.id)) {
        return isReady
      } else {
        return (isReady && this.value && this.value !== null)
      }
    },
    stepCount () {
      if (this.option && ['max_length', 'min_length'].includes(this.option.id)) {
        return '1'
      } else {
        return '0.01'
      }
    },
    isDisabled () {
      if (this.option) {
        return this.booleanType.includes(this.option.id)
      } else {
        return (!this.option)
      }
    }
  },
  data () {
    return {
      saving: false,
      name: null,
      isDirty: false,
      isEdit: false,
      errors: {},
      option: { label: 'In', id: 'in' },
      value: null,
      allTypes: [
        { label: 'In', id: 'in' },
        { label: 'Max Length', id: 'max_length' },
        { label: 'Min Length', id: 'min_length' },
        { label: 'Max Value', id: 'max_value' },
        { label: 'Min Value', id: 'min_value' },
        { label: 'Integer Only', id: 'integer_only' },
        { label: 'Numeric Only', id: 'numeric_only' },
        { label: 'Alpha Only', id: 'alpha_only' }
      ],
      intType: ['max_length', 'min_length', 'max_value', 'min_value'],
      booleanType: ['integer_only', 'numeric_only', 'alpha_only']
    }
  },
  methods: {
    save () {
      this.saving = true
      this.errors = {}
      const data = {
        rule_validators: [{
          type: this.option.id,
          value: this.value
        }]
      }
      const arrayData = {
        rule_validators: [{
          type: this.option.id,
          values: [this.value]
        }]
      }
      const json = this.option.id === 'in' ? arrayData : data
      const method = this.isEdit ? 'put' : 'patch'
      this.$http[method](this.endpoint, json).then(response => {
        this.$emit('reload:rules')
        const message = this.isEdit ? 'Rule validation updated successfully' : 'Rule validation added successfully'
        this.$buefy.toast.open({ type: 'is-success', message: message })
        this.$parent.close()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        } else {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      }).finally(() => {
        this.saving = false
        this.isEdit = false
      })
    },
    onDelete () {
      this.saving = true
      this.errors = {}
      const data = {
        rule_validators: []
      }
      this.$http.put(this.endpoint, data).then(response => {
        this.$emit('reload:rules')
        this.$buefy.toast.open({ type: 'is-success', message: 'Rule validation deleted successfully' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        } else {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      }).finally(() => {
        this.saving = false
        this.isEdit = false
      })
    }
  },
  mounted () {
    this.name = this.rule.name
    if (this.rule.rule_validators && this.rule.rule_validators.length) {
      this.option = this.allTypes.find(el => el.id === this.rule.rule_validators[0].type)
      this.value = this.rule.rule_validators[0].value || this.rule.rule_validators[0].values
      this.isEdit = true
    }
  },
  props: {
    rule: {
      type: Object,
      require: true
    }
  }
}
</script>
