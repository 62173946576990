import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'pulse',
  tooltip: 'This is a tooltip'
}

export const inStock = {
  label: 'In Stock?',
  key: 'in_stock',
  icon: 'pulse',
  tooltip: 'Limit results based on whether an item is in stock or not.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const isDiscontinued = {
  label: 'Is Discontinued?',
  key: 'is_discontinued',
  icon: 'pulse',
  tooltip: 'Limit results based on whether an item is discontinued or not.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const ignoreDeleted = {
  label: 'Show Deleted Items?',
  key: 'ignore_deleted',
  icon: 'pulse',
  tooltip: 'Limit results based on whether an item is deleted or not.',
  options: [{
    value: 0,
    label: 'Yes'
  }, {
    value: 1,
    label: 'No'
  }]
}

export const noStockNoEad = {
  label: 'Unknown Return',
  key: 'no_stock_no_ead',
  icon: 'pulse',
  tooltip: 'Limit results to items that are out of stock with no estimated restock date.',
  options: [{
    value: 1,
    label: 'Out of stock with no estimated restock date'
  }]
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'pulse',
  tooltip: 'This is a tooltip',
  options: dates
}

export const submittedAt = {
  label: 'Submitted At',
  key: 'brand_inventory_updated_at',
  icon: 'pulse',
  tooltip: 'This is a tooltip',
  options: dates
}
