<template>
<div>
  <div class="mb-sm">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered">Step 1 of 3: Upload SKU List</p>
    </div>
    <!-- Select Connection -->
    <div class="mb-lg">
      <FieldLabel label="Select Merchant Partner" class="mb-sm" />
      <b-field
        :type="('connection' in errors) ? 'is-danger' : ''"
        :message="('connection' in errors) ? errors['connection'][0] : ''">
        <ConnectionSelect v-model="connectionShort" size="is-medium" />
      </b-field>
    </div>
    <div v-if="connectionShort">
      <div v-if="!loadingConnection && connection !== null" class="mt-lg">
        <!-- Allows Proposals -->
        <div>
          <!-- Department -->
          <div class="mb-lg">
            <FieldLabel label="Category" class="mb-sm" />
            <b-field
              :type="('department' in errors) ? 'is-danger' : ''"
              :message="('department' in errors) ? errors['department'][0] : ''">
              <DepartmentSelect v-model="department" :connectionId="(connection) ? connection.id : null" size="is-medium" />
            </b-field>
          </div>
          <!-- Proposal Type -->
          <div class="mb-lg">
            <FieldLabel label="Proposal Purpose" class="mb-md" />
            <b-field>
              <b-radio v-model="proposalType" native-value="new_product">I want to submit new products for approval.</b-radio>
            </b-field>
            <b-field v-if="connection.connection_fees.net_price_method === 'permit_cost'">
              <b-radio v-model="proposalType" native-value="cost_update">I want to propose changes to my item costs or prices.</b-radio>
            </b-field>
            <!-- Z Gallerie Only (until more QA is applied)-->
            <template v-if="connection.retailer.code === 'z-gallerie' || connection.retailer.code === 'z-gallerie-uat'">
              <b-field>
                <b-radio v-model="proposalType" native-value="attribute_update">I want to submit updated attributes for previously approved products.</b-radio>
              </b-field>
              <b-field>
                <b-radio v-model="proposalType" native-value="image_update">I want to submit updated images for previously approved products.</b-radio>
              </b-field>
            </template>
          </div>
          <!-- Sample Template -->
          <div v-if="department" class="mb-lg">
            <p class="has-text-weight-bold is-size-6">Add file for import</p>
            <p class="mb-md is-size-7">
              Please complete and upload our line sheet template.
              <a
                v-if="department.sample_template_url"
                :href="department.sample_template_url"
                class="has-text-info"
                target="_blank"
                download>Download sample</a>
            </p>
            <b-message size="is-small">
              <span class="has-text-weight-bold is-alert">Note: </span>
              Required file headers and guidelines are available in the sample template.
            </b-message>
            <!--- File -->
            <div class="mb-lg">
              <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile" :showLabel='true' />
            </div>
          </div>
        </div>
        <!-- Doesn't Allow Proposals -->
        <!-- <div v-else>
          <b-message type="is-info">We're sorry, but {{ connection.retailer.name }} is
            not currently accepting new proposals. If you are looking to add additional
            products, please contact {{ connection.retailer.name }} for further information.
          </b-message>
        </div> -->
      </div>
      <div v-else>
        <loading message="Loading connection details..." />
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :loading="saving"
            :disabled="!ready || saving"
            @click="goForward"
          >Create Proposal</b-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import DepartmentSelect from '@/components/Models/ProposalDepartment/DepartmentSelect'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    ConnectionSelect,
    CoreUpload,
    DepartmentSelect
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.connection && this.department && this.tempfile)
    },
    proposalBody () {
      if (!this.ready) return {}
      return {
        connection: {
          id: this.connection.id
        },
        name: this.department.name + ' - ' + this.context.partner.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'merchandising',
        proposal_type: 'new_product',
        department: {
          id: this.department.id
        },
        recipient: {
          user_id: this.department.owner_user.id
        }
      }
    }
  },
  data () {
    return {
      connectionShort: null,
      loadingConnection: true,
      connection: null,
      department: null,
      proposalType: 'new_product',
      tempfile: null,
      proposal: null,
      feedQueue: null,
      saving: false,
      buttonLabel: 'Upload SKU List',
      errors: {}
    }
  },
  methods: {
    loadConnectionDetail () {
      this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.connectionShort.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    async goForward () {
      this.saving = true
      try {
        await this.createProposal()
        await this.createFeedQueue()
        await this.runFeedQueue()
        this.$router.replace({ name: 'catalog.submit.new', query: { step: 'Progress', proposal_id: this.proposal.id, feed_id: this.feedQueue.id } })
        this.$emit('navigate', 'Progress')
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occured!' })
      }
      this.saving = false
    },
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    createProposal () {
      const endpoint = this.context.route + '/proposals/'
      const body = {
        connection: {
          id: this.connection.id
        },
        name: this.context.partner.name + ' - ' + this.department.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'console',
        proposal_type: this.proposalType,
        department: {
          id: this.department.id
        },
        recipient: {
          user_id: this.department.owner_user.id
        }
      }
      return this.$http.post(endpoint, body).then(response => {
        this.$emit('proposal:created', response.data)
        this.proposal = response.data
      })
    },
    createFeedQueue () {
      const endpoint = this.context.route + '/feed-queue/'
      const body = {
        template: {
          id: this.department.template.id
        },
        direction: 'import',
        data_type: 'products',
        source: 'console',
        behavior: 'merge',
        info: {
          assign_to_proposal_id: this.proposal.id,
          proposal_feed_purpose: 'combined',
          rulesets: [{
            id: this.department.brand_ruleset.id
          }, {
            id: this.department.retailer_ruleset.id
          }]
        },
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      return this.$http.post(endpoint + 'enqueue/', body).then(response => {
        this.$emit('feed:queued', response.data)
        this.feedQueue = response.data
      })
    },
    runFeedQueue () {
      const endpoint = this.context.route + '/feed-queue/' + this.feedQueue.id + '/run/'
      return this.$http.put(endpoint)
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  watch: {
    connectionShort (connection) {
      if (connection) {
        this.loadConnectionDetail()
      }
    }
  }
}
</script>
