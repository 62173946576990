<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add {{ platform.name }} Integration</p>
      </header>
      <section class="modal-card-body">
        <div class="has-text-centered">
          <b-image :src="platform.logo" ratio="3x1" :responsive="false" />
        </div>
        <b-field label="Store Identifier">
          <b-input v-model="hash" size="is-medium"></b-input>
        </b-field>
        <b-field label="Store Token">
          <b-input v-model="token" size="is-medium"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$emit('canceled')">Back</button>
        <b-button type="is-primary" native-type="submit" :loading="saving" :disabled="!ready || saving">Add Integration
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/platform-accounts/'
    },
    json () {
      return {
        platform: { code: this.platform.code },
        credentials: [{
          credential: { code: 'bigcommerce_shop_hash' },
          value: this.hash
        }, {
          credential: { code: 'bigcommerce_token' },
          value: this.token
        }]
      }
    },
    ready () {
      return this.hash && this.token
    }
  },
  data () {
    return {
      hash: null,
      token: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$emit('account:saved')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = { detail: 'Please review your input and try again.' }
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: { platform: { type: Object, required: true } }
}
</script>
