import List from './views/List'
import Dashboard from './views/Dashboard'
import DashboardNew from './views/DashboardNew'
import Detail from './views/Detail'
import Summary from './views/Summary'
import Fulfill from './views/Fulfill'
import Invoices from './views/Invoices'
import Rmas from './views/Rmas'
import Shipments from './views/Shipments'
import Cancels from './views/Cancels'
import Credits from './views/Credits'
import Replacements from './views/Replacements'
import Messages from './views/Messages'
import Create from './views/Create'

export default [{
  path: '/orders/legacy',
  name: 'orders.dashboard.legacy',
  component: Dashboard
}, {
  path: '/orders',
  name: 'orders.dashboard',
  component: DashboardNew
}, {
  path: '/orders/create',
  name: 'orders.create',
  component: Create
}, {
  path: '/orders/list',
  name: 'orders.list',
  component: List
}, {
  path: '/orders/:id',
  name: 'order',
  component: Detail,
  children: [{
    path: 'summary',
    name: 'order.summary',
    component: Summary
  }, {
    path: 'fulfill/:method',
    name: 'order.fulfill',
    component: Fulfill
  }]
}, {
  path: '/orders/messages',
  name: 'orders.messages',
  component: Messages
}, {
  path: '/replacements/list',
  name: 'replacements.list',
  component: Replacements
}, {
  path: '/invoices/list',
  name: 'invoices.list',
  component: Invoices
}, {
  path: '/shipments/list',
  name: 'shipments.list',
  component: Shipments
}, {
  path: '/returns/list',
  name: 'returns.list',
  component: Rmas
}, {
  path: '/credits/list',
  name: 'credits.list',
  component: Credits
}, {
  path: '/cancels/list',
  name: 'cancels.list',
  component: Cancels
}]
