<template>
  <div>
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered mb-md">Products Submitted!</p>
      <div class="has-text-centered">
        <p class="is-size-7">
          Your products have been submitted to {{ connection.retailer.name }} for review &amp; approval!
        </p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="saving"
            :loading="saving"
            @click="goForward"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Compatibility')
    },
    goForward () {
      this.$store.dispatch('setUnsavedChanges', false)
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    feed: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object
    }
  }
}
</script>
