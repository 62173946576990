<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        Items Failing {{ rule.rule.name }}
      </header>
      <section class="modal-card-body">
        <b-table
          :data="variants"
          :striped="true"
          :hoverable="true"
          :narrowed="true"
          :loading="loading"
          :mobile-cards="false"
          class="is-size-7">
          <template>
            <!-- Name -->
            <b-table-column
              label="Item"
              cell-class="nowrap"
              v-slot="props">
              <p class="has-text-weight-bold">{{ props.row.name }}</p>
              <p class="has-text-grey">SKU: {{ props.row.identifier }}</p>
            </b-table-column>
            <!-- Value -->
            <b-table-column
              :label="rule.rule.name"
              cell-class="nowrap"
              v-slot="props">
              <p v-if="rule.rule.attribute.code in props.row.attributes">{{ props.row.attributes[rule.rule.attribute.code].values[0].value }}</p>
              <p v-else class="is-italic has-text-grey-light">Not Set</p>
            </b-table-column>
          </template>
        </b-table>
        <div class="mt-md">
          <b-pagination
            :total="total"
            :current.sync="currentPage"
            :rounded="true"
            :per-page="perPage"
            size="is-small"
            @change="paginate">
          </b-pagination>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      loading: false,
      variants: [],
      total: 0,
      currentPage: 1,
      perPage: 25
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/variants/'
      const query = {
        params: {
          failed_rule_id: this.rule.id,
          short: 1,
          is_rejected: 0,
          added_to_proposal: 1,
          editor: 1,
          limit: this.perPage,
          page: this.currentPage
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.total = response.data.count
        this.variants = response.data.results
        this.loading = false
      })
    },
    paginate (page) {
      this.currentPage = page
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  props: ['proposal', 'rule']
}
</script>
