<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">{{ (context.isMerchant()) ? 'Supplier' : 'Merchant' }} Detail</h1>
          </div>
        </div>
        <!-- Connection Detail -->
        <div>
          <!-- Partner Name Header -->
          <div class="has-background-white rounded-lg pa-lg mb-lg">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <div class="is-size-5 has-text-weight-bold">
                  <p v-if="!loading && context.isMerchant()">{{ connection.brand.name }}</p>
                  <p v-else-if="!loading && context.isSupplier()">{{ connection.retailer.name }}</p>
                  <p v-else>Loading...</p>
                </div>
                <p class="has-text-grey-light is-size-7">Connection ID: {{ (loading) ? 'Loading...' : connection.id }}</p>
              </div>
              <div class="column is-narrow has-text-right">
                <p class="is-size-5 has-text-weight-bold">
                  <span v-if="loading" class="has-text-grey-light">Loading...</span>
                  <span v-else-if="refreshing" class="has-text-grey-light">Refreshing...</span>
                  <span v-else><Status :connection="connection" /></span>
                </p>
                <p v-if="connection && connection.status === 'onboarding'" class="is-size-7 has-text-weight-bold">
                  <span v-if="connection.onboarding_status === 'complete'" class="has-text-warning">In Review</span>
                  <span v-else class="has-text-grey">In Progress</span>
                </p>
              </div>
            </div>
          </div>
          <!-- Connection Child Routes -->
          <div v-if="!loading && connection !== null" >
            <div class="columns">
              <div class="column is-narrow">
                <b-menu>
                  <b-menu-list label="Connection">
                    <b-menu-item
                      icon="clipboard-list-outline"
                      label="Summary"
                      tag="router-link"
                      :to="{ name: 'connection.detail', params: { id: connection.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      v-if="context.isMerchant()"
                      icon="warehouse"
                      label="Locations"
                      tag="router-link"
                      :to="{ name: 'connection.detail.locations', params: { id: connection.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      icon="format-list-bulleted"
                      label="Attributes"
                      tag="router-link"
                      :to="{ name: 'connection.detail.credentials', params: { id: connection.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      icon="truck"
                      label="Carriers"
                      tag="router-link"
                      :to="{ name: 'connection.detail.shipping', params: { id: connection.id } }"
                      exact
                    ></b-menu-item>
                    <!-- Onboarding Link -->
                    <b-menu-item
                      v-if="context.isMerchant() && connection && connection.status === 'onboarding'"
                      icon="checkbox-marked-outline"
                      label="Onboarding"
                      tag="router-link"
                      :to="{ name: 'connection.detail.onboarding', params: { id: connection.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      v-if="context.isSupplier() && connection"
                      icon="checkbox-marked-outline"
                      label="Onboarding"
                      tag="router-link"
                      :to="{ name: 'onboarding.tasks', params: { connection: connection.id } }"
                      exact
                    ></b-menu-item>
                  </b-menu-list>
                </b-menu>
              </div>
               <div class="column">
                <router-view :connection="connection" :loading="loading" @updated="load(true)" />
              </div>
            </div>
          </div>
          <!-- Loading... -->
          <div v-else>
            <loading />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Status from '@/components/Models/Connection/Presenters/Status'

export default {
  components: {
    Status
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    breadcrumbs () {
      const breadcrumbs = {
        current: 'Loading...',
        history: []
      }
      // for merchants, the connections root is the connections dashboard.
      if (this.context.isMerchant()) {
        breadcrumbs.history = [
          { label: 'Suppliers', name: 'connections' },
          { label: 'List', name: 'connections.list' }
        ]
      // for brands, the connections root is the list page
      } else {
        breadcrumbs.history = [
          { label: 'Merchants', name: 'connections.list' }
        ]
      }
      if (this.connection !== null) {
        breadcrumbs.current = (this.context.isSupplier()) ? this.connection.retailer.name : this.connection.brand.name
      }
      return breadcrumbs
    },
    endpoint () {
      return this.context.route + '/connections/' + this.$route.params.id + '/'
    },
    shippingMethods () {
      if (this.loading || this.connection === null) return []
      const shippingMethods = []
      for (const shippingAccount of this.connection.shipping_accounts) {
        for (const shippingMethod of shippingAccount.shipping_methods) {
          const sm = shippingMethod
          sm.shipping_account = {
            id: shippingAccount.id,
            nickname: shippingAccount.nickname,
            owner: shippingAccount.owner,
            carrier: shippingAccount.carrier
          }
          shippingMethods.push(sm)
        }
      }
      return shippingMethods
    }
  },
  data () {
    return {
      loading: true,
      refreshing: false,
      connection: null
    }
  },
  methods: {
    load (refresh = false) {
      if (refresh) {
        this.refreshing = true
      }
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.refreshing = false
        this.$set(this, 'connection', response.data)
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
