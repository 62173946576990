<template>
  <div>
    <!-- Content -->
    <div class="has-text-grey ">
      <p class="mb-md has-text-weight-bold has-text-centered">Schedule Introductory Call</p>
      <div class="content mb-lg">
        <p>
          During our introductory call, we will discuss all aspects of the
          <span v-if="connection.level === 'products_only'">
           product onboarding lifecycle.
          </span>
          <span v-else>
            the dropship lifecycle.
          </span>
          In order to have the most efficient call, please include the following team
          members:
        </p>
        <ul>
          <li>
            a <span class="has-text-weight-bold">product data specialist</span>
            to discuss product imports &amp; attributes.
          </li>
          <li v-if="connection.level === 'transactions_and_products'">
            a <span class="has-text-weight-bold">fulfillment specialist</span>
            to discuss inventory and shipping.
          </li>
          <li v-if="connection.level === 'transactions_and_products'">
            an <span class="has-text-weight-bold">accounting specialist</span>
            to discuss invoicing.
          </li>
        </ul>
        <p>
          Please also feel free to include any technical or operations representatives that might have questions!
        </p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-multiline is-centered">
        <div class="column is-half">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column is-half">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
          >Schedule Call</b-button>
        </div>
        <div class="column">
          <b-button
            v-if="auth.isStaff"
            type="is-success rounded-lg"
            size="is-medium"
            outlined
            expanded
            :disabled="saving"
            :loading="saving"
            @click="activateOnboarding"
          >Activate Onboarding (Staff Only)</b-button>
        </div>
      </div>
      <div class="pt-xl has-text-centered">
        <a href="#" @click.prevent="showSkipCallModal = true">Skip Call</a>
      </div>
    </div>
    <!-- Modal: Skip  -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showSkipCallModal"
      :can-cancel="['x']"
      ><div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Skip Introductory Call?</p>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <p>
              Are you sure you want to skip the introductory call? If you're new to {{ app.productName }},
              we highly recommend scheduling a training and demo session.
            </p>
            <p>If you'd like to skip this call, please select a reason below:</p>
          </div>
          <b-field label="Reason">
            <b-select size="is-medium" v-model="skipReason" expanded placeholder="Select Reason...">
              <option value="Already Onboarded">I've already onboarded with {{ app.productName }}</option>
              <option value="Self-Serve">I prefer a self-serve experience</option>
              <option value="Testing">I'm just testing</option>
              <option value="Other">Other</option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="showSkipCallModal = false">Close</b-button>
          <b-button
            type="is-primary"
            @click="skipCall"
            :disabled="!skipReason || saving"
            :loading="saving"
          >Skip Call</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'auth', 'context', 'services']),
    redirectUrl () {
      const url = new URL(this.services.calendly.onboardingUrl)
      // add easy fields
      url.searchParams.append('name', this.auth.user.fullName)
      url.searchParams.append('email', this.auth.user.email)
      url.searchParams.append('a1', this.brand.name)
      // construct the info field...
      let info = ''
      if ('ob_marketplace_experience' in this.brand.metadata) {
        info += '- Experience: ' + this.brand.metadata.ob_marketplace_experience.values[0].value
      }
      if ('ob_integration_plan' in this.brand.metadata) {
        info += '\n- Integration: ' + this.brand.metadata.ob_integration_plan.values[0].value
      }
      if (info) {
        url.searchParams.append('a2', info)
      }
      return url.href
    }
  },
  data () {
    return {
      saving: false,
      showSkipCallModal: false,
      skipReason: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Integration')
    },
    goForward () {
      window.open(this.redirectUrl, '_self')
    },
    activateOnboarding () {
      this.saving = true
      const endpoint = this.context.route + '/'
      this.$http.put(endpoint + 'assigned-tasks/' + this.task.id + '/complete/').then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Onboarding Activated!' })
        this.$emit('completed')
      }).finally(() => {
        this.saving = false
      })
    },
    skipCall () {
      this.saving = true
      const endpoint = this.context.route + '/'
      const body = {
        metadata: [{
          name: 'ob_skip_call_reason',
          display_name: 'Onboarding Skip Call Reason',
          type: 'string',
          value: this.skipReason
        }]
      }
      this.$http.patch(endpoint, body).then(() => {
        this.$http.put(endpoint + 'assigned-tasks/' + this.task.id + '/complete/').then(() => {
          this.showSkipCallModal = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
          this.$emit('completed')
        }).finally(() => {
          this.saving = false
        })
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    brand: {
      type: Object,
      required: true
    }
  }
}
</script>
