<template>
  <div>
    <!-- Page Header -->
    <div class="pt-lg has-text-centered">
      <h1 class="is-size-4">{{ context.type === 'retailer' ? 'Merchant' : 'Supplier' }} Settings</h1>
    </div>
    <!-- Action Items -->
    <div class="section">
      <div class="container">
        <div class="columns is-multiline">
          <!-- General -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.general' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">General Information</h4>
                    <p class="has-text-grey is-size-7">Manage general information about your business.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Onboarding Preferences -->
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'settings.onboarding' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Default Onboarding Preferences</h4>
                    <p class="has-text-grey is-size-7">Manage default onboarding settings for all new connections.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Transaction Preferences -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.transaction' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Currency Settings</h4>
                    <p class="has-text-grey is-size-7">Manage the currency of your account.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Notifications -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.notifications' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg has-background-white pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Notifications</h4>
                    <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Update the notifications you receive from the {{ app.platform }} Platform.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="column is-half">
            <router-link :to="{ name: 'settings.branding' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Branding</h4>
                    <p class="has-text-grey is-size-7">Add your logo to the {{ app.platform }} platform.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="auth.isStaff" class="column is-half">
            <div class="rounded-lg has-background-white pa-md px-xl">
              <h4 class="has-text-weight-bold"><router-link :to="{ name: 'settings.payments' }">Payment Settings (Staff Only)</router-link></h4>
              <p class="has-text-grey is-size-7">Connect your bank account for automatic payments.</p>
            </div>
          </div>
          <div v-if="context.isSupplier()" class="column is-half">
            <router-link :to="{ name: 'settings.inventory' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Inventory Settings</h4>
                    <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Register and update fullfilment &amp; inventory locations.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="column is-half">
            <router-link :to="{ name: 'users' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">User Management</h4>
                    <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Invite new and manage existing users.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="column is-half">
            <router-link :to="{ name: 'clients' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">API Clients</h4>
                    <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Manage API keys for your account.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Integrations -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.integrations' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Integrations</h4>
                    <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Manage integrations of your account (e.g. Shopify, fabric OMS).</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Webhooks -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.webhooks' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <h4 class="has-text-weight-bold">Webhooks (Advanced)</h4>
                <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Configure webhooks and review webhook history.</p>
              </div>
            </router-link>
          </div>
          <!-- Product SKU Settings -->
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'settings.products' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <h4 class="has-text-weight-bold">Retailer SKU Settings</h4>
                <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Configure auto-generation of Retailer SKU.</p>
              </div>
            </router-link>
          </div>
          <!-- Proposal Departments -->
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'departments' }">
              <div class="rounded-lg pa-md px-xl has-background-white">
                <h4 class="has-text-weight-bold">Proposal Departments</h4>
                <p class="is-size-7 has-text-grey">Manage your proposal departments. </p>
              </div>
            </router-link>
          </div>
          <!-- Product Transformers -->
          <div class="column is-half">
            <router-link :to="{ name: 'transformers' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <h4 class="has-text-weight-bold">Attribute Value Transformers</h4>
                <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Manage your product attribute value transformations.</p>
              </div>
            </router-link>
          </div>
          <!-- Product Rulesets -->
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'rulesets' }">
              <div class="rounded-lg pa-md px-xl has-background-white">
                <h4 class="has-text-weight-bold">Product Rulesets</h4>
                <p class="is-size-7 has-text-grey">Manage your product rulesets. </p>
              </div>
            </router-link>
          </div>
          <!-- Product Templates -->
          <div class="column is-half">
            <router-link :to="{ name: 'templates' }" :class="{'item-disabled': isDelinquent}">
              <div class="rounded-lg pa-md px-xl" :class="isDelinquent ? 'has-background-grey has-text-white' : 'has-background-white'">
                <h4 class="has-text-weight-bold">Product &amp; Inventory Templates</h4>
                <p class="is-size-7" :class="isDelinquent ? '' : 'has-text-grey'">Manage your product and inventory templates. </p>
              </div>
            </router-link>
          </div>
          <!-- Shipping Accounts -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.shipping' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <h4 class="has-text-weight-bold">Shipping Accounts</h4>
                <p class="has-text-grey is-size-7">Manage existing and add new Shipping Accounts.</p>
              </div>
            </router-link>
          </div>
          <!-- Subscriptions -->
          <div v-if="context.isSupplier() && hasPermission('View Subscriptions', 'brand')" class="column is-half">
            <router-link :to="{ name: 'settings.subscriptions' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Subscriptions</h4>
                    <p class="has-text-grey is-size-7">Configure subscriptions and review available services.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- EDI Settings (Vendors Only) -->
          <div v-if="context.isSupplier()" class="column is-half">
            <router-link :to="{ name: 'settings.edi' }">
              <div class="rounded-lg has-background-white pa-lg px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">EDI Settings</h4>
                    <p class="has-text-grey is-size-7">Get your EDI and SFTP account information.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Legal -->
          <div class="column is-half">
            <router-link :to="{ name: 'settings.legal' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <!-- <div class="column is-narrow">
                    <p><b-icon icon="power-plug" size="is-medium" /></p>
                  </div> -->
                  <div class="column">
                    <h4 class="has-text-weight-bold">Terms of Service</h4>
                    <p class="has-text-grey is-size-7">Review the Terms of Service from {{ app.platform }} Marketplace. </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'settings.tracking' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <!-- <div class="column is-narrow">
                    <p><b-icon icon="power-plug" size="is-medium" /></p>
                  </div> -->
                  <div class="column">
                    <h4 class="has-text-weight-bold">Tracking Number History</h4>
                    <p class="has-text-grey is-size-7">Acknowledge change in tracking numbers. </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Commission Profiles -->
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'commission-profiles' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Commission Profiles</h4>
                    <p class="has-text-grey is-size-7">Create & manage commission profile & assign them to suppliers  </p>
                   </div>
                 </div>
               </div>
             </router-link>
          </div>
          <!-- FTP Settings -->
          <div v-if="context.isSupplier()"  class="column is-half">
            <router-link :to="{ name: 'settings.ftp' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">SFTP File Browser</h4>
                    <p class="has-text-grey is-size-7">View files submitted via SFTP.</p>
                   </div>
                 </div>
               </div>
             </router-link>
          </div>
          <!-- Merchant Categories -->
          <div v-if="context.isMerchant()" class="column is-half">
            <router-link :to="{ name: 'categories' }">
                <div class="rounded-lg has-background-white pa-md px-xl">
                  <div class="columns is-vcentered is-mobile">
                    <div class="column">
                      <h4 class="has-text-weight-bold">Categories</h4>
                      <p class="has-text-grey is-size-7">Create and manage categories.</p>
                    </div>
                  </div>
                </div>
              </router-link>
          </div>
          <!-- Supplier Categories -->
          <div v-if="context.isSupplier()" class="column is-half">
            <router-link :to="{ name: 'connection' }">
              <div class="rounded-lg has-background-white pa-md px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h4 class="has-text-weight-bold">Categories</h4>
                    <p class="has-text-grey is-size-7">View merchant categories.</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    isDelinquent () {
      return !this.auth.isStaff && this.context !== null && this.context.type === 'vendor' && this.context.partner !== null && this.context.partner.isDelinquent
    }
  },
  methods: {
    hasPermission (name, contentType) {
      return this.auth.user.permissions.find(permission => permission.name === name && permission.contentType === contentType) !== undefined
    }
  }
}
</script>
<style scoped>
  .item-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>
