<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Integration</p>
      </header>
      <section class="modal-card-body">
        <!-- Category Selector -->
        <div class="mb-lg">
          <b-field label="Category">
            <b-select v-model="category" expanded>
              <option value="All">All</option>
              <option value="Orders">Orders &amp; Fulfillments</option>
              <option value="Products">Product Information</option>
              <option value="Inventory">Inventory</option>
              <option value="Returns">Returns & Post-Transaction Support</option>
            </b-select>
          </b-field>
        </div>
        <div class="columns is-vcentered is-multiline">
          <div v-for="platform in filteredPlatforms" :key="platform.code" class="column is-half">
            <div class="has-text-centered pa-md border">
              <p class="mb-lg"><b-image :src="platform.logo" ratio="3x1" /></p>
              <p>
                <b-button
                  type="is-light"
                  expanded
                  @click="selectPlatform(platform)"
                >Add</b-button>
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.api.route + '/'
    },
    filteredPlatforms () {
      const filtered = []
      this.availablePlatforms.forEach(platform => {
        if (platform.context.includes(this.context.type)) {
          if (this.category === 'All') {
            filtered.push(platform)
          } else {
            if (platform.categories.includes(this.category)) {
              filtered.push(platform)
            }
          }
        }
      })
      return filtered
    }
  },
  data () {
    return {
      loading: true,
      category: 'All',
      availablePlatforms: [{
        name: 'BigCommerce',
        code: 'bigcommerce',
        categories: ['Orders', 'Inventory', 'Pricing'],
        logo: 'https://res.cloudinary.com/revcascade/image/upload/static/integrations/bigcommerce.png',
        context: ['vendor', 'retailer']
      }, {
        name: 'fabric',
        code: 'fabric',
        categories: ['Orders', 'Products', 'Inventory', 'Pricing'],
        logo: 'https://res.cloudinary.com/revcascade/image/upload/static/integrations/fabric.png',
        context: ['retailer']
      }, {
        name: 'Returnly',
        code: 'returnly',
        categories: ['Returns'],
        logo: 'https://res.cloudinary.com/revcascade/image/upload/static/integrations/returnly.png',
        context: ['vendor', 'retailer']
      }, {
        name: 'ShipStation',
        code: 'shipstation',
        categories: ['Orders'],
        logo: 'https://res.cloudinary.com/revcascade/image/upload/static/integrations/shipstation.png',
        context: ['vendor']
      }, {
        name: 'Shopify',
        code: 'shopify',
        categories: ['Orders', 'Products', 'Inventory', 'Pricing'],
        logo: 'https://res.cloudinary.com/revcascade/image/upload/static/integrations/shopify.png',
        context: ['vendor', 'retailer']
      }, {
        name: 'WooCommerce',
        code: 'woocommerce',
        categories: ['Orders', 'Products', 'Inventory', 'Pricing'],
        logo: 'https://res.cloudinary.com/revcascade/image/upload/v1673974346/static/integrations/woocommerce.png',
        context: ['vendor']
      }
      ]
    }
  },
  methods: {
    selectPlatform (platform) {
      this.$emit('platform:selected', platform)
    }
    // load () {
    //   this.loading = true
    //   const endpoint = this.api.baseUrl + '/v1/platforms/'
    //   this.$http.get(endpoint).then(response => {
    //     this.availablePlatforms = response.data.results
    //   }).finally(() => {
    //     this.loading = false
    //   })
    // },
  },
  mixins: [Filterable],
  mounted () {
    // this.load()
  }
}
</script>
