<template>
  <div>
    <!-- Intro -->
    <div class="has-background-black py-xxl">
      <div class="section">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <div class="columns">
                <div class="column">
                  <div class="mb-lg">
                    <Logo color="white" />
                  </div>
                  <div class="mb-lg has-text-white">
                    <p class="is-uppercase has-text-weight-bold mb-sm" style="color:#177F87">marketplace</p>
                    <p class="is-size-3 has-text-weight-bold mb-md" style="line-height: 1.2;">Start and scale your dropshipping business</p>
                    <p class="is-size-5">
                      fabric Marketplace is a smarter way to expand
                      your assortment without the inventory risk.
                    </p>
                  </div>
                </div>
                <div class="column">
                  <img src="https://res.cloudinary.com/revcascade/image/upload/c_scale,w_1400/c_crop,w_1000/v1681773948/static/shopify_assets/marketplace-hero.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading State -->
    <div class="section" style="margin-top:-4rem">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters-tablet is-three-fifths-widescreen">
            <div class="has-background-white rounded-xl pa-xl has-text-centered">
              <p class="is-size-4 has-text-primary mb-md">Welcome to {{ app.productName }}!</p>
              <!-- If user is not resolved -->
              <div v-if="auth.authenticating">
                <loading message="Signing in..." />
              </div>
              <!-- If user has one retailer that IS NOT onboarded -->
              <div v-else-if="auth.retailers.length === 1 && !auth.retailers[0].is_onboarded">
                <b-button type="is-primary" size="is-medium" @click="goToDashboard">Activate Trial</b-button>
              </div>
              <!-- If user has one retailer that IS onboarded -->
              <div v-else-if="auth.retailers.length === 1 && auth.retailers[0].is_onboarded">
                <b-button type="is-primary" size="is-medium" @click="goToDashboard">Go to App</b-button>
              </div>
              <!-- If user more than one retailer -->
              <div v-else-if="auth.retailers.length > 1 ">
                <b-button type="is-primary" size="is-medium" @click="selectContext">Go to App</b-button>
              </div>
              <!-- General Error -->
              <div v-else>
                <p class="is-size-5">Installation Error</p>
                <p class="mb-md">It seems there was an error installing the Shopify app.</p>
                <p>Please contact our support team at</p>
                <p><a href="mailto:support@fabric.inc" class="has-text-info has-text-weight-bold">support@fabric.inc</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logos/Logo'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['api', 'app', 'auth'])
  },
  data () {
    return {
      token: null,
      user: null
    }
  },
  methods: {
    goToDashboard () {
      this.$router.push({ name: 'orders.dashboard' })
    },
    selectContext () {
      this.$router.push({ name: 'context.external' })
    }
  },
  mounted () {
    if ('t' in this.$route.query) {
      this.token = this.$route.query.t
      this.$router.replace(this.$route.path)
    }
    if (this.token) {
      this.$store.dispatch('authenticate', { access_token: this.token }).then(() => {})
    }
  }
}

</script>
