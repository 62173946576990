<template>
  <div>
    <Summary :start-date="startDate" :end-date="endDate" :dimension="dynamicDimension" :metric="metric"></Summary>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Summary from '@/modules/Performance/views/Summary.vue'
export default {
  data () {
    return {}
  },
  props: {
    startDate: Date,
    endDate: Date,
    dimension: String,
    metric: String
  },
  computed: {
    ...mapGetters(['context']),
    dynamicDimension () {
      if (this.context.isSupplier()) {
        return 'retailer'
      } else if (this.context.isMerchant()) {
        return 'brand'
      }
      return 'date'
    }
  },
  components: { Summary }
}
</script>
