<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{
              label: 'Settings',
              name: 'settings' }]"
              current="FTP Files" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">
              FTP Files
            </h1>
          </div>
        </div>
      </div>
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <FileFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <!-- counts -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="has-text-right is-size-7">
          <p v-if="loading">Loading...</p>
          <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
        </div>
      </div>
      <!-- Table -->
      <div>
        <b-table
          :data="results"
          :mobile-cards="false"
          :loading="loading"
          class="is-size-7">
          <template>
            <!-- ID -->
            <b-table-column
              label="ID"
              header-class="nowrap"
              cell-class="nowrap has-text-grey-light"
              v-slot="props">
              {{ props.row.id }}
            </b-table-column>
            <!-- File name -->
            <b-table-column
              label="File Name"
              header-class="nowrap"
              cell-class="nowrap"
              v-slot="props">
              {{ props.row.filename }}
            </b-table-column>
            <!-- path -->
            <b-table-column
              label="Path"
              header-class="nowrap"
              cell-class="is-tall"
              v-slot="props">
              {{ props.row.path }}
            </b-table-column>
            <!-- size -->
            <b-table-column
              label="Size"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              v-slot="props">
              {{ props.row.size }}
            </b-table-column>
            <!-- uploaded_at -->
            <b-table-column
              label="Uploaded"
              header-class="nowrap"
              cell-class="nowrap"
              numeric
              v-slot="props">
              <p>{{ $dates.utc(props.row.uploaded_at).local().format('MMM D, YYYY') }}</p>
              <p class="is-size-8 has-text-grey-light">{{ $dates.utc(props.row.uploaded_at).local().format('h:mm:ss A') }}</p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
        <div class="mt-md">
          <b-pagination
            :total="total"
            :current.sync="currentPage"
            :rounded="true"
            :per-page="perPage"
            @change="paginate">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import FileFilters from '../components/Ftp/FileFilters'
export default {
  components: {
    FileFilters
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return `${this.context.route}/ftp-files/`
    }
  },
  data () {
    return {
      key: 'ftp-files',
      loading: false
    }
  },
  methods: {},
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
