<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Onboarding Preferences" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Default Onboarding Preferences</h1>
          </div>
        </div>
      </div>
      <!-- Preferences -->
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Default Onboarding Preferences</p>
          <p class="has-text-grey is-size-7">
            The settings configured under this section will be applicable by default for
            <u class="has-text-weight-bold">all new connections</u>. You can always override these settings when inviting a new connection.
          </p>
        </div>
        <div class="column">
          <!-- Preferences -->
          <div v-if="!loading && preferences.length > 0">
            <div class="mb-lg">
              <ConnectionLevel :preferences="preferences" />
            </div>
            <div class="mb-lg">
              <CostDetermination :preferences="preferences" />
            </div>
            <div class="mb-lg">
              <FulfillmentSla :preferences="preferences" />
            </div>
            <div class="mb-lg">
              <PaymentTerms :preferences="preferences" />
            </div>
            <div class="mb-lg">
              <CatalogImportMethods :preferences="preferences" />
            </div>
            <div class="mb-lg">
              <ReturnsAllowance :preferences="preferences" />
            </div>
            <div class="mb-lg">
              <PackingSlipTemplate :preferences="preferences" />
            </div>
          </div>
          <!-- Loading -->
          <div v-else>
            <div class="has-background-white rounded-lg pa-lg mb-lg">
              <b-skeleton />
              <b-skeleton width="50%" />
              <b-skeleton width="25%" />
            </div>
            <div class="has-background-white rounded-lg pa-lg mb-lg">
              <b-skeleton />
              <b-skeleton width="50%" />
              <b-skeleton width="25%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CostDetermination from '../components/Onboarding/Cost'
import ConnectionLevel from '../components/Onboarding/ConnectionLevel'
import FulfillmentSla from '../components/Onboarding/FulfillmentSla'
import PaymentTerms from '../components/Onboarding/PaymentTerms'
import PackingSlipTemplate from '../components/Onboarding/PackingSlipTemplate'
import ReturnsAllowance from '../components/Onboarding/ReturnsAllowance'
import CatalogImportMethods from '../components/Onboarding/CatalogImportMethods'

export default {
  components: {
    CostDetermination,
    FulfillmentSla,
    ConnectionLevel,
    PaymentTerms,
    PackingSlipTemplate,
    ReturnsAllowance,
    CatalogImportMethods
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    params () {
      return {
        params: {
          grouping: 'onboarding'
        }
      }
    },
    json () {
      if (Object.keys(this.values).length === 0) {
        return null
      }
      const preferences = []
      for (const preference in this.values) {
        preferences.push(this.values[preference])
      }
      return preferences
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      preferences: [],
      values: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/preferences/'
      this.$http.get(endpoint, this.params).then(response => {
        this.preferences = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    trackPreferenceValue (preferenceValue) {
      this.$set(this.values, preferenceValue.key, preferenceValue.payload)
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences saved!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Preferences not saved!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  }
}
</script>
