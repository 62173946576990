import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.js'
import { version } from '../../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // app
    version: version,
    initialized: false,
    redirecting: false,
    hamburger: false,
    navbar: false,
    demoMode: false,
    supportRequest: false,
    unsavedChanges: false,
    showUnsavedChangesModal: false,
    navigateToPath: null,
    // api error handling
    serverError: false,
    errorStatusCode: null
  },
  getters: {
    app: state => {
      return {
        environment: process.env.VUE_APP_ENVIRONMENT_NAME,
        platform: process.env.VUE_APP_PLATFORM_NAME,
        platformCode: process.env.VUE_APP_PLATFORM_CODE,
        productName: process.env.VUE_APP_PRODUCT_NAME,
        companyName: process.env.VUE_APP_COMPANY_NAME,
        support: {
          merchant: {
            url: 'https://developer.fabric.inc/docs/marketplace-retailers-overview',
            email: null
          },
          supplier: {
            url: 'https://developer.fabric.inc/docs/marketplace-suppliers-overview',
            email: 'vendorsupport@fabric.inc'
          }
        },
        supportEmail: process.env.VUE_APP_SUPPORT_EMAIL, // deprecated
        supportUrl: process.env.VUE_APP_SUPPORT_URL, // deprecated
        url: process.env.VUE_APP_URL,
        version: state.version,
        initialized: state.initialized,
        hamburger: state.hamburger,
        navbar: state.navbar,
        demoMode: state.demoMode,
        debug: process.env.VUE_APP_DEBUG,
        supportRequest: state.supportRequest,
        serverError: state.serverError,
        errorStatusCode: state.errorStatusCode,
        unsavedChanges: state.unsavedChanges,
        showUnsavedChangesModal: state.showUnsavedChangesModal,
        navigateToPath: state.navigateToPath,
        websiteUrl: process.env.VUE_APP_WEBSITE_URL,
        privacyUrl: 'https://fabric.inc/privacy-policy/',
        termsOfUseUrl: 'https://fabric.inc/terms-of-use/'
      }
    },
    api: state => {
      return {
        baseUrl: process.env.VUE_APP_API_URL,
        baseRoute: process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION,
        version: process.env.VUE_APP_API_VERSION,
        clientId: process.env.VUE_APP_API_CLIENT_ID
      }
    },
    assets: state => {
      return {
        templates: {
          import: {
            shipments: process.env.VUE_APP_TEMPLATE_IMPORT_SHIPPING_SAMPLE_URL,
            invoices: process.env.VUE_APP_TEMPLATE_IMPORT_INVOICES_SAMPLE_URL,
            pricing: {
              id: process.env.VUE_APP_TEMPLATE_IMPORT_PRICING_ID,
              sample: process.env.VUE_APP_TEMPLATE_IMPORT_PRICING_SAMPLE_URL
            },
            offers: {
              sample: process.env.VUE_APP_TEMPLATE_IMPORT_OFFERS_SAMPLE_URL
            }
          },
          export: {
            pricing: {
              id: process.env.VUE_APP_TEMPLATE_EXPORT_PRICING_ID
            }
          }
        },
        terms: {
          retailer: parseInt(process.env.VUE_APP_RCN_RETAILER_TERMS_ID),
          vendor: parseInt(process.env.VUE_APP_RCN_VENDOR_TERMS_ID)
        },
        hardcodes: {
          deliveright: process.env.VUE_APP_DELIVERIGHT_CARRIER_ID,
          costUpdateTemplateId: process.env.VUE_APP_COST_UPDATE_TEMPLATE_ID
        }
      }
    },
    services: state => {
      return {
        calendly: {
          onboardingUrl: process.env.VUE_APP_CALENDLY_ONBOARDING_URL
        },
        fullstory: {
          pageName: process.env.VUE_APP_FULLSTORY_PAGE_NAME ?? 'MKP Development'
        },
        instagram: {
          clientId: process.env.VUE_APP_INSTAGRAM_CLIENT_ID,
          oauthUrl: process.env.VUE_APP_INSTAGRAM_OAUTH_URL,
          redirectUrl: process.env.VUE_APP_INSTAGRAM_REDIRECT_URL
        },
        shopify: {
          clientId: process.env.VUE_APP_SHOPIFY_CLIENT_ID,
          scopes: process.env.VUE_APP_SHOPIFY_SCOPES
        },
        plaid: {
          scriptUrl: process.env.VUE_APP_PLAID_SCRIPT_URL,
          clientName: process.env.VUE_APP_PLAID_CLIENT_NAME,
          env: process.env.VUE_APP_PLAID_ENV,
          key: process.env.VUE_APP_PLAID_KEY
        },
        stripe: {
          key: process.env.VUE_APP_STRIPE_KEY
        }
      }
    }
  },
  mutations: {
    initialize: state => {
      state.initialized = true
    },
    navigate: state => {
      state.hamburger = false
    },
    hamburger: state => {
      state.hamburger = !state.hamburger
    },
    supportRequest: (state) => {
      state.supportRequest = true
    },
    clearSupportRequest: (state) => {
      state.supportRequest = false
    },
    serverError: (state, statusCode) => {
      state.serverError = true
      state.errorStatusCode = statusCode
    },
    clearServerError: state => {
      state.serverError = false
      state.errorStatusCode = null
    },
    demoMode: state => {
      state.demoMode = !state.demoMode
    },
    setUnsavedChanges: (state, value) => {
      state.unsavedChanges = value
    },
    setShowUnsavedChangesModal: (state, value) => {
      state.showUnsavedChangesModal = value
    },
    setNavigateToPath: (state, value) => {
      state.navigateToPath = value
    }
  },
  actions: {
    /**
     * Initialize the application; read in values stored in the local cache
     * (e.g. local/session storage, etc) and pass them to the application
     * store for verification later.
     */
    initialize ({ commit, state, dispatch }, route) {
      return new Promise((resolve, reject) => {
        // look for a local/unverified access token
        commit('setToken', localStorage.getItem('rct'))
        // next, look for a local/unverified context setting. we store the
        // last used context in a local cache so that if a multi-context user
        // (e.g. RC Staff) refreshes the page, they don't have to reselect
        // context; instead the app should resolve the context and carry out
        // the users request. because session storage can be manipulated by
        // end users, let's try to catch any parse errors to prevent the app
        // from crashing and move on.
        try {
          commit('setLocalContext', JSON.parse(localStorage.getItem('rcc')))
        } catch (e) {}
        // finally, dispatch an action to identify the user; once indentification
        // is resolved, we'll consider the app initialized.
        dispatch('identify').then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        }).finally(() => {
          commit('initialize')
        })
      })
    },
    /**
     * As the user navigates within the application, we might need to
     * clean up various pieces of state (e.g. hide open modals and menus).
     * Could also be used later to fire custom analytics??
     */
    navigate ({ commit }) {
      commit('navigate')
    },
    /**
     * Configure the state required to show the navigation menu when the
     * user taps or clicks on the hamburger menu icon.
     */
    hamburger ({ commit }) {
      commit('hamburger')
    },
    /**
     * Configure the state required to indicate we're in "demo mode", so we
     * can hide things (e.g. context) from displaying prominently on the screen.
     */
    toggleDemoMode ({ commit }) {
      commit('demoMode')
    },
    /**
     * Show the modal to initiate a support request
     */
    supportRequest ({ commit }) {
      commit('supportRequest')
    },
    /**
     * Clear state to hide the support request modal
     */
    clearSupportRequest ({ commit }) {
      commit('clearSupportRequest')
    },
    /**
     * Configure the state required to gracefully handle 500-level error (i.e.
     * the API crashed).
     */
    serverError ({ commit }, statusCode) {
      commit('serverError', statusCode)
    },
    /**
     * Configure the state required to gracefully recover from 500-level
     * server errors
     */
    clearServerError ({ commit }) {
      commit('clearServerError')
    },
    // Action to configure the unsaved changes
    setUnsavedChanges ({ commit }, value) {
      commit('setUnsavedChanges', value)
    },
    // Action to configure the unsaved changes modal
    setShowUnsavedChangesModal ({ commit }, value) {
      commit('setShowUnsavedChangesModal', value)
    },
    // Action to configure the navigate to path
    setNavigateToPath ({ commit }, value) {
      commit('setNavigateToPath', value)
    }
  },
  modules: { auth }
})
