<template>
  <div>
    <div v-for="attribute in attributes" :key="attribute.id">
      <p>
        <a href="#" @click.prevent="inspectRule(attribute)" class="has-text-danger">
          <span class="has-text-weight-bold">{{ attribute.rule.name }}: </span>
          <template v-if="attribute.invalid > 0 && attribute.empty > 0">
            {{ attribute.empty }} missing {{ (attribute.empty === 1) ? 'value' : 'values' }},
            {{ attribute.invalid }} invalid {{ (attribute.invalid === 1) ? 'value' : 'values' }}
          </template>
          <template v-else-if="attribute.invalid === 0 && attribute.empty > 0">
            {{ attribute.empty }} missing {{ (attribute.empty === 1) ? 'value' : 'values' }}
          </template>
          <template v-else-if="attribute.invalid > 0 && attribute.empty === 0">
            {{ attribute.invalid }} invalid {{ (attribute.invalid === 1) ? 'value' : 'values' }}
          </template>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      loading: true,
      attributes: [],
      showVariantsModal: false,
      rule: null
    }
  },
  methods: {
    loadAttributeSummary () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/rulesets-attributes-summary/'
      const query = {
        params: {
          ruleset_id: this.ruleset.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.forEach(rule => {
          if (rule.failed > 0) {
            this.attributes.push({
              empty: rule.empty,
              invalid: rule.failed - rule.empty,
              rule: rule.rule
            })
          }
        })
        this.loading = false
      })
    },
    inspectRule (rule) {
      this.$emit('inspect:rule', rule)
    }
  },
  mounted () {
    this.loadAttributeSummary()
  },
  props: ['proposal', 'ruleset']
}
</script>
