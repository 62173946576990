<template>
<div>
  <!-- Option to Resume -->
  <div v-if="(proposal || feed) && !saving">
    <b-message type="is-info">
      <p class="has-text-weight-bold mb-sm">Welcome Back!</p>
      <p>
        We've noticed that you've already started this task.
        If you would like to pickup where you left off, choose 'Resume' below. Otherwise,
        you can start the task over.
      </p>
    </b-message>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="showResetProgressModal = true"
            expanded
            >Start Over</b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goResume">Resume</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- New Proposal -->
  <div v-else>
    <!-- Select Department -->
    <div class="mb-lg">
      <p class="has-text-weight-bold is-size-6 mb-sm">Merchant Category</p>
      <b-field
        :type="('department' in errors) ? 'is-danger' : ''"
        :message="('department' in errors) ? errors['department'][0] : ''">
        <DepartmentSelect v-model="department" :connectionId="(connection) ? connection.id : null" size="is-medium" />
      </b-field>
    </div>
    <div v-if="department">
      <!-- Sample Template -->
      <div class="mb-lg">
        <p class="has-text-weight-bold is-size-6">Add file for import</p>
        <p class="mb-md is-size-7">
          Please complete and upload our line sheet template.
          <a
            v-if="department.sample_template_url"
            :href="department.sample_template_url"
            target="_blank"
            class="has-text-info"
            download>Download sample</a>
        </p>
        <b-message size="is-small">
          <span class="has-text-weight-bold is-alert">Note: </span>
          Required file headers and guidelines are available in the sample template.</b-message>
      </div>
      <!--- File -->
      <div class="mb-lg">
        <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile" :showLabel='true'/>
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            >Cancel</b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            @click="goForward">Import</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Progress Modal -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showResetProgressModal"
    :can-cancel="['x']"
    ><ResetProgressModal :task="task" :proposal="proposal" @task:reset="$emit('task:reset')" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DepartmentSelect from '@/components/Models/ProposalDepartment/DepartmentSelect'
import CoreUpload from '@/components/CoreUpload'
import ResetProgressModal from './ResetProgressModal'

export default {
  components: {
    DepartmentSelect,
    CoreUpload,
    ResetProgressModal
  },
  computed: {
    ...mapGetters(['api', 'app', 'context']),
    ready () {
      return (this.department && this.tempfile)
    }
  },
  data () {
    return {
      department: null,
      tempfile: null,
      saving: false,
      errors: {},
      showResetProgressModal: false
    }
  },
  methods: {
    updateTempfile (file, response) {
      this.tempfile = response
    },
    async goForward () {
      this.saving = true
      try {
        await this.createProposal()
        await this.createFeedQueue()
        await this.updateTaskInfo()
        this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
        this.$store.dispatch('setUnsavedChanges', true)
        this.$emit('navigate', 'Progress')
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occured!' })
      }
      this.saving = false
    },
    goResume () {
      this.$store.dispatch('setUnsavedChanges', true)
      this.$emit('navigate', 'Progress')
    },
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    },
    createProposal () {
      const endpoint = this.context.route + '/proposals/'
      const body = {
        connection: {
          id: this.connection.id
        },
        name: 'Onboarding Proposal: ' + this.context.partner.name + ' - ' + this.department.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'console',
        proposal_type: 'new_product',
        department: {
          id: this.department.id
        },
        recipient: {
          user_id: this.department.owner_user.id
        }
      }
      return this.$http.post(endpoint, body).then(response => {
        this.$emit('proposal:created', response.data)
        const taskEndpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
        const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
        taskBody.info.proposal_id = response.data.id
        this.$http.patch(taskEndpoint, taskBody)
      })
    },
    createFeedQueue () {
      const endpoint = this.context.route + '/feed-queue/'
      const body = {
        template: {
          id: this.department.template.id
        },
        direction: 'import',
        data_type: 'products',
        source: 'console',
        behavior: 'merge',
        info: {
          assign_to_proposal_id: this.proposal.id,
          proposal_feed_purpose: 'combined',
          rulesets: [{
            id: this.department.brand_ruleset.id
          }, {
            id: this.department.retailer_ruleset.id
          }]
        },
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      return this.$http.post(endpoint + 'enqueue/', body).then(response => {
        this.$emit('feed:queued', response.data)
        this.$http.put(endpoint + response.data.id + '/run/')
      })
    },
    resetTempfile () {
      this.tempfile = null
    },
    updateTaskInfo () {
      const taskEndpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      const taskBody = {
        info: {
          proposal_id: this.proposal.id,
          feed_queue_id: this.feed.id
        }
      }
      return this.$http.patch(taskEndpoint, taskBody)
    }
  },
  mounted () {

  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object
    },
    feed: {
      type: Object
    }
  }
}
</script>
