<template>
  <div>
    <div v-if="platform === null">
      <SelectPlatform @platform:selected="setPlatform" />
    </div>
    <div v-else>
      <component :is="platform.name"
        :platform="platform"
        @canceled="platform = null"
        @account:saved="refreshAccounts"
      />
    </div>
  </div>
</template>

<script>
import SelectPlatform from './SelectPlatform'
import BigCommerce from './BigCommerce'
import Fabric from './Fabric'
import Returnly from './Returnly'
import ShipStation from './ShipStation'
import Shopify from './Shopify'
import WooCommerce from './WooCommerce'

export default {
  components: {
    SelectPlatform,
    BigCommerce,
    Fabric,
    Returnly,
    ShipStation,
    Shopify,
    WooCommerce
  },
  computed: {

  },
  data () {
    return {
      platform: null
    }
  },
  methods: {
    close () {
      this.$parent.close()
    },
    refreshAccounts () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Integration credentials added!' })
      this.$parent.close()
      this.$emit('updated')
    },
    setPlatform (platform) {
      this.platform = platform
    }
  }
}
</script>
