<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Import Offers" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Import Offers</h1>
        </div>
      </div>
      <!-- Step 1: Select Connection -->
      <div class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 1: Select Merchant</p>
            <p class="has-text-grey">Offers that you upload will only be applied to the selected merchant partner.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <p class="mb-lg">
                When you upload "offers" to {{ app.platform }}, you are granting permission
                to the selected merchant partner to sell your items at the
                costs you specify in your upload. To begin, please select a merchant partner
                to verify that your partner allows you to update costs.
              </p>
              <div class="mb-xl">
                <b-field label="Connection">
                  <ConnectionSelect v-model="connection" />
                </b-field>
              </div>
              <div v-if="connection !== null">
                <b-message type="is-success">
                  This partner allows you to upload offers.
                </b-message>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2: Download Template -->
      <div class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 2: Download Template</p>
            <p class="has-text-grey">Download a pricing template to import your pricing into {{ app.platform }}.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <!-- Instructions -->
              <div class="mb-lg">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p>
                      The offer template allows you to specify 5 columns. Fields
                      denoted with an asterisk (<b class="has-text-danger">*</b>) are required.
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <a :href="assets.templates.import.pricing.sample" target="_blank" class="button is-fullwidth is-primary is-outlined rounded-lg" download>Download Template</a>
                  </div>
                </div>
              </div>
              <!-- Fields -->
              <div>
                <!-- Item SKU -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Item SKU</span>
                          <b class="has-text-danger"> * </b>
                        </div>
                        <div class="column is-narrow">
                          <p>Learn More</p>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      Your unique identifier for the the item.
                    </div>
                  </b-collapse>
                </div>
                <!-- Cost -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Merchant Cost</span>
                          <b class="has-text-danger"> * </b>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      In the Commerce Network, suppliers pay upfront
                      for shipping labels, but are later reimbursed for the shipping
                      & handling fee set per item. The "Dropship Cost" represents
                      your "all-in" cost to sell <u>AND</u> ship this item anywhere
                      via domestic ground shipping. This cost Should also include any
                      order processing fees (sometimes added by 3PLs).
                    </div>
                  </b-collapse>
                </div>
                <!-- Retail -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Suggested Retail Price</span>
                          <i class="has-text-grey is-size-7"> optional </i>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The suggested retail price. Merchants may choose to make
                      adjustments to the retail price in accordance with your
                      supplier agreement. {{ app.platform }} does not monitor retail
                      price.
                    </div>
                  </b-collapse>
                </div>
                <!-- Start Date -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Start Date</span>
                          <i class="has-text-grey is-size-7"> optional </i>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The date on which the cost becomes effective. It is recommended
                      to leave this column blank (which will default to make the cost
                      effective immediately)
                    </div>
                  </b-collapse>
                </div>
                <!-- End Date -->
                <div class="has-background-white-ter pa-md rounded-lg">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">End Date</span>
                          <i class="has-text-grey is-size-7"> optional </i>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The date after which the offer becomes invalid. It is highly
                      recommended to leave this column blank (which will not
                      set an end date) as orders cannot be placed on items
                      with invalid offers.
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 3: Upload File -->
      <div class="mb-lg pb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 3: Upload File</p>
            <p class="has-text-grey">Select and upload your pricing updated pricing file.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl">
              <div class="pa-xl">
                <!-- Upload -->
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
              </div>
              <div class="has-border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column">
                    <div class="px-sm has-text-grey-light">
                      <i v-if="tempfile !== null">Click 'Import File' to upload your file for processing.</i>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <form @submit.prevent="queueFile">
                      <div class="px-sm">
                        <b-button
                          native-type="submit"
                          type="is-primary"
                          :loading="scheduling"
                          :disabled="!ready || scheduling"
                        >Import File</b-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
export default {
  components: {
    CoreUpload,
    ConnectionSelect
  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    endpoint () {
      return this.context.route + '/permits/bulk/'
    },
    ready () {
      return this.connection !== null && this.tempfile !== null
    },
    json () {
      return {
        retailers: [this.connection.retailer.id],
        tempfile_id: this.tempfile.id,
        require_cost: 1
      }
    }
  },
  data () {
    return {
      connection: null,
      tempfile: null,
      scheduling: false,
      exporting: false
    }
  },
  methods: {
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.scheduling = false
        this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  }
}
</script>
