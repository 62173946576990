<template>
  <div>
    <div class="columns">
      <!-- Left Side -->
      <div class="column">
        <!-- Info -->
        <div class="mb-lg">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold ">Connection Detail</p>
              </div>
              <div v-if="context.isMerchant()" class="column is-narrow">
                <a href="#" @click.prevent="$emit('edit:connection')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr v-if="context.isSupplier()">
                <th>Retailer</th>
                <td class="has-text-right">{{ connection.retailer.name }}</td>
              </tr>
              <tr v-else>
                <th>Supplier</th>
                <td class="has-text-right">{{ connection.brand.name }}</td>
              </tr>
              <!-- MKP-2811 remove Supplier # for Supplier-->
              <tr v-if="context.isMerchant()">
                <th>Supplier #</th>
                <td class="has-text-right">
                  <span v-if="connection.retailer_identifier">{{ connection.retailer_identifier }}</span>
                  <span v-else>--</span>
                </td>
              </tr>
              <tr v-if="context.isSupplier()">
                <th>Retailer ID</th>
                <td class="has-text-right">{{ connection.retailer.id }}</td>
              </tr>
              <tr v-else>
                <th>{{ app.platform }} Supplier ID</th>
                <td class="has-text-right">{{ connection.brand.id }}</td>
              </tr>
              <tr>
                <th>{{ (context.isSupplier()) ? `Supplier&#39;s Integration Type` : 'Integration Type' }}</th>
                <td class="has-text-right"><Integration :connection="connection" @show:connectionAccounts="$emit('show:connectionAccounts')"/></td>
              </tr>
              <tr v-if="context.isMerchant()">
                <th>Connection Level</th>
                <td class="has-text-right">{{ connectionLevel }}</td>
              </tr>
              <tr>
                <th>Catalog Import Method</th>
                <td class="has-text-right">{{ importMethodType }}</td>
              </tr>
              <!-- To do...remove 'packing_slip_template' property check once backend ready -->
              <tr v-if="hasTransactions && 'packing_slip_template' in connection">
                <th>Packing Slip Template</th>
                <td class="has-text-right">
                  <PackingSlip :template="connection.packing_slip_template" />
                </td>
              </tr>
              <!-- <tr v-if="canCustomizePackingSlipCopy">
                <th>Custom Packing Slip Copy</th>
                <td class="has-text-right">
                  <a class="has-text-info has-text-weight-bold" href="#" @click.prevent="showPackingSlipCopyModal = true">View</a>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!-- Fees -->
        <div class="mb-lg" v-if="hasTransactions">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold ">Payment Settings</p>
              </div>
              <div v-if="context.isMerchant()" class="column is-narrow">
                <a href="#" @click.prevent="$emit('edit:fees')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>Cost Tracking</th>
                <td class="has-text-right"><NetPriceMethod :connection="connection" /></td>
              </tr>
              <tr>
                <th>Payment Terms</th>
                <td class="has-text-right"><PaymentTerms :connection="connection" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- SLAs -->
        <div class="mb-lg" v-if="hasTransactions">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold ">SLAs</p>
              </div>
              <div v-if="context.isMerchant()" class="column is-narrow">
                <a href="#" @click.prevent="$emit('edit:slas')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>
                  <b-tooltip label="The number of days a vendor has to process an order before it is considered late (may be overridden by item level setting)." dashed multilined position="is-left">
                    Fulfillment SLA
                  </b-tooltip>
                </th>
                <td class="has-text-right"><FulfilmentSla :connection="connection" /></td>
              </tr>
              <!-- <tr>
                <th>
                  <b-tooltip label="The number of days a vendor has to update inventory before an item is considered stale." dashed multilined position="is-top">
                    Inventory SLA
                  </b-tooltip>
                </th>
                <td class="has-text-right">{{ connection.connection_slas.brand_inventory_hours / 24 }} Business Days</td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!-- Onboarding-->
        <div class="mb-lg" v-if="connection.status === 'onboarding'">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold ">Onboarding</p>
              </div>
              <div v-if="context.isMerchant()" class="column is-narrow">
                <a href="#" @click.prevent="$emit('edit:connection')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>Target Launch Date</th>
                <td class="has-text-right">{{ (connection.onboarding_target_date) ? $dates(connection.onboarding_target_date).format('MMM D, YYYY') : '--' }} </td>
              </tr>
              <tr>
                <th>Welcome Call Date</th>
                <td class="has-text-right">{{ (connection.intro_call_date) ? $dates(connection.intro_call_date).format('MMM D, YYYY') : '--' }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Right Side -->
      <div class="column">
        <!-- Supplier Contacts -->
        <div class="mb-lg">
          <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Supplier Contacts</p>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>Business</th>
                <td class="has-text-right">
                  <div v-if="primaryContact">
                    <p>{{ primaryContact.first_name }} {{ primaryContact.last_name }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Merchandising</th>
                <td class="has-text-right">
                  <div v-if="merchandisingContact">
                    <p>{{ merchandisingContact.first_name }} {{ merchandisingContact.last_name }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Fulfillment</th>
                <td class="has-text-right">
                  <div v-if="fulfillmentContact">
                    <p>{{ fulfillmentContact.first_name }} {{ fulfillmentContact.last_name }}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Retailer Contacts -->
        <div class="mb-lg">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold">Retailer Contacts</p>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>Merchandising</th>
                <td class="has-text-right">
                  <div v-if="context.isMerchant()">
                    <a href="#" @click.prevent="editContact('merchandising')" class="has-text-info has-text-weight-bold">
                      <span v-if="retailerMerchandisingContact">{{ retailerMerchandisingContact.first_name }} {{ retailerMerchandisingContact.last_name }}</span>
                      <span v-else>- Not Set -</span>
                    </a>
                  </div>
                  <div v-else>
                    <p class="has-text-grey-light">
                      <span v-if="retailerMerchandisingContact">{{ retailerMerchandisingContact.first_name }} {{ retailerMerchandisingContact.last_name }}</span>
                      <span v-else>- Not Set -</span>
                    </p>
                  </div>
                </td>
              </tr>
              <tr v-if="hasTransactions">
                <th>Fulfillment</th>
                <td class="has-text-right">
                  <div v-if="context.isMerchant()">
                    <a href="#" @click.prevent="editContact('orders')" class="has-text-info has-text-weight-bold">
                      <span v-if="retailerFulfillmentContact">{{ retailerFulfillmentContact.first_name }} {{ retailerFulfillmentContact.last_name }}</span>
                      <span v-else>- Not Set -</span>
                    </a>
                  </div>
                  <div v-else>
                    <p class="has-text-grey-light">
                      <span v-if="retailerFulfillmentContact">{{ retailerFulfillmentContact.first_name }} {{ retailerFulfillmentContact.last_name }}</span>
                      <span v-else>- Not Set -</span>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Options -->
        <div>
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold ">Settings</p>
              </div>
              <div v-if="context.isMerchant()" class="column is-narrow">
                <a href="#" @click.prevent="$emit('edit:options')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table v-if="hasConnectionOptions" class="table is-narrow is-fullwidth is-striped is-size-7">
            <tbody>
              <tr v-for="option in connection.options" :key="option.id">
                <td>&check; &nbsp;{{ getLabel(option) }}</td>
              </tr>
            </tbody>
          </table>
          <table v-else class="table is-fullwidth is-striped is-size-7 border-b">
            <tbody>
              <tr>
                <td>
                  &check; &nbsp;
                  <b-tooltip label="This connection uses default settings, and has no specialized configuration." dashed multilined>
                      Uses Default Settings
                  </b-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Update Contacts Modal -->
    <b-modal
      has-modal-card
      :active.sync="showUpdateContactsModal"
      :can-cancel="['escape', 'x']"
      ><UpdateContactsModal :connection="connection" :contactType="updateContactType" @connection:updated="$emit('updated')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Integration from '@/components/Models/Connection/Presenters/Integration'
import PackingSlip from '@/components/Models/Connection/Presenters/PackingSlip'
import NetPriceMethod from '@/components/Models/Connection/Presenters/NetPriceMethod'
import PaymentTerms from '@/components/Models/Connection/Presenters/PaymentTerms'
import FulfilmentSla from '@/components/Models/Connection/Presenters/FulfilmentSla'
import UpdateContactsModal from '@/components/Models/Connection/Modals/UpdateContacts'

export default {
  components: {
    Integration,
    FulfilmentSla,
    PackingSlip,
    PaymentTerms,
    NetPriceMethod,
    UpdateContactsModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    hasConnectionOptions () {
      return (Object.keys(this.connection.options).length > 0)
    },
    hasTransactions () {
      return Boolean(this.connection.level === 'transactions_and_products')
    },
    connectionLevel () {
      return (this.connection.level === 'products_only')
        ? 'Products Only'
        : 'Transactions & Products'
    },
    primaryContact () {
      if (this.context.isMerchant()) {
        return this.connection.brand.connections_manager
      }
      return this.connection.retailer.connections_manager
    },
    merchandisingContact () {
      if (this.context.isMerchant()) {
        return this.connection.brand.merchandising_manager
      }
      return this.connection.retailer.merchandising_manager
    },
    retailerMerchandisingContact () {
      let merchContact = null
      this.connection.connection_contacts.forEach(contact => {
        if ('retailer' in contact && contact.contact_type === 'merchandising') {
          merchContact = contact
        }
      })
      return merchContact
    },
    retailerFulfillmentContact () {
      let fulfillmentContact = null
      this.connection.connection_contacts.forEach(contact => {
        if ('retailer' in contact && contact.contact_type === 'orders') {
          fulfillmentContact = contact
        }
      })
      return fulfillmentContact
    },
    fulfillmentContact () {
      if (this.context.isMerchant()) {
        return this.connection.brand.shipping_manager
      }
      return this.connection.retailer.transaction_manager
    },
    importMethodType () {
      let type = '--'
      if (this.hasConnectionOptions) {
        const isProposal = this.$utils.find(this.connection.options, ['code', 'merchandise_via_proposals'])
        const isImportRequest = this.$utils.find(this.connection.options, ['code', 'merchandise_via_import_request'])
        type = isProposal ? 'Proposals' : isImportRequest ? 'Import Request' : '--'
      }
      return type
    }
  },
  data () {
    return {
      showUpdateContactsModal: false,
      updateContactType: null
    }
  },
  methods: {
    getLabel (option) {
      const str = option.name ? option.name : option.code.replace(/_/g, ' ')
      return str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase())
    },
    editContact (type) {
      this.showUpdateContactsModal = true
      this.updateContactType = type
    }
  },
  props: ['connection']
}
</script>
