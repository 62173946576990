<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="mb-lg">
        The {{ app.productName }} Shopify app can be installed to automatically push purchase
        orders into your Shopify store and pull tracking numbers once you ship. To install the app,
        please enter your Store URL below:
      </p>
      <!-- Account Exists -->
      <div v-if="account !== null">
        <ShopifyPresenter :account="account" />
      </div>
      <!-- Account Doesn't Exist -->
      <div v-else class="mb-lg">
        <!-- Shop Name -->
        <div class="mb-lg">
          <b-field label="Shopify Store Name" class="mb-lg">
            <p class="control">
              <span class="button is-static is-medium">https://</span>
            </p>
            <b-input v-model="storeName" expanded size="is-medium" placeholder="Shopify Store Name"></b-input>
            <p class="control">
              <span class="button is-static is-medium">.myshopify.com</span>
            </p>
          </b-field>
        </div>
        <!-- Shop Token -->
        <b-field label="API Key">
          <b-input v-model="storeToken" expanded size="is-medium" placeholder="Shopify API Key"></b-input>
        </b-field>
        <!-- Key Documentation -->
        <div class="mt-xl has-text-centered">
          <p><a class="has-text-info" target="_blank" href="https://res.cloudinary.com/revcascade/image/upload/v1664948385/static/resources/fabricMarketplace_SupplierShopifyAppSetup.pdf" downloadclass="has-text-info">Learn how to obtain an API Key</a></p>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving || !ready"
            :loading="saving"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ShopifyPresenter from '@/components/Models/PlatformAccount/Presenters/Shopify'

export default {
  components: {
    ShopifyPresenter
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.account || (this.storeName && this.storeToken))
    }
  },
  data () {
    return {
      storeName: null,
      storeToken: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      // if platform account exists, don't do anything
      if (this.account !== null) {
        this.$emit('navigate', 'Install')
        return
      }
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'shopify' },
        credentials: [{
          credential: { code: 'shopify_shop_name' },
          value: this.storeName
        }, {
          credential: { code: 'shopify_token' },
          value: this.storeToken
        }, {
          credential: { code: 'shopify_private_app' },
          value: 1
        }]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('platform:created', response.data)
        this.$emit('navigate', 'Inventory')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
