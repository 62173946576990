<template>
  <div>
    <table class="table is-fullwidth is-striped is-narrow is-size-8 border">
      <tbody>
        <tr>
          <th>ID</th>
          <td class="has-text-right">{{ feed.id }}</td>
          <th>Feed Type</th>
          <td class="has-text-right">{{ $utils.upperFirst(feed.direction) }} {{ $utils.upperFirst(feed.data_type) }}</td>
        </tr>
        <tr>
          <th>Filename</th>
          <td class="has-text-right">{{ $utils.truncate(feed.original_filename, { length: 32 }) }}</td>
          <th>Template</th>
          <td class="has-text-right">{{ feed.template.name }}</td>
        </tr>
        <tr>
          <th>File Link</th>
          <td class="has-text-right">
            <DownloadFileAs label="Download" :cloudFileName="feed.cloud_filename" :originalFileName="feed.original_filename"/>
          </td>
          <th>Status</th>
          <td class="has-text-weight-bold has-text-right">
            <span v-if="feed.status === 'success'" class="has-text-success">Success</span>
            <span v-else-if="feed.status === 'failure'" class="has-text-danger">Failed</span>
            <span v-else-if="feed.status === 'processing'" class="has-text-danger">Processing</span>
            <span v-else>Queued</span>
          </td>
        </tr>
        <tr>
          <th>Started At</th>
          <td class="has-text-right">
            <span v-if="feed.processed_at">{{ $dates.utc(feed.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
            <span v-else class="has-text-grey is-italic">Not Started</span>
          </td>
          <th>Completed At</th>
          <td class="has-text-right">
            <span v-if="feed.completed_at">{{ $dates.utc(feed.completed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
            <span v-else class="has-text-grey is-italic">Not Completed</span>
          </td>
        </tr>
        <tr>
          <th>Wait time</th>
          <td class="has-text-right"><Runtime :start="feed.created_at" :end="feed.processed_at" /></td>
          <th>Processing Time</th>
          <td class="has-text-right"><Runtime :start="feed.processed_at" :end="feed.completed_at" /></td>
        </tr>
        <tr>
          <th># of Rows</th>
          <td class="has-text-right">{{ feed.num_items }}</td>
          <th># of Items Created</th>
          <td class="has-text-right">{{ feed.num_created }}</td>
        </tr>
        <tr>
          <th># of Items Updated</th>
          <td class="has-text-right">{{ feed.num_updated }}</td>
          <th># of Warnings</th>
          <td class="has-text-right">{{ feed.num_warnings }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Runtime from './Runtime'

export default {
  components: {
    Runtime
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  }
}
</script>
