<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Connection Settings</p>
        </header>
        <section class="modal-card-body">
          <!-- General Errors -->
          <div v-if="error" class="mb-md">
            <b-message type="is-danger">{{ error }}</b-message>
          </div>
          <!-- Connection options -->
          <div class="is-sisze-7">
            <div v-for="(option) in options" :key="option.code">
              <div v-if="!option.restricted || (option.restricted && auth.isStaff)"  class="has-background-light rounded-lg pa-md mb-xxs">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p class="has-text-weight-bold">{{ getLabel(option) }}</p>
                    <p class="is-size-8 has-text-grey">{{ option.description }}</p>
                  </div>
                  <div class="column is-narrow">
                    <b-switch
                      @input="switchOption(option)"
                      v-model="option.value"
                      type="is-success"
                      true-value='1'
                      false-value='0'
                      >{{ option.value === '1' ? 'Yes' : 'No' }}
                    </b-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Save Settings
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      options: null,
      saving: false,
      error: null,
      errors: {},
      editableOptions: [{
        name: 'Ship From Retailer',
        code: 'return_to_retailer_warehouse',
        description: 'Populate the "ship from" on all shipping labels generated from the platfrom with a merchant return address. Some carriers do no respect this address, so it should be viewed as cosmetic only.'
      }, {
        name: 'Auto-populate Invoice Data',
        code: 'enforce_invoice_integrity',
        description: 'Auto-populate all supplier invoice data except Invoice Number. Enabling this feature may reduce invoicing discrepancies, but suppliers will not be able to submit custom adjustments.'
      }, {
        name: 'Merchandise via Proposals?',
        code: 'merchandise_via_proposals',
        description: 'Require suppliers to submit proposals to onboard product information.'
      }, {
        name: 'Require Pricing Approval On Proposals?',
        code: 'require_pricing_approval_on_proposals',
        description: 'Add an additional pricing approval step to the proposal workflow.'
      }, {
        name: 'Include 850 In Packing Slip?',
        code: 'include_850_packing_slip',
        description: 'Include a link to the packing slip in the 850 PO (EDI Suppliers only)'
      }, {
        name: 'Create Permits Automatically (Staff)',
        code: 'create_permits_on_product_create',
        description: 'Gain access to products as soon as they are created.',
        restricted: true
      }, {
        name: 'Auto Invoice And Close Order When Fulfilled',
        code: 'auto_invoice_and_close_on_order_fulfilled',
        description: 'As soon as an order is fully shipped, automatically generate an invoice and close the order.'
      }]
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/'
    }
  },
  methods: {
    save () {
      const connectionOptions = this.options.map(el => {
        return {
          [el.code]: el.value === '1'
        }
      })
      const json = {
        connection_options: Object.assign(...connectionOptions)
      }
      this.saving = true
      this.$http.patch(this.endpoint, json).then(response => {
        this.$emit('saved:connection')
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && 'non_field_errors' in error.response.data) {
          this.error = error.response.data.non_field_errors[0]
        }
      }).finally(() => {
        this.saving = false
      })
    },
    getLabel (option) {
      const str = option.name ? option.name : option.code.replace(/_/g, ' ')
      return str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase())
    },
    switchOption (option) {
      this.options = this.options.map(el => el.code === option.code ? option : el)
    }
  },
  mounted () {
    const connectionOptions = this.connection.options
    const optionsArray = Object.values(connectionOptions)
    const result = this.editableOptions.map(el => {
      if (optionsArray.some((e) => e.code === el.code)) {
        el.value = '1'
      }
      return el
    })
    this.options = result
  },
  props: ['connection']
}
</script>
