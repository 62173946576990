<template>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add {{ platform.name }} Integration</p>
        </header>
        <section class="modal-card-body">
          <div class="has-text-centered">
            <img :src="platform.logo" width="180" height="60" />
          </div>
          <div class="mb-lg">
            <b-field label="WooCommerce Store URL">
              <b-input v-model="storeName" expanded size="is-medium" placeholder="WooCommerce Store URL"></b-input>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <button class="button" type="button" @click="$emit('canceled')">Back</button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving"
          >Add Integration</b-button>
        </footer>
      </div>
    </form>
  </template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/'
    },
    json () {
      return {
        platform: { code: this.platform.code },
        credentials: [{
          credential: { code: 'woocommerce_shop_uri' },
          value: this.storeName
        }]
      }
    },
    ready () {
      return this.storeName
    },
    redirectUrl () {
      return this.app.url + '/onboarding'
    }
  },
  data () {
    return {
      storeName: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'woocommerce' },
        credentials: [{
          credential: { code: 'woocommerce_shop_uri' },
          value: this.storeName
        }]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('account:saved')
        const installUrl = this.context.route + '/woocommerce/' + response.data.id + '/request-permission/'
        this.$http.get(installUrl).then(res => {
          window.location.href = res.data.redirect_url
        })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    platform: {
      type: Object,
      required: true
    }
  }
}
</script>
