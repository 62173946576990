var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mb-md"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-one-third"},[_c('breadcrumbs',{attrs:{"history":[{ label: 'Settings', name: 'settings'}],"current":"Integrations"}})],1),_vm._m(0)])]),_c('div',{},[_c('div',{staticClass:"has-background-white pa-md rounded-lg mb-xs"},[_c('div',{staticClass:"columns is-vcentered space-between"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(_vm.accounts.length)+" "+_vm._s((_vm.accounts.length) === 1 ? 'Integration' : 'Integrations'))])]),_c('div',{staticClass:"column is-narrow"},[_c('b-button',{attrs:{"type":"is-primary","outlined":""},on:{"click":function($event){_vm.showIntegrationModal = true}}},[_vm._v(" Add Integration ")])],1)])]),(!_vm.loading && _vm.accounts.length > 0)?_c('div',_vm._l((_vm.accounts),function(account){return _c('div',{key:account.id,staticClass:"has-background-white rounded-lg pa-lg my-xxs"},[(account.platform.code === 'returnly')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png',"alt":"returnly logo."}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.returnly', params: { id: account.id} }}},[_vm._v("Configuration")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.returnly.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1)],1)],1)])]):_vm._e(),(account.platform.code === 'fabric')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png',"alt":"fabric logo."}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.returnly', params: { id: account.id} }}},[_vm._v("Configuration")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.returnly.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1)],1)],1)])]):_vm._e(),(account.platform.code === 'bill')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/static/integrations/billcom.png',"alt":"bill.com logo."}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.returnly', params: { id: account.id} }}},[_vm._v("Configuration")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.returnly.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1)],1)],1)])]):(account.platform.code === 'shipstation')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png',"alt":"shipstation logo."}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.shipstation', params: { id: account.id} }}},[_vm._v("Configuration")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.shipstation.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.shipstation.utilities', params: { id: account.id} }}},[_vm._v("Utilities")])],1)],1)],1)])]):(account.platform.code === 'shopify')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.shopify', params: { id: account.id} }}},[_vm._v("Configuration")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.shopify.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'shopify.templates', params: { id: account.id} }}},[_vm._v("Shopify Templates")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.shopify.utilities', params: { id: account.id} }}},[_vm._v("Utilities")])],1)],1)],1)])]):(account.platform.code === 'bigcommerce')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/static/integrations/' + account.platform.code + '.png'}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.bigcommerce', params: { id: account.id} }}},[_vm._v("Configuration")])],1),(_vm.context.isMerchant())?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.bigcommerce.utilities', params: { id: account.id} }}},[_vm._v("Utilities")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.bigcommerce.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1)],1)],1)])]):(account.platform.code === 'woocommerce')?_c('div',[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-3x1"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":'https://res.cloudinary.com/revcascade/image/upload/v1673974346/static/integrations/woocommerce.png'}})])]),_c('div',{staticClass:"column is-narrow"},[_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('button',{staticClass:"button"},[_c('span',[_vm._v("Options")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.woocommerce', params: { id: account.id} }}},[_vm._v("Configuration")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'settings.integrations.woocommerce.webhooks', params: { id: account.id} }}},[_vm._v("Webhook History")])],1)],1)],1)])]):_vm._e()])}),0):(!_vm.loading && _vm.accounts.length === 0)?_c('div',[_c('div',{staticClass:"has-background-white pa-md rounded-lg mb-xs"},[_c('div',{staticClass:"my-xxl has-text-grey-light has-text-centered"},[_c('p',[_vm._v("No integrations added yet.")]),_c('p',[_vm._v("Click \"Add Integration above to add integration credentials.")]),_c('p',{staticClass:"py-lg"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){_vm.showIntegrationModal = true}}},[_vm._v(" Add Integration ")])],1)])])]):_c('div',_vm._l((3),function(index){return _c('div',{key:index,staticClass:"has-background-white pa-md mb-xs rounded-xl"},[_c('div',{staticClass:"placeholder placeholder-title",staticStyle:{"width":"66%"}}),_c('div',{staticClass:"placeholder placeholder-sentence",staticStyle:{"width":"50%"}})])}),0)])])]),_c('b-modal',{attrs:{"active":_vm.showIntegrationModal,"can-cancel":['escape', 'x'],"has-modal-card":"","scroll":"keep"},on:{"update:active":function($event){_vm.showIntegrationModal=$event},"close":function($event){_vm.account = null}}},[_c('PlatformAccountModal',{on:{"updated":_vm.load}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-one-third"},[_c('h1',{staticClass:"has-text-centered is-size-4"},[_vm._v("Integrations")])])}]

export { render, staticRenderFns }