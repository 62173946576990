<template>
  <div class="section">
    <div class="container">
      <div class="mb-lg">
        <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h4 class="is-size-4 has-text-centered">{{ merchantOrSupplier }} Details</h4>
            </div>
          </div>
      </div>
      <div v-if="!loadingAllPeriods && allAvailablePeriods.length">
        <div class="mb-md">
          <div class="mb-xs has-background-white pa-md rounded-lg">
            <div class="columns">
              <div class="column">
                <div class="has-text-weight-bold is-size-5">{{ getName }}</div>
                <div class="has-text-grey-light">Connection ID {{this.$route.params.connection_id}}</div>
              </div>
              <div class="column is-one-quarter">
                <div class="field">
                  <b-select size="is-medium" v-model="selectedMonth" @input="reloadMonth" expanded>
                    <option
                      v-for="period in allAvailablePeriods"
                      :value="period"
                      :key="period.id"
                      selected
                    >
                      {{ period.label }}
                    </option>
                  </b-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ComplianceGraphs
          :loading="loadingRevenueData"
          :scoreHistoryData="scoreHistoryData"
          :thisMonthsIndex="thisMonthsIndex"
          :connectionsCount="connectionsCount"
          :isMerchant="context.isMerchant()"
        ></ComplianceGraphs>
        <ComplianceSummary
          :scoreData="thisMonthsScoreData"
          :lastMonthsScoreData="lastMonthsScoreData"
          :loading="loadingRevenueData"
        ></ComplianceSummary>
        <RevenueSummary
          :thisMonthsOrderTotals="thisMonthsRevenueData"
          :lastMonthsOrderTotals="previousMonthsRevenueData"
          :loading="loadingRevenueData"
        ></RevenueSummary>
      </div>
      <div v-else>
        <loading />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ComplianceGraphs from '../components/ComplianceGraphs.vue'
import ComplianceSummary from '../components/ScoreDetailComplianceSummary.vue'
import RevenueSummary from '../components/ScoreDetailRevenueSummary.vue'

export default {
  components: {
    ComplianceGraphs,
    ComplianceSummary,
    RevenueSummary
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return {
        current: this.thisMonthsScoreData ? this.context.isMerchant() ? `Supplier ${this.thisMonthsScoreData.brand.name}` : `Merchant ${this.thisMonthsScoreData.retailer.name}` : '',
        history: [
          { label: 'Compliance', name: 'reports.compliance.score' }
        ]
      }
    },
    merchantOrSupplier () {
      return this.context.isMerchant() ? 'Supplier' : 'Merchant'
    },
    getName () {
      return this.context.isMerchant() ? this.thisMonthsScoreData.brand.name : this.thisMonthsScoreData.retailer.name
    },
    loadingRevenueData () {
      return (this.loadingThisMonthRevenueSummary || this.loadingLastMonthRevenueSummary)
    },
    connectionsCount () {
      return this.thisMonthsScoreData ? this.thisMonthsScoreData.connections_count : 'N/A'
    },
    lastMonthsScoreData () {
      if (this.scoreHistoryData && this.scoreHistoryData[1]) {
        return this.scoreHistoryData[1].results[0]
      }
      return null
    },
    revenueQueryStringThisMonth () {
      const qs = {
        params: {
          end_date: this.$dates.unix(this.$route.query.period_end).format('YYYY-MM-DD'),
          start_date: this.$dates.unix(this.$route.query.period_end).startOf('month').format('YYYY-MM-DD'),
          dimension: 'date',
          interval: 'month',
          brands: this.context.isMerchant() ? this.$route.params.id : this.context.partner.id,
          retailer: this.context.isMerchant() ? this.context.partner.id : this.$route.params.id
        }
      }
      return qs
    },
    revenueQueryStringPreviousMonth () {
      const qs = {
        params: {
          end_date: this.$dates.unix(this.$route.query.period_end).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
          start_date: this.$dates.unix(this.$route.query.period_end).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          dimension: 'date',
          interval: 'month',
          brands: this.context.isMerchant() ? this.$route.params.id : this.context.partner.id,
          retailer: this.context.isMerchant() ? this.context.partner.id : this.$route.params.id
        }
      }
      return qs
    }

  },
  data () {
    return {
      thisMonthsScoreData: null,
      scoreHistoryData: [],
      allAvailablePeriods: [],
      thisMonthsRevenueData: null,
      thisMonthsIndex: null,
      previousMonthsRevenueData: null,
      selectedMonth: null,
      loadingAllPeriods: false,
      loadingThisMonthRevenueSummary: false,
      loadingLastMonthRevenueSummary: false
    }
  },
  methods: {
    loadComplianceDetail () {
      this.loadingAllPeriods = true
      this.$http.get(this.context.route + '/connections/' + this.$route.params.connection_id + '/compliance-reports/' + this.$route.params.report_id + '/periods/?order_by=-start').then(response => {
        // persist all periods for dropdown calendar
        const allAvailablePeriods = response.data.results

        if (allAvailablePeriods.length) {
          allAvailablePeriods.forEach(period => {
            period.label = this.$dates.utc(period.end).local().format('MMMM YYYY')
            this.allAvailablePeriods.push(period)
          })
          const thisMonth = allAvailablePeriods.find(month => this.$dates(month.end).unix() === Number(this.$route.query.period_end))
          const thisMonthsIndex = allAvailablePeriods.map(month => month.period_index).indexOf(thisMonth.period_index)

          this.selectedMonth = thisMonth
          this.thisMonthsScoreData = thisMonth.results[0]
          this.thisMonthsIndex = this.$dates.utc(thisMonth.end).local().get('month') + 1

          // isolate half a year of compliance reports for graphs going back from the chosen month
          for (let i = 0; i < 6; i++) {
            if (allAvailablePeriods[thisMonthsIndex + i]) {
              this.scoreHistoryData.push(allAvailablePeriods[thisMonthsIndex + i])
            }
          }
        }
      }).finally(() => {
        this.loadingAllPeriods = false
      })
    },
    loadRevenueDetailThisMonth () {
      this.loadingThisMonthRevenueSummary = true
      this.$http.get(this.api.baseUrl + '/v1/summary/orders/', this.revenueQueryStringThisMonth).then(response => {
        const data = response.data.date
        if (Object.keys(data)) {
          this.thisMonthsRevenueData = data[Object.keys(data)[0]]
        }
      }).finally(() => {
        this.loadingThisMonthRevenueSummary = false
      })
    },
    loadRevenueDetailPreviousMonth () {
      this.loadingLastMonthRevenueSummary = true
      this.$http.get(this.api.baseUrl + '/v1/summary/orders/', this.revenueQueryStringPreviousMonth).then(response => {
        const data = response.data.date
        if (Object.keys(data)) {
          this.previousMonthsRevenueData = data[Object.keys(data)[0]]
        }
      }).finally(() => {
        this.loadingLastMonthRevenueSummary = false
      })
    },
    reloadMonth () {
      this.$router.push({
        name: 'reports.compliance.score.detail',
        params: {
          id: this.$route.params.id,
          connection_id: this.$route.params.connection_id,
          report_id: this.$route.params.report_id
        },
        query: { period_end: this.$dates(this.selectedMonth.end).unix() }
      })
      this.selectNewMonthFromAvailablePeriods()
      this.loadRevenueDetailThisMonth()
      this.loadRevenueDetailPreviousMonth()
    },
    selectNewMonthFromAvailablePeriods () {
      this.scoreHistoryData = []
      const thisMonth = this.allAvailablePeriods.find(month => this.$dates(month.end).unix() === this.$route.query.period_end)
      const thisMonthsIndex = this.allAvailablePeriods.map(month => month.period_index).indexOf(thisMonth.period_index)

      this.selectedMonth = thisMonth
      this.thisMonthsScoreData = thisMonth.results[0]
      this.thisMonthsIndex = this.$dates.utc(thisMonth.end).local().get('month') + 1

      for (let i = 0; i < 6; i++) {
        if (this.allAvailablePeriods[thisMonthsIndex + i]) {
          this.scoreHistoryData.push(this.allAvailablePeriods[thisMonthsIndex + i])
        }
      }
    }
  },
  mounted () {
    this.loadComplianceDetail()
    this.loadRevenueDetailThisMonth()
    this.loadRevenueDetailPreviousMonth()
  }
}
</script>
