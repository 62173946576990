<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Export Inventory</p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-info" class="is-size-7">
        The inventory export will be delivered to your email. Please note that the items export will only include
        the items that match your applied filters, so double check your filters before exporting.
      </b-message>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()" >Close</b-button>
      <b-button
        type="is-primary"
        @click="scheduleExport"
        :loading="exporting"
        :disabled="exporting"
      >Export</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Exportable from '@/mixins/Exportable'

export default {
  computed: {
    ...mapGetters(['context']),
    exportEndpoint () {
      return this.context.route + '/inventory/export-email/'
    }
  },
  data () {
    return {
      template: null,
      loadingMappings: false,
      mappings: [],
      showMappings: false
    }
  },
  mixins: [Exportable],
  props: {
    filters: {
      type: Object
    }
  }
}
</script>
