<template>
<div>
  <div class="pa-md">
    <!-- Task is loaded -->
    <div>
      <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">Welcome to {{ app.productName }}!</p>
      <div class="columns is-centered">
        <div class="column">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component :is="step"
                :connection="connection"
                :task="task"
                :brand="brand"
                @navigate="navigate"
                @completed="$emit('completed')"
                @metadata:saved="load(true)"
              ></component>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Intro from '../components/Welcome/Intro'
import Experience from '../components/Welcome/Experience'
import Integration from '../components/Welcome/Integration'
import Call from '../components/Welcome/Call'

export default {
  components: {
    Intro,
    Experience,
    Integration,
    Call
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      loading: true,
      brand: null,
      step: 'Intro'
    }
  },
  methods: {
    navigate (step) {
      this.step = step
      window.scrollTo(0, 0)
    },
    load (silent = false) {
      if (!silent) this.loading = true
      const endpoint = this.context.route + '/'
      this.$http.get(endpoint).then(response => {
        this.brand = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['connection', 'task']
}
</script>
