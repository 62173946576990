<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div v-if="showImage && imageUrl !== null" class="column is-narrow is-hidden-mobile">
        <!-- Image can be clicked -->
        <a v-if="imageInteraction !== 'none'"
          href="#"
          @click.prevent="handleImageInteraction">
          <figure class="image is-48x48">
            <img :src="imageUrl" class="rounded-lg" />
          </figure>
        </a>
        <!-- Image does Nothing -->
        <figure v-else class="image is-48x48">
          <img :src="imageUrl" class="rounded-lg" />
        </figure>
      </div>
      <div class="column">
        <div class="is-size-7">
          <!-- Name can be clicked -->
          <a v-if="nameInteraction !== 'none'"
            href="#"
            class="has-text-info has-text-weight-bold"
            @click.prevent="handleNameInteraction"
          >{{ name }}</a>
          <!-- Name does nothing -->
          <p v-else>{{ name }}</p>
        </div>
        <!-- SKU (Context Sensitive) -->
        <p v-if="context.isMerchant() && merchantSku !== null">
          <span class="is-size-8 has-text-grey">Merchant Product SKU </span>
          <span class="is-size-7 has-text-weight-bold">{{ merchantSku }}</span>
        </p>
        <p v-else>
          <span class="is-size-8 has-text-grey">Supplier Product SKU </span>
          <span class="is-size-7 has-text-weight-bold">{{ supplierSku }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
export default {
  computed: {
    ...mapGetters(['context']),
    name () {
      return this.product.name
    },
    supplierSku () {
      return this.product.parent_identifier
    },
    merchantSku () {
      return null
    },
    imageUrl () {
      if (this.showImage === false) return null
      if (this.product.primary_media === null) return null
      return this.cdnTransform(this.product.primary_media.url, 48, 48, 'fill')
    }
  },
  methods: {
    handleImageInteraction () {
      if (this.imageInteraction === 'navigate') {
        this.$emit('navigate', this.product)
      } else if (this.imageInteraction === 'inspect') {
        this.$emit('inspect', this.product)
      }
    },
    handleNameInteraction () {
      if (this.nameInteraction === 'navigate') {
        this.$emit('navigate', this.product)
      } else if (this.nameInteraction === 'inspect') {
        this.$emit('inspect', this.product)
      }
    }
  },
  mixins: [CdnTransform],
  props: {
    product: {
      type: Object,
      required: true
    },
    showImage: {
      type: Boolean,
      default: true
    },
    nameInteraction: {
      type: String,
      validator: (val) => ['navigate', 'inspect', 'none'].includes(val),
      default: 'navigate'
    },
    imageInteraction: {
      type: String,
      validator: (val) => ['navigate', 'inspect', 'none'].includes(val),
      default: 'inspect'
    }
  }
}
</script>
