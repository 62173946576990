<template>
  <form @submit.prevent="save">
    <!-- Logistics -->
    <div class="has-background-white pa-lg rounded-lg mb-xxs">
      <!-- Ship From -->
      <div class="mb-md">
        <ShipFrom v-model="shipFrom" />
      </div>
      <div class="columns is-mobile">
        <div class="column">
          <!-- Service -->
          <ShippingAccount v-model="shippingAccount" :connectionId="order.connection_id"  />
        </div>
        <div class="column">
          <!-- Service -->
          <ShippingService
            v-model="shippingMethod"
            :account="shippingAccount"
            :requestedService="(order.shipping_method) ? order.shipping_method.generic_shipping_method : order.requested_shipping_method" />
        </div>
      </div>
    </div>
    <!-- Items -->
    <div class="has-background-white pa-lg rounded-lg mb-xxs">
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold">Items</p>
        </div>
        <div class="column">
          <p class="has-text-right"><a href="#" @click.prevent="updateFillAll">Fill All</a></p>
        </div>
      </div>
      <FulfillLine
        v-for="line in order.order_lines"
        :line="line"
        :key="line.id"
        :fillAll="fillAll[line.variant.id]"
        :isDirty="isDirty"
        @update:line="updateLine" />
    </div>
    <!-- Packages -->
    <div class="has-background-white pa-lg rounded-lg mb-xxs">
      <p class="has-text-weight-bold mb-md">Package & Weight</p>
      <!-- Package Dimensions -->
      <div class="mb-lg">
        <div class="columns">
          <!-- Package Template -->
          <div class="column">
            <p class="mb-sm">Package Template</p>
            <b-field
              :type="('weight' in errors) ? 'is-danger' : ''"
              :message="('weight' in errors) ? errors['weight'][0] : ''"
              ><b-select v-model="packageTemplate" placeholder="Select a Package" @input="setDimensions" expanded>
                <optgroup v-for="variant in eligibleCartons" :label="variant.sku" :key="variant.id">
                  <option v-for="carton in variant.cartons" :value="carton" :key="carton.id">
                    {{ carton.width }}{{ carton.width_unit.symbol }}
                    x {{ carton.height }}{{ carton.height_unit.symbol }}
                    x {{ carton.length }}{{ carton.length_unit.symbol }}
                  </option>
                </optgroup>
                <option value="custom">Custom</option>
              </b-select>
            </b-field>
          </div>
          <!-- Dimensions -->
          <div class="column">
            <p class="mb-sm">Dimensions (WxHxL)</p>
            <div class="columns is-vcentered is-mobile is-variable is-1">
              <div class="column">
                <b-field
                  label=""
                  :type="('width' in errors) ? 'is-danger' : ''"
                  :message="('width' in errors) ? errors['width'][0] : ''"
                  expanded>
                  <b-numberinput v-model="width" placeholder="W" :controls="false" :step="0.01"></b-numberinput>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label=""
                  :type="('height' in errors) ? 'is-danger' : ''"
                  :message="('height' in errors) ? errors['height'][0] : ''"
                  expanded>
                  <b-numberinput v-model="height" placeholder="H" :controls="false" :step="0.01"></b-numberinput>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label=""
                  :type="('length' in errors) ? 'is-danger' : ''"
                  :message="('length' in errors) ? errors['length'][0] : ''"
                  expanded>
                  <b-numberinput v-model="length" placeholder="L" :controls="false" :step="0.01"></b-numberinput>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Units -->
          <div class="column">
            <p class="mb-sm">Unit</p>
            <b-field
              :type="('lengthUnits' in errors) ? 'is-danger' : ''"
              :message="('lengthUnits' in errors) ? errors['lengthUnits'][0] : ''">
              <b-select v-model="lengthUnits" placeholder="Units" expanded>
                <option value="in">Inches</option>
                <option value="cm">Centimeters</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Weight -->
      <div>
        <p class="mb-sm">Total Weight (including package)</p>
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <b-field
              :type="('weight' in errors) ? 'is-danger' : ''"
              :message="('weight' in errors) ? errors['weight'][0] : ''"
              expanded>
                <b-numberinput v-model="weight"  step="0.01" expanded type="is-light"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field
              :type="('weight' in errors) ? 'is-danger' : ''"
              :message="('weight' in errors) ? errors['weight'][0] : ''"
              >
                <b-select v-model="weightUnits" placeholder="Units" expanded>
                  <option value="lbs">lbs</option>
                  <option value="lbs">g</option>
                  <option value="lbs">kg</option>
                </b-select>
            </b-field>
          </div>
        </div>
        <p v-if="estimatedWeight !== null" class="mb-sm">
          Esimated Weight:
          <a href="#" @click.prevent="setWeight" class="has-text-info">
            {{ estimatedWeight.weight }} {{ estimatedWeight.unit.standard_unit }}
          </a>
        </p>
      </div>
    </div>
    <!-- Buttons -->
    <div class="has-background-white py-md px-lg rounded-lg mb-xxs">
      <p v-if="isDirty" :class="validation.messageClass" class="mb-md">{{ validation.message }}</p>
      <div class="columns">
        <div class="column is-narrow">
          <b-button
            type="is-primary"
            native-type="submit"
            size="is-medium"
            :disabled="!validation.ready || saving || orderRefreshing"
            expanded
          ><span class="px-md">{{ submitButtonLabel }}</span></b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import FulfillLine from './FulfillLine'
import ShipFrom from './ShipFrom'
import ShippingAccount from './ShippingAccount'
import ShippingService from './ShippingService'

export default {
  components: {
    FulfillLine,
    ShipFrom,
    ShippingAccount,
    ShippingService
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shipments/?skip_attributes=1'
    },
    eligibleCartons () {
      const cartons = []
      this.order.order_lines.forEach(line => {
        if (line.variant.cartons.length > 0) {
          const eligibleCartons = []
          line.variant.cartons.forEach(carton => {
            if (!isNaN(parseFloat(carton.height)) && !isNaN(parseFloat(carton.width)) && !isNaN(parseFloat(carton.length)) &&
              carton.width_unit && carton.height_unit && carton.length_unit) {
              eligibleCartons.push(carton)
            }
          })
          if (eligibleCartons.length > 0) {
            cartons.push({
              id: line.variant.id,
              sku: line.variant.brand_identifier,
              cartons: eligibleCartons
            })
          }
        }
      })
      return cartons
    },
    estimatedWeight () {
      let weight = 0
      let unit = null
      for (const variant in this.lines) {
        weight += this.lines[variant].estimatedWeight.weight
        unit = this.lines[variant].estimatedWeight.unit
      }
      if (weight > 0 && unit !== null) {
        return {
          weight: weight,
          unit: unit
        }
      }
      return null
    },
    validation () {
      // first, make sure we have all the carrier details
      if (!this.shipFrom ||
        !this.shippingAccount ||
        !this.shippingMethod
      ) {
        return {
          ready: false,
          message: 'Please make sure to select your carrier details',
          messageClass: 'has-text-danger'
        }
      }
      // next check that we have all the form fields for units
      if (!this.weight ||
        !this.height ||
        !this.length ||
        !this.weight ||
        !this.weightUnits ||
        !this.lengthUnits
      ) {
        return {
          ready: false,
          message: 'Please make sure to add weight & dimensions.',
          messageClass: 'has-text-danger'
        }
      }
      // next, check that we have at least one time
      if (Object.keys(this.lines).length === 0) {
        return {
          ready: false,
          message: 'Please make sure you add at least 1 item to your shipment.',
          messageClass: 'has-text-danger'
        }
      }
      return {
        ready: true,
        message: 'You are now ready to create your label',
        messageClass: 'has-text-success'
      }
    },
    json () {
      if (this.validation.ready === false) return null
      const json = {
        order_id: this.order.id,
        label_format: 'PDF',
        ship_from: { location: { id: this.shipFrom.id } },
        tracking_number: this.trackingNumber,
        shipping_account_id: this.shippingAccount.id,
        shipping_method_id: this.shippingMethod.id,
        ship_width: this.width,
        ship_height: this.height,
        ship_length: this.length,
        ship_weight: this.weight,
        batch_number: null,
        label_reference: null,
        skip_attributes: 1,
        shipment_lines: []
      }
      for (const id in this.lines) {
        const shipment = {
          variant: { id: id },
          quantity: this.lines[id].quantity,
          part: 1
        }
        json.shipment_lines.push(shipment)
      }
      return json
    },
    submitButtonLabel () {
      if (this.orderRefreshing) return 'Order Refreshing...'
      if (this.saving) return 'Saving...'
      return 'Create Label'
    }
  },
  data () {
    return {
      shipFrom: null,
      shippingAccount: null,
      shippingMethod: null,
      fillAll: {},
      packageTemplate: null,
      width: null,
      height: null,
      length: null,
      weight: null,
      lengthUnits: 'in',
      weightUnits: 'lbs',
      lines: {},
      // error handling
      isDirty: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tracking number registered!' })
        this.$emit('fulfillment:updated')
        this.reset()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Tracking number not registered!' })
      }).finally(() => {
        this.saving = false
      })
    },
    updateLine (payload) {
      if (this.fillAll[payload.variantId]) {
        this.$set(this.fillAll, payload.variantId, false)
      }
      this.isDirty = true
      if (payload.quantity > 0) {
        const line = {
          variant: {
            id: payload.variantId
          },
          quantity: payload.quantity,
          estimatedWeight: payload.estimatedWeight
        }
        this.$set(this.lines, payload.variantId, line)
      } else {
        this.$delete(this.lines, payload.variantId)
      }
    },
    setDimensions () {
      if (this.packageTemplate === 'custom') return
      this.width = parseFloat(this.packageTemplate.width)
      this.height = parseFloat(this.packageTemplate.height)
      this.length = parseFloat(this.packageTemplate.length)
      this.lengthUnits = this.packageTemplate.width_unit.standard_unit
    },
    setWeight () {
      if (this.estimatedWeight.weight <= 0 || this.estimatedWeight.unit === null) return
      this.weight = parseFloat(this.estimatedWeight.weight)
      this.weightUnits = this.estimatedWeight.unit.standard_unit
    },
    updateFillAll () {
      Object.keys(this.fillAll).forEach(key => {
        if (!this.fillAll[key]) {
          this.fillAll[key] = true
        }
      })
    },
    reset () {
      this.packageTemplate = null
      this.isDirty = false
      this.width = null
      this.height = null
      this.length = null
      this.weight = null
      this.lines = {}
    }
  },
  mounted () {
    this.order.order_lines.forEach(line => {
      this.$set(this.fillAll, line.variant.id, false)
    })
  },
  props: ['order', 'orderRefreshing', 'warehouses']
}
</script>
