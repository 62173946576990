<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Browse by Item" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Items</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <item-filters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-dropdown expanded position="is-bottom-left">
              <button class="button is-medium is-fullwidth" slot="trigger">
                <b-icon icon="download"></b-icon>
                <span>Export</span>
              </button>
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="showExportItemsModal = true">Export Items &amp; Attributes</a>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="showExportInventoryModal = true">Export Current Inventory</a>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showDiscontinueModal = true">Discontinue</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} items found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- Supplier -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                cell-class="nowrap"
                width="194"
                v-slot="props">
                {{ props.row.brand.name }}
              </b-table-column>
              <!-- RC ID (since RMA Number not always required) -->
              <b-table-column
                label="Item"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <Identifiers
                  :variant="props.row"
                  :showImage="context.isSupplier() ? true : false"
                  nameInteraction="navigate"
                  imageInteraction="none"
                />
              </b-table-column>
              <!-- Retailer Category -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Category"
                cell-class="nowrap"
                width="194"
                v-slot="props">
                {{ getCategories(props.row) }}
              </b-table-column>
              <!-- UPC -->
              <b-table-column
                label="UPC"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <span v-if="props.row.upc">{{ props.row.upc }}</span>
                <span v-else class="has-text-grey-light is-italic">None</span>
              </b-table-column>
              <!-- Parent -->
              <b-table-column
                label="Product SKU"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <span v-if="props.row.parent_identifier">{{ props.row.parent_identifier }}</span>
                <span v-else class="has-text-grey-light is-italic">None</span>
              </b-table-column>
              <!-- Availability -->
              <b-table-column
                label="Availability"
                header-class="nowrap is-hidden-mobile"
                cell-class="nowrap is-hidden-mobile"
                centered
                width="200"
                v-slot="props">
                <a href="#" @click.prevent="inspectVariant(props.row)">
                  <div v-if="props.row.inventory.discontinued">
                    <p class="has-text-weight-bold has-text-centered">
                      <span class="has-text-danger">Discontinued</span>
                    </p>
                    <p class="has-text-grey is-size-8">{{ $dates.utc(props.row.discontinued_updated_at).local().format('MMM D, YYYY') }}</p>
                  </div>
                  <div v-else-if="props.row.inventory.inventory === 0 && props.row.inventory.estimated_availability_date === null">
                    <p class="has-text-weight-bold has-text-centered">
                      <span class="has-text-danger" @click.prevent="inspectVariant(props.row)">0</span>
                    </p>
                    <p class="has-text-grey is-size-8">Unknown Return</p>
                  </div>
                  <div v-else-if="props.row.inventory.inventory === 0 && props.row.inventory.estimated_availability_date !== null">
                    <p class="has-text-weight-bold has-text-centered">
                      <span class="has-text-danger" @click.prevent="inspectVariant(props.row)">0</span>
                    </p>
                    <p class="has-text-grey is-size-8">Returns {{ $dates(props.row.inventory.estimated_availability_date).format('MMM D, YYYY') }}</p>
                  </div>
                  <div v-else-if="props.row.inventory.inventory > 0">
                    <p class="has-text-weight-bold">
                      <span v-if="props.row.inventory.inventory === 9999" class="has-text-grey is-italic">Not Managed</span>
                      <span v-else class="has-text-weight-bold has-text-info" @click.prevent="inspectVariant(props.row)">
                        {{ props.row.inventory.inventory }}
                      </span>
                    </p>
                    <p class="has-text-grey is-size-8">Available Now</p>
                  </div>
                </a>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No items found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Inspect Exception -->
      <b-modal
        v-if="(inspectItem !== null)"
        has-modal-card
        :active="(inspectItem !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><ItemModal :selectedItem="inspectItem" />
      </b-modal>
      <!-- Modal: Export Variants -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportItemsModal"
        :can-cancel="['x']"
        ><ExportVariantsModal :filters="filters" @export:scheduled="showExportItemsModal = false" />
      </b-modal>
      <!-- Modal: Export Inventory -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportInventoryModal"
        :can-cancel="['x']"
        ><ExportInventoryModal :filters="filters" @export:scheduled="showExportInventoryModal = false" />
      </b-modal>
      <!-- Modal: Inventory Details -->
      <b-modal
        :active="selectedVariant !== null"
        has-modal-card
        :can-cancel="['escape', 'x']"
        @close="selectedVariant = null" >
        <InventoryModal :selectedVariant="selectedVariant" />
      </b-modal>
      <b-modal
        v-if="context.isSupplier() && !this.saving"
        has-modal-card
        scroll="keep"
        :active.sync="showDiscontinueModal"
        :can-cancel="['x']"
        ><DiscontinuedModal :products="selected" @reload="reload"/>
        </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import ItemModal from '@/components/Modals/Variant/VariantModal'
import ItemFilters from '../components/Items/ItemFilters'
import Identifiers from '@/components/Models/Variant/Identifiers'
import ExportVariantsModal from '../components/Items/ExportVariantsModal'
import ExportInventoryModal from '../components/Items/ExportInventoryModal'
import InventoryModal from '@/components/Modals/Variant/InventoryModal'
import DiscontinuedModal from '../components/Items/DiscontinuedModal'

export default {
  components: {
    Identifiers,
    ItemFilters,
    ItemModal,
    ExportVariantsModal,
    ExportInventoryModal,
    InventoryModal,
    DiscontinuedModal
  },
  data () {
    return {
      key: 'items',
      perPage: 25,
      // modals
      showExportItemsModal: false,
      showExportInventoryModal: false,
      inspectItem: null,
      selectedVariant: null,
      showDiscontinueModal: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/variants/'
    },
    fields () {
      const base = [
        'id',
        'name',
        'brand_identifier',
        'identifier',
        'parent_identifier',
        'parent_name',
        'upc',
        'brand',
        'product_id',
        'inventory'
      ]
      if (this.context.isSupplier()) {
        base.push('media')
      }
      if (this.context.isMerchant()) {
        base.push('retailer_identifiers')
        base.push('retailer_categories')
      }
      return base.join(',')
    },
    alwaysAttach () {
      return {
        fields: this.fields
      }
    }
  },
  methods: {
    close () {
      this.DiscontinuedModal = false
    },
    closeModals () {
      this.inspectItem = null
    },
    reload () {
      this.selected = []
      this.load()
    },
    inspectVariant (variant) {
      this.selectedVariant = { variant }
    },
    getCategories (row) {
      const values = []
      if (row.retailer_categories.length) {
        row.retailer_categories.map(el => values.push(el.category))
      }
      const categories = values.length ? values.join('/') : '-'
      return categories
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
