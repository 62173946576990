<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Shopify Settings</h1>
            </div>
          </div>
        </div>
        <!-- Info -->
        <div v-if="!loading && account !== null" class="border-b mb-xl pb-xl">
          <StoreDetails :account="account" />
        </div>
        <!-- Receive Orders (Merchants) -->
        <div v-if="!loading && account !== null && context.isMerchant()" class="border-b mb-xl pb-xl">
          <ReceiveOrders :account="account" />
        </div>
         <!-- Skip Fraud Order (Merchants) -->
         <div v-if="!loading && account !== null && context.isMerchant()" class="border-b mb-xl pb-xl">
          <SkipFraudOrders :account="account" @update:account="load(true)" />
        </div>
        <!-- Location Id (Merchants) -->
        <div v-if="!loading && account !== null && context.isMerchant()" class="border-b mb-xl pb-xl">
          <Location :account="account" />
        </div>
        <!-- Push Orders (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <PushOrders :account="account" @update:account="load(true)" />
        </div>
        <!-- Push Fulfillments (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <PushFulfillments :account="account" />
        </div>
        <!-- Cancellations (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <Cancellations :account="account" />
        </div>
        <!-- Partial Cancellations (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <PartialCancellations :account="account" />
        </div>
        <!-- Inventory Webhook (Suppliers & Merchants) -->
        <div v-if="!loading && account !== null" :class="context.isSupplier() ? 'border-b mb-xl pb-xl' : ''">
          <Inventory :account="account" />
        </div>
        <!-- Location Mapping (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <LocationMapping :account="account" @update:account="load(true)"  />
        </div>
        <!-- Carrier Mapping (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()">
          <CarrierMapping :account="account" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Inventory from '../components/Shopify/Inventory'
import ReceiveOrders from '../components/Shopify/ReceiveOrders'
import SkipFraudOrders from '../components/Shopify/SkipFraudOrders'
import PushOrders from '../components/Shopify/PushOrders'
import PushFulfillments from '../components/Shopify/PushFulfillments'
import Location from '../components/Shopify/Location'
import Cancellations from '../components/Shopify/Cancellations'
import StoreDetails from '../components/Shopify/StoreDetails'
import PartialCancellations from '../components/Shopify/PartialCancellations'
import CarrierMapping from '../components/Shopify/CarrierMapping'
import LocationMapping from '../components/Shopify/LocationMapping'
export default {
  components: {
    Inventory,
    ReceiveOrders,
    PushFulfillments,
    PushOrders,
    Location,
    Cancellations,
    PartialCancellations,
    SkipFraudOrders,
    StoreDetails,
    LocationMapping,
    CarrierMapping
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Shopify',
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Integrations', name: 'settings.integrations' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
