<template>
<form @submit.prevent="submitRequest">
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Support Request</p>
    </header>
    <section class="modal-card-body">
      <!-- Subject -->
      <div class="mb-lg">
        <b-field
          label="Subject"
          :type="('subject' in errors) ? 'is-danger' : ''"
          :message="('subject' in errors) ? errors['subject'][0] : ''">
          <b-input
            type="text"
            v-model="subject"
            size="is-medium"
          ></b-input>
        </b-field>
      </div>
      <!-- Emails -->
      <div class="mb-lg">
        <div class="mb-md">
          <label class="label">CC Additional Email Addresses</label>
          <p class="is-size-7">
            If you would like to CC additional email addresses on this request, please add them below
            (separated by a space).
          </p>
        </div>
        <b-field
          :type="('subject' in errors) ? 'is-danger' : ''"
          :message="('subject' in errors) ? errors['subject'][0] : ''">
          <b-input
            placeholder="Separate emails with a space"
            type="text"
            v-model="emails"
            size="is-medium"
          ></b-input>
        </b-field>
      </div>
      <!-- Customer Type -->
      <div v-if="context.isMerchant()" class="mb-lg">
        <div class="mb-md">
          <label class="label">This issue affects...</label>
        </div>
        <b-field>
          <b-radio v-model="customer" native-value="Retailer">My merchant account</b-radio>
          <b-radio v-model="customer" native-value="Supplier">One of my suppliers</b-radio>
        </b-field>
      </div>
      <!-- Issue Type -->
      <div class="columns">
        <div class="column">
          <b-field label="Issue Type">
            <b-select
              v-model="issue_type"
              placeholder="Select Issue Type..."
              expanded
              size="is-medium">
              <option v-for="issue_type in issue_type_list" :value="issue_type.code" :key="issue_type.code">{{ issue_type.label }}</option>
            </b-select>
          </b-field>
        </div>
        <!-- Priority -->
        <div class="column">
          <b-field label="Priority">
            <b-select v-model="priority" expanded size="is-medium">
              <option value="low">Low</option>
              <option value="normal">Normal</option>
              <option value="high">High</option>
              <option value="urgent">Urgent</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <!-- Message -->
      <div class="mb-lg">
        <b-field
          label="Message"
          :type="('subject' in errors) ? 'is-danger' : ''"
          :message="('subject' in errors) ? errors['subject'][0] : ''">
          <b-input
            type="textarea"
            v-model="message"
          ></b-input>
        </b-field>
      </div>
      <!-- Attachments -->
      <div class="mb-lg">
        <p class="has-text-weight-bold has-text-grey-darker mb-sm">Attachments</p>
        <div v-if="attachments.length">
          <div v-for="(attachment, index) in attachments" :key="index" >
            <div class="has-background-light px-md py-sm rounded-lg mb-xxs">{{ attachment.filename }}</div>
          </div>
        </div>
        <div v-else>
          <p class="is-size-7 has-text-grey">If you think a screenshot or file might help us resolve your issue faster, upload the file below.</p>
        </div>
      </div>
      <!-- Upload Attachment -->
      <div class="mb-lg">
        <CoreUpload @file:uploaded="addFileToTicket" @file:reset="resetFile" :fileType="defaultTypes" :showLabel='true' />
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="saving"
        :disabled="!ready || saving">
        Submit Request
      </b-button>
    </footer>
  </div>
</form>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    ready () {
      const isReady = this.subject && this.message && this.issue_type && this.priority
      if (this.context.isMerchant()) {
        return (isReady && this.customer)
      } else {
        return isReady
      }
    },
    postScript () {
      let context = 'Context: null'
      let user = 'User: null'
      if (this.context && this.context.partner) {
        context = this.context.label + ': ' + this.context.partner.name + ' (' + this.context.partner.id + ')'
      }
      if (this.auth && this.auth.user) {
        user = 'User: ' + this.auth.user.fullName
      }
      const route = 'Route: ' + this.app.url + this.$route.fullPath
      const customer = 'Customer: ' + this.customer
      return '\n\n' + context + '\n' + user + '\n' + route + '\n' + customer
    },
    json () {
      const json = {
        group_id: 6046167562385,
        subject: this.subject,
        priority: this.priority,
        issue_type: this.issue_type,
        customer_type: this.customer,
        body: this.message + this.postScript,
        attachments: []
      }
      if (this.emails && this.emails !== null) {
        json.collaborators = this.emails.split(' ')
      }
      this.attachments.forEach(tempfile => {
        json.attachments.push({
          tempfile_id: tempfile.id
        })
      })
      return json
    }
  },
  data () {
    return {
      subject: null,
      priority: 'low',
      customer: 'Retailer',
      issue_type: null,
      message: null,
      saving: false,
      attachments: [],
      errors: {},
      defaultTypes: '.xls,.xlsx,.csv,.txt,.pdf,.jpg,.jpeg,.png,.zip,.heic',
      emails: null,
      issue_type_list: [
        { label: 'Product Training', code: 'product_training' },
        { label: 'Merchandising', code: 'merchandising' },
        { label: 'Upload Failure', code: 'upload_failure' },
        { label: 'Orders Help', code: 'order_help' },
        { label: 'Order Managament', code: 'order_management' },
        { label: 'Shopify', code: 'shopify_help' },
        { label: 'Shipstation', code: 'shipstation_help' },
        { label: 'Account Help', code: 'account_help' },
        { label: 'EDI Integration', code: 'ediintegration_help' },
        { label: 'API Integration', code: 'apiintegration_help' },
        { label: 'Reporting', code: 'reporting_help' },
        { label: 'Data Issue', code: 'data_issue' },
        { label: 'Inventory Issue', code: 'inventory_issue' },
        { label: 'Proposal Help', code: 'proposal_help' }
      ]
    }
  },
  methods: {
    submitRequest () {
      this.saving = true
      const endpoint = this.context.route + '/zendesk/create-ticket/'
      this.$http.post(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Request submitted!' })
        this.$parent.close()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem submitting your request. Please check your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    addFileToTicket (file, response) {
      this.attachments.push(response)
    },
    resetFile () {
      this.attachments = []
    }
  }
}
</script>
