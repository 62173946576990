export default {
  draft: {
    label: 'Draft',
    tooltip: 'Proposals that are still in a draft state.',
    filters () {
      return {
        preset: 'draft',
        search: {},
        facets: {
          status: [{
            label: 'Draft',
            value: 'draft'
          }]
        },
        timestamps: {}
      }
    }
  },
  proposed: {
    label: 'Proposed',
    tooltip: 'Proposals that have been received',
    filters () {
      return {
        preset: 'proposed',
        search: {},
        facets: {
          status: [{
            label: 'Proposed',
            value: 'proposed'
          }]
        },
        timestamps: {}
      }
    }
  },
  revised: {
    label: 'Revised',
    tooltip: 'Proposals that have been revised by either party.',
    filters () {
      return {
        preset: 'revised',
        search: {},
        facets: {
          status: [{
            label: 'Revised',
            value: 'revised'
          }]
        },
        timestamps: {}
      }
    }
  },
  approvedSupplier: {
    label: 'Approved',
    tooltip: 'Proposals that have been approved',
    filters () {
      return {
        preset: 'approved_supplier',
        search: {},
        facets: {
          status: [{
            label: 'Approved',
            value: 'approved'
          }, {
            label: 'Pricing Approved',
            value: 'pricing_approved'
          }]
        },
        timestamps: {}
      }
    }
  },
  approved: {
    label: 'Approved',
    tooltip: 'Proposals that have been approved',
    filters () {
      return {
        preset: 'approved',
        search: {},
        facets: {
          status: [{
            label: 'Approved',
            value: 'approved'
          }]
        },
        timestamps: {}
      }
    }
  },
  pricing_approved: {
    label: 'Pricing Approved',
    tooltip: 'Proposals that have been pricing approved',
    filters () {
      return {
        preset: 'pricing_approved',
        search: {},
        facets: {
          status: [{
            label: 'Pricing Approved',
            value: 'pricing_approved'
          }]
        },
        timestamps: {}
      }
    }
  },
  open: {
    label: 'Open',
    tooltip: 'Proposals that are open',
    filters () {
      return {
        preset: 'open',
        search: {},
        facets: {
          status: [{
            label: 'Draft',
            value: 'draft'
          }, {
            label: 'Proposed',
            value: 'proposed'
          }, {
            label: 'Revised',
            value: 'revised'
          }, {
            label: 'Approved',
            value: 'approved'
          }, {
            label: 'Pricing Approved',
            value: 'pricing_approved'
          }]
        },
        timestamps: {}
      }
    }
  },
  closed: {
    label: 'Closed',
    tooltip: 'Proposals that are closed',
    filters () {
      return {
        preset: 'closed',
        search: {},
        facets: {
          status: [{
            label: 'Completed',
            value: 'completed'
          }, {
            label: 'Declined',
            value: 'declined'
          }]
        },
        timestamps: {}
      }
    }
  },
  ready: {
    label: 'Ready',
    tooltip: 'Proposals that are ready to be published.',
    filters () {
      return {
        preset: 'ready',
        search: {},
        facets: {
          status: [{
            label: 'Completed',
            value: 'completed'
          }],
          is_acknowledged: [{
            label: 'No',
            value: '0'
          }]
        },
        timestamps: {}
      }
    }
  },
  completed: {
    label: 'Completed',
    tooltip: 'Proposals that have been completed.',
    filters () {
      return {
        preset: 'completed',
        search: {},
        facets: {
          status: [{
            label: 'Completed',
            value: 'completed'
          }],
          is_acknowledged: [{
            label: 'Yes',
            value: '1'
          }]
        },
        timestamps: {}
      }
    }
  },
  completedSupplier: {
    label: 'Completed',
    tooltip: 'Proposals that have been completed.',
    filters () {
      return {
        preset: 'completed',
        search: {},
        facets: {
          status: [{
            label: 'Completed',
            value: 'completed'
          }]
        },
        timestamps: {}
      }
    }
  },
  declined: {
    label: 'Declined',
    tooltip: 'Proposals that have been declined.',
    filters () {
      return {
        preset: 'declined',
        search: {},
        facets: {
          status: [{
            label: 'Declined',
            value: 'declined'
          }]
        },
        timestamps: {}
      }
    }
  }
}
