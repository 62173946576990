<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="py-sm mb-md">
          <div class="columns is-vcentered">
            <div class="column">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
          </div>
        </div>
        <!-- Credentials -->
        <div class="pb-lg mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Client Credentials</p>
              <p class="has-text-grey is-size-7">API credentials required to access the {{ app.platform }} API.</p>
            </div>
            <div class="column">
              <div class="has-background-white rounded-xl">
                <!-- Loaded -->
                <div v-if="!loading && client !== null" class="pa-lg pb-xl">
                  <div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold">Client Name</p>
                      <p>{{ client.name }}</p>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold">API URL</p>
                      <p>{{ api.baseRoute }}/{{ context.route }}/</p>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold">{{ (context.isMerchant() ? 'Retailer' : 'Brand' ) }} ID</p>
                      <p>{{ context.partner.id }}</p>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold">Client ID</p>
                      <p>{{ client.client_id }}</p>
                    </div>
                    <div>
                      <p class="has-text-weight-bold">Client Secret</p>
                      <p v-if="!client.secret_retrieved_at">
                        <a href="#" @click.prevent="showSecretModal = true" class="has-text-info has-text-weight-bold">
                          Get Client Secret
                        </a>
                      </p>
                      <p v-else class="has-text-grey-light">
                        Client secret was retrieved on {{ $dates.utc(client.secret_retrieved_at).local().format('MMM D, YYYY')}}.
                        If you need access to this secret, please please file a
                          <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>
                          for assistance.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Loading -->
                <div v-else>
                  <loading message="Loading client..." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Secret Modal -->
    <b-modal
      :has-modal-card="true"
      :active.sync="showSecretModal"
      :can-cancel="['x']"
      ><ClientSecretModal :client="client" @secret:retrieved="load(true)"  />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientSecretModal from '@/components/Models/User/Modals/ClientSecret'

export default {
  components: {
    ClientSecretModal
  },
  computed: {
    ...mapGetters(['api', 'app', 'context']),
    breadcrumbs () {
      return {
        current: '#' + this.$route.params.id,
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'API Access', name: 'clients' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/users/' + this.$route.params.id + '/'
    }
  },
  data () {
    return {
      loading: true,
      client: null,
      showSecretModal: false
    }
  },
  methods: {
    load (silent) {
      if (!silent) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.client = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
