<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Packing Slip Copy</p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-input
            type="textarea"
            v-model="value"
            :readonly="readOnly"
            custom-class="is-tall-textarea"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :disabled="saving"
          :loading="saving"
        >Save Copy</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { br2nl } from '@/helpers/br2nl.js'
import nl2br from 'locutus/php/strings/nl2br'

export default {
  components: {

  },
  data () {
    return {
      saving: false,
      value: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    readOnly () {
      return this.context.isSupplier()
    },
    endpoint () {
      return this.context.route + '/connections/' + this.connectionId + '/'
    },
    json () {
      return {
        connection_credentials: [{
          credential: {
            code: 'packing_slip_bottom_text'
          },
          value: nl2br(this.value, false)
        }]
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Copy Updated!' })
        this.$parent.close()
        this.$emit('updated')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.value = br2nl(this.copy, true)
  },
  props: ['connectionId', 'copy']
}
</script>
