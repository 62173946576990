<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <!-- Has Requests -->
      <div v-if="!loading && requests.length">
        <p class="mb-md">
          {{ connection.retailer.name }} has submitted the following product import requests for your
          review. If you approve of the content, please approve the request and your products
          will be automatically created.
        </p>
        <div class="mb-xxl">
          <b-table
            :data="requests"
            :mobile-cards="false"
            hoverable
            class="is-size-7">
            <template>
              <b-table-column
                label="ID"
                header-class="nowrap"
                cell-class="nowrap has-text-grey-light"
                width="64"
                v-slot="props">
                {{ props.row.id }}
              </b-table-column>
              <!-- Merchant (Suppliers Only) -->
              <b-table-column
                label="File"
                v-slot="props">
                <p>{{ props.row.feed_file.filename }}</p>
                <p><a :href="props.row.feed_file.file" :download="props.row.feed_file.filename" class="has-text-info has-text-weight-bold is-size-7">Download File</a></p>
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                cell-class="has-text-weight-bold"
                centered
                width="200"
                v-slot="props">
                <span v-if="props.row.status === 'approved'" class="has-text-success">Approved</span>
                <span v-else-if="props.row.status === 'rejected'" class="has-text-grey-light">Declined</span>
                <span v-else>Pending</span>
              </b-table-column>
              <!-- Merchant (Suppliers Only) -->
              <b-table-column v-slot="props" width="96">
                <ApproveButton :request="props.row" size="is-small" @request:saved="loadImportRequests"/>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
      <!-- Has No Requests -->
      <div v-else-if="!loading && requests.length === 0">
        <p class="mb-md">
          {{ connection.retailer.name }} will soon submit a request
          to upload a set products on your behalf. Once submitted, this "import request"
          requires your approval.
        </p>
        <p class="mb-md">
          When {{ connection.retailer.name }} submits the import request, you will receive an
          email with instructions (and a link) to view the products and approve the request.
        </p>
        <p class="mb-md">
          You don't have to take any action at this time, so you may choose
          'Complete Task' below to continue onboarding.
        </p>
      </div>
      <!-- Loading -->
      <div v-else>
        <p class="has-text-centered has-text-grey-light py-md">Loading...</p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApproveButton from '@/components/Models/ImportRequest/Approve'

export default {
  components: {
    ApproveButton
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return this.loadingImportRequests
    },
    ready () {
      if (this.loadingImportRequests) return false
      if (this.requests.length > 0) {
        this.requests.forEach(request => {
          if (request.status === 'pending') {
            return false
          }
        })
      }
      return true
    }
  },
  data () {
    return {
      loadingImportRequests: true,
      requests: [],
      saving: false
    }
  },
  methods: {
    loadImportRequests () {
      this.loadingImportRequests = true
      const endpoint = this.context.route + '/feed-requests/'
      const query = {
        params: {
          orderBy: '-id',
          status: 'approved,pending',
          limit: 5
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.requests = response.data.results
        this.loadingImportRequests = false
      })
    },
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please try again!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadImportRequests()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
