<template>
  <div>
    <!-- Permit/Connection Selector -->
    <!-- <div class="has-background-white pa-lg rounded-lg mb-xxs">

    </div> -->
    <div class="has-background-white pa-lg rounded-lg">
      <!-- Description Attributes -->
      <div v-if="descriptionAttributes.length > 0" class="mb-lg">
        <div class="has-border-b has-border-lg pb-sm">
          <p class="has-text-weight-bold">Marketing Attributes</p>
        </div>
        <table class="table is-size-7 is-fullwidth is-narrow is-striped">
          <tbody>
            <tr v-for="attribute in descriptionAttributes" :key="attribute.id">
              <th style="width:25%">{{ attribute.name }}</th>
              <td class="has-text-right">
                {{ attribute.values[0].value }}
                <span v-if="attribute.values[0].unit !== null">
                  {{ attribute.values[0].unit.standard_unit }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pricing Attributes -->
      <div v-if="pricingAttributes.length > 0" class="mb-lg">
        <div class="has-border-b has-border-lg pb-sm">
          <p class="has-text-weight-bold">Pricing Attributes</p>
        </div>
        <table class="table is-size-7 is-fullwidth is-narrow is-striped">
          <tbody>
            <tr v-for="attribute in pricingAttributes" :key="attribute.id">
              <th style="width:25%">{{ attribute.name }}</th>
              <td class="has-text-right">
                <CurrencyAttribute :value="attribute.values[0]" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Image Attributes -->
      <div v-if="imageAttributes.length > 0" class="mb-lg">
        <div class="has-border-b has-border-lg pb-sm">
          <p class="has-text-weight-bold">Image Attributes</p>
          <p class="is-size-7 has-text-grey-light">
            When suppliers upload image URL attributes, the media files are copied and
            stored on the {{ app.platform }} CDN. The URLs below remain, but might
            not always resolve.
          </p>
        </div>
        <table class="table is-size-7 is-fullwidth is-narrow is-striped">
          <tbody>
            <tr v-for="attribute in imageAttributes" :key="attribute.id">
              <th style="width:25%">{{ attribute.name }}</th>
              <td class="has-text-right">
                <a v-if="attribute.values.length > 0" :href="attribute.values[0].value" target="_blank">
                  External URL <b-icon icon="open-in-new" size="is-small" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Shipping Attributes -->
      <div v-if="shippingAttributes.length > 0" class="mb-lg">
        <div class="has-border-b has-border-lg pb-sm">
          <p class="has-text-weight-bold">Shipping Attributes</p>
        </div>
        <table class="table is-size-7 is-fullwidth is-narrow is-striped">
          <tbody>
            <tr v-for="attribute in shippingAttributes" :key="attribute.id">
              <th style="width:25%">{{ attribute.name }}</th>
              <td class="has-text-right">
                {{ attribute.values[0].value }}
                <span v-if="attribute.values[0].unit !== null">
                  {{ attribute.values[0].unit.standard_unit }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Misc Attributes -->
      <div v-if="miscAttributes.length > 0" class="mb-lg">
        <div class="has-border-b has-border-lg pb-sm">
          <p class="has-text-weight-bold">Miscellanous Attributes</p>
          <p class="is-size-7 has-text-grey-light">Description</p>
        </div>
        <table class="table is-size-7 is-fullwidth is-narrow is-striped">
          <tbody>
            <tr v-for="attribute in miscAttributes" :key="attribute.id">
              <th style="width:25%">
                {{ attribute.name }}
                <strong v-if="attribute.values.length > 1" class="has-text-danger">*</strong>
              </th>
              <td class="has-text-right">
                {{ attribute.values[0].value }}
                <span v-if="attribute.values[0].unit !== null">
                  {{ attribute.values[0].unit.standard_unit }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="platformAccountIdentifiers.length > 0" class="mb-lg">
        <div class="has-border-b has-border-lg pb-sm">
          <p class="has-text-weight-bold">Platform Account Idenitfiers</p>
          <p class="is-size-7 has-text-grey-light">Platform Account ID</p>
        </div>
        <table class="table is-size-7 is-fullwidth is-narrow is-striped">
          <tbody>
            <tr v-for="attribute in platformAccountIdentifiers" :key="attribute.id">
              <th style="width:25%">
                {{ attribute['platform_account_id'] }}
              </th>
              <td class="has-text-right">
                {{ attribute['identifier'] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    generalAttributes () {
      const values = []
      Object.keys(this.item.attributes).forEach(code => {
        if (this.item.attributes[code].grouping === 'general') {
          this.item.attributes[code].values = this.getAttributeValuesByContext(this.item.attributes[code].values)
          if (this.item.attributes[code].values.length > 0) {
            values.push(this.item.attributes[code])
          }
        }
      })
      // return this.$utils.orderBy(values, [function(attribute) { return attribute.code }]);
      return this.$utils.sortBy(values, [value => { return value.code }])
    },
    descriptionAttributes () {
      const values = []
      Object.keys(this.item.attributes).forEach(code => {
        if (this.item.attributes[code].grouping === 'description') {
          this.item.attributes[code].values = this.getAttributeValuesByContext(this.item.attributes[code].values)
          if (this.item.attributes[code].values.length > 0) {
            values.push(this.item.attributes[code])
          }
        }
      })
      // return this.$utils.orderBy(values, [function(attribute) { return attribute.code }]);
      return this.$utils.sortBy(values, [value => { return value.code }])
    },
    pricingAttributes () {
      const values = []
      Object.keys(this.item.attributes).forEach(code => {
        if (this.item.attributes[code].grouping === 'pricing') {
          this.item.attributes[code].values = this.getAttributeValuesByContext(this.item.attributes[code].values)
          if (this.item.attributes[code].values.length > 0) {
            values.push(this.item.attributes[code])
          }
        }
      })
      // return this.$utils.orderBy(values, [function(attribute) { return attribute.code }]);
      return this.$utils.sortBy(values, [value => { return value.code }])
    },
    imageAttributes () {
      const values = []
      Object.keys(this.item.attributes).forEach(code => {
        if (this.item.attributes[code].grouping === 'images') {
          this.item.attributes[code].values = this.getAttributeValuesByContext(this.item.attributes[code].values)
          if (this.item.attributes[code].values.length > 0) {
            values.push(this.item.attributes[code])
          }
        }
      })
      // return this.$utils.orderBy(values, [function(attribute) { return attribute.code }]);
      return this.$utils.sortBy(values, [value => { return value.code }])
    },
    shippingAttributes () {
      const values = []
      Object.keys(this.item.attributes).forEach(code => {
        if (this.item.attributes[code].grouping === 'shipping') {
          this.item.attributes[code].values = this.getAttributeValuesByContext(this.item.attributes[code].values)
          if (this.item.attributes[code].values.length > 0) {
            values.push(this.item.attributes[code])
          }
        }
      })
      // return this.$utils.orderBy(values, [function(attribute) { return attribute.code }]);
      return this.$utils.sortBy(values, [value => { return value.code }])
    },
    miscAttributes () {
      const values = []
      Object.keys(this.item.attributes).forEach(code => {
        if (this.item.attributes[code].grouping === 'misc') {
          this.item.attributes[code].values = this.getAttributeValuesByContext(this.item.attributes[code].values)
          if (this.item.attributes[code].values.length > 0) {
            values.push(this.item.attributes[code])
          }
        }
      })
      // return this.$utils.orderBy(values, [function(attribute) { return attribute.code }]);
      return this.$utils.sortBy(values, [value => { return value.code }])
    },
    platformAccountIdentifiers () {
      return this.item.platform_account_identifiers
    }
  },
  methods: {
    getAttributeValuesByContext(attributeValues) {
      if (this.context.isSupplier()) {
        // only return brand scoped attribute values when in brand context.
        return attributeValues.filter(attributeValue => !('retailer' in attributeValue))
      } else {
        // when in retailer context, return retailer scoped attribute values first, then brand scoped attribute values.
        return attributeValues.filter(attributeValue => (attributeValue.retailer !== undefined && attributeValue.retailer.id === this.context.partner.id) || attributeValue.retailer === undefined).sort(
          (a, b) => {
            if (a.retailer !== undefined && b.retailer === undefined) {
              return -1
            } else if (a.retailer === undefined && b.retailer !== undefined) {
              return 1
            } else {
              return 0
            }
          }
        )
      }
    }
  },
  mounted () {

  },
  props: ['item']
}
</script>
