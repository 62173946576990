<template>
  <div>
    <div>
      <!-- Workflow -->
      <div v-if="context.isMerchant()" class="has-background-white rounded-lg py-md px-lg mb-xxs">
        <div v-if="!loading && connection !== null">
          <Workflow
            :connection="connection"
            @edit:connection="showUpdateConnectionModal = true"
            @activate:connection="showActivateConnectionModal = true"
            @suspend:connection="showSuspendConnectionModal = true"
          />
        </div>
        <div v-else>
          <b-skeleton height="20" />
        </div>
      </div>
      <!-- Connection Info -->
      <div class="mb-lg">
        <div class="has-background-white rounded-lg pa-lg">
          <ConnectionInfo :connection="connection"
            @edit:connection="showUpdateConnectionModal = true"
            @edit:fees="showUpdateFeesModal = true"
            @edit:slas="showUpdateSlasModal = true"
            @edit:options="showUpdateOptionsModal = true"
            @show:connectionAccounts="showConnectionIntegrationAccountsModal = true"
            @updated="$emit('updated')"
          />
        </div>
      </div>
      <!-- Connection Memos -->
      <div class="mb-lg">
        <ConnectionMemos :connection="connection" />
      </div>
      <!-- Connection Adjustments -->
      <div class="mb-lg">
        <div class="has-text-centered mb-md">
          <p class="is-size-5">Invoice Adjustments</p>
          <p class="has-text-grey is-size-7">
            The following adjustments are allowed on invoices submitted by
            <span v-if="context.isSupplier()"> {{ context.partner.name }}</span>
            <span v-else>{{ connection.brand.name }}</span>.
          </p>
        </div>
        <div class="has-background-white rounded-lg pa-md">
          <Adjustments :connection="connection" />
        </div>
      </div>
    </div>
    <!-- Packing Slip Copy Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showPackingSlipCopyModal"
      :can-cancel="['escape', 'x']"
      ><PackingSlipCopyModal :copy="customPackingSlipCopy" :connectionId="connection.id" @updated="load(true)" />
    </b-modal>
    <!-- Update Connection Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showUpdateConnectionModal"
      :can-cancel="['escape', 'x']"
      ><UpdateConnectionModal :connection="connection" @saved:connection="$emit('updated')" />
    </b-modal>
    <!-- Update Connection SLAs Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showUpdateFeesModal"
      :can-cancel="['escape', 'x']"
      ><UpdateFeesModal :connection="connection" @saved:connection="$emit('updated')" />
    </b-modal>
    <!-- Update Connection SLAs Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showUpdateSlasModal"
      :can-cancel="['escape', 'x']"
      ><UpdateSlasModal :connection="connection" @saved:connection="$emit('updated')" />
    </b-modal>
    <!-- Activate Connection Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showActivateConnectionModal"
      :can-cancel="['escape', 'x']"
      ><ActivateModal :connection="connection" @activated:connection="$emit('updated')" />
    </b-modal>
    <!-- Suspend Connection Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showSuspendConnectionModal"
      :can-cancel="['escape', 'x']"
      ><SuspendModal :connection="connection" @suspended:connection="$emit('updated')" />
    </b-modal>
    <!-- Update Connection options Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showUpdateOptionsModal"
      :can-cancel="['escape', 'x']"
      ><UpdateOptions :connection="connection" @saved:connection="$emit('updated')" />
    </b-modal>
    <!-- Connection Integration Accounts Modal -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      :active.sync="showConnectionIntegrationAccountsModal"
      :can-cancel="['escape', 'x']"
      ><IntegrationAccountsModal :connection="connection" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Workflow from '../components/Summary/Workflow'
import ConnectionInfo from '../components/Summary/ConnectionInfo'
import ConnectionMemos from '../components/Summary/ConnectionMemos'
import Adjustments from '../components/Summary/Adjustments'
import PackingSlipCopyModal from '../components/Summary/PackingSlipCopyModal'
import UpdateConnectionModal from '@/components/Models/Connection/Modals/UpdateConnectionModal'
import UpdateFeesModal from '@/components/Models/Connection/Modals/UpdateFees'
import UpdateSlasModal from '@/components/Models/Connection/Modals/UpdateSlas'
import ActivateModal from '@/components/Models/Connection/Modals/Activate'
import SuspendModal from '@/components/Models/Connection/Modals/Suspend'
import UpdateOptions from '@/components/Models/Connection/Modals/UpdateOptions'
import IntegrationAccountsModal from '@/components/Models/Connection/Modals/ConnectionIntegrationAccountsModal'

export default {
  components: {
    Workflow,
    ConnectionInfo,
    ConnectionMemos,
    Adjustments,
    PackingSlipCopyModal,
    UpdateConnectionModal,
    UpdateFeesModal,
    UpdateSlasModal,
    ActivateModal,
    SuspendModal,
    UpdateOptions,
    IntegrationAccountsModal
  },
  computed: {
    ...mapGetters(['context']),
    canCustomizePackingSlipCopy () {
      return (
        this.connection !== null &&
        'packing_slip_template' in this.connection &&
        this.connection.packing_slip_template === 'generic_rcn_packing_slip.html'
      )
    },
    customPackingSlipCopy () {
      if (this.loading || this.connection === null) return null
      const credential = this.$utils.filter(this.connection.connection_credentials, { code: 'packing_slip_bottom_text' })
      return (credential.length > 0) ? credential[0].value : null
    }
  },
  data () {
    return {
      showActivateConnectionModal: false,
      showSuspendConnectionModal: false,
      showUpdateConnectionModal: false,
      showPackingSlipCopyModal: false,
      showUpdateShippingMethodsModal: false,
      showUpdateSlasModal: false,
      showUpdateFeesModal: false,
      showUpdateOptionsModal: false,
      showConnectionIntegrationAccountsModal: false
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
