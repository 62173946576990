<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Import History" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Import History</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <FeedQueueFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <template>
              <!-- ID -->
              <b-table-column
                label="ID"
                cell-class="nowrap"
                width="96"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectFeed = props.row"
                >{{ props.row.id }}</a>
              </b-table-column>
              <!-- Datatype -->
              <b-table-column
                label="Data"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                <p>
                  {{ $utils.upperFirst(props.row.direction) }}
                  {{ $utils.upperFirst(props.row.data_type) }}
                </p>
                <p class="has-text-grey is-italic is-size-8">{{ $utils.upperFirst(props.row.behavior) }}</p>
              </b-table-column>
              <!-- File -->
              <b-table-column
                label="File"
                cell-class="nowrap"
                v-slot="props">
                <p>
                  <DownloadFileAs :cloudFileName="props.row.cloud_filename" :originalFileName="props.row.original_filename"/>
                </p>
              </b-table-column>
              <!-- Created At -->
              <b-table-column
                label="Queued"
                width="96"
                centered
                v-slot="props">
                <p>{{ $dates.utc(props.row.created_at).local().format('MMM D') }}</p>
                <p class="is-size-8 has-text-grey">{{ $dates.utc(props.row.created_at).local().format('h:mm A') }}</p>
              </b-table-column>
              <!-- Processed At -->
              <b-table-column
                label="Processed"
                width="96"
                centered
                v-slot="props">
                <div v-if="props.row.processed_at">
                  <p>{{ $dates.utc(props.row.processed_at).local().format('MMM D') }}</p>
                  <p class="is-size-8 has-text-grey">{{ $dates.utc(props.row.processed_at).local().format('h:mm A') }}</p>
                </div>
                <div v-else>
                  <p class="is-size-8 has-text-grey">--</p>
                </div>
              </b-table-column>
              <!-- Completed At -->
              <b-table-column
                label="Completed"
                width="96"
                centered
                v-slot="props">
                <div v-if="props.row.completed_at">
                  <p>{{ $dates.utc(props.row.completed_at).local().format('MMM D') }}</p>
                  <p class="is-size-8 has-text-grey">{{ $dates.utc(props.row.completed_at).local().format('h:mm A') }}</p>
                </div>
                <div v-else>
                  <p class="is-size-8 has-text-grey">--</p>
                </div>
              </b-table-column>
              <!-- Run Time -->
              <b-table-column
                label="Duration"
                width="96"
                centered
                v-slot="props">
                <p class="is-size-8 has-text-grey">
                  <Runtime :start="props.row.processed_at" :end="props.row.completed_at" />
                </p>
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                cell-class="nowrap has-text-weight-bold"
                centered
                v-slot="props">
                <span v-if="props.row.status === 'success'" class="has-text-success">Success</span>
                <span v-else-if="props.row.status === 'failure'" class="has-text-danger">Failed</span>
                <span v-else-if="props.row.status === 'processing'" class="has-text-danger">Processing</span>
                <span v-else>Queued</span>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No records found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Inspection Modal -->
      <b-modal
        v-if="(inspectFeed !== null)"
        has-modal-card
        :active="(inspectFeed !== null)"
        :can-cancel="['escape', 'x']"
        @close="inspectFeed = null"
        ><FeedQueueModal :selectedFeed="inspectFeed" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Runtime from '@/components/Models/FeedQueue/Presenters/Runtime'
import Filterable from '@/mixins/Filterable'
import FeedQueueFilters from '../components/ImportHistory/FeedQueueFilters'
import FeedQueueModal from '@/components/Modals/FeedQueue/FeedQueueModal'

export default {
  components: {
    FeedQueueFilters,
    FeedQueueModal,
    Runtime
  },
  data () {
    return {
      key: 'feedqueue',
      inspectFeed: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/feed-queue/'
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
