<template>
<div>
  <div>
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered mb-md">Step 3 of 3: Review Compatibility</p>
      <div v-if="!loadingProposal && proposalDetail !== null">
        <p v-if="proposalDetail.proposal_type !== 'cost_update'" class="is-size-7">
          Before you can submit your proposal, you must review compatibility with the merchants data requirements.
        </p>
      </div>
    </div>
    <!-- Results -->
    <div v-if="!loadingProposal" class="mb-lg">
      <div class="columns is-multiline">
        <!-- Items Imported -->
        <div class="column is-half">
          <div class="has-background-light rounded-lg pa-sm has-text-centered">
            <p class="is-size-5 has-text-weight-bold">
              <span v-if="!loadingCompatibility" class="has-text-success">{{ proposalDetail.item_count }}</span>
              <span v-else class="has-text-grey-light">Loading...</span>
            </p>
            <p class="has-text-grey is-size-7 mb-xxs">Items Imported</p>
          </div>
        </div>
        <!-- Items Skipped -->
        <div class="column is-half">
          <div class="has-background-light rounded-lg pa-sm has-text-centered">
            <p v-if="!loadingCompatibility" class="is-size-5 has-text-weight-bold">
              <span v-if="proposalDetail.item_rejected_count > 0" class="has-text-warning">{{ proposalDetail.item_rejected_count }}</span>
              <span v-else class="has-text-success">{{ proposalDetail.item_rejected_count }}</span>
            </p>
            <p v-else class="is-size-5 has-text-weight-bold has-text-grey-light">Loading...</p>
            <b-tooltip
              dashed
              label="Items are skipped if they are already being reviewed by your partner."
              position="is-bottom">
              <p class="has-text-grey is-size-7">Items Skipped</p>
            </b-tooltip>
          </div>
        </div>
        <!-- SKUs Passing -->
        <div v-if="proposalDetail.proposal_type !== 'cost_update'" class="column is-one-third">
          <div class="has-background-light rounded-lg pa-sm has-text-centered">
            <p v-if="!loadingCompatibility" class="is-size-5 has-text-weight-bold">
              <span v-if="'passed' in vendorRuleset && vendorRuleset.passed > 0" class="has-text-success">{{ vendorRuleset.passed }}</span>
              <span v-else-if="'passed' in vendorRuleset && vendorRuleset.passed === 0" class="has-text-danger">0</span>
              <span v-else>--</span>
            </p>
            <p v-else class="is-size-5 has-text-weight-bold has-text-grey-light">Loading...</p>
            <p class="has-text-grey is-size-7">SKUs Passing</p>
          </div>
        </div>
        <!-- SKUs Processing -->
        <div v-if="proposalDetail.proposal_type !== 'cost_update'" class="column is-one-third">
          <div class="has-background-light rounded-lg pa-sm has-text-centered">
            <p v-if="!loadingCompatibility" class="is-size-5 has-text-weight-bold">
              <span v-if="'processing' in vendorRuleset && vendorRuleset.processing > 0" class="has-text-danger">{{ vendorRuleset.processing }}</span>
              <span v-else-if="'processing' in vendorRuleset && vendorRuleset.processing === 0" class="has-text-success">0</span>
              <span v-else>--</span>
            </p>
            <p v-else class="is-size-5 has-text-weight-bold has-text-grey-light">Loading...</p>
            <p class="has-text-grey is-size-7">SKUs Processing</p>
          </div>
        </div>
        <!-- SKUs Failed -->
        <div v-if="proposalDetail.proposal_type !== 'cost_update'" class="column is-one-third">
          <div class="has-background-light rounded-lg pa-sm has-text-centered">
            <p v-if="!loadingCompatibility" class="is-size-5 has-text-weight-bold">
              <span v-if="'failed' in vendorRuleset && vendorRuleset.failed > 0" class="has-text-danger">{{ vendorRuleset.failed }}</span>
              <span v-else-if="'failed' in vendorRuleset && vendorRuleset.failed === 0" class="has-text-success">0</span>
              <span v-else>--</span>
            </p>
            <p v-else class="is-size-5 has-text-weight-bold has-text-grey-light">Loading...</p>
            <p class="has-text-grey is-size-7">SKUs Failing</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Messaging -->
    <div v-if="!loading">
      <!-- Has No Items (Rare) -->
      <b-message v-if="proposalDetail.status === 'draft' && proposalDetail.item_count === proposalDetail.item_rejected_count" type="is-warning" class="is-size-7">
        <div class="has-text-centered">
          <p class="has-text-weight-bold">No Items</p>
          <p>
            We're sorry, but no items are able to be imported at this time. It's likely
            that these items are already under review. If you have any questions, please don't
            hesitate to create a
            <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>.
          </p>
        </div>
      </b-message>
      <!-- Ready to Send -->
      <b-message v-else-if="proposalDetail.status === 'draft' && isPassing" type="is-success" class="is-size-7">
        <div class="has-text-centered">
          <p class="has-text-weight-bold">Compatibility Passed!</p>
          <p>All items are passing attribute validation checks.</p>
          <p>Your proposal is ready to send to {{ proposalDetail.connection.retailer.name }} for approval.</p>
        </div>
      </b-message>
      <!-- Processing -->
      <b-message v-else-if="proposalDetail.status === 'draft' && isProcessing" type="is-info" class="is-size-7">
        <div class="has-text-centered">
          <p class="has-text-weight-bold">Compatibility Processing</p>
          <p>We're currently evaluating the compatibility of the products you submitted.</p>
          <p>This process can take a few minutes.</p>
          <p>Please refresh this page as necessary to refresh compatibility status.</p>
          <p><a href="#" @click.prevent="loadCompatibility(false)">Refresh Results</a></p>
        </div>
        <div v-if="timeElapsed > 15" class="has-text-centered mt-lg">
          <p v-if="!revaluatingCompatibility && retries === 0">
            Results stuck?
            <a href="#" @click.prevent="rerunCompatibility">Re-evalutate Compatibility</a>
          </p>
          <p v-else-if="!revaluatingCompatibility && retries >= 1">
            We've retrigged a rules evaluation. Please wait a few more minutes and refresh.
            If you're not seeing progress, please contact our support team at {{  app.supportEmail  }}
          </p>
          <p v-else>Re-running rules engine...</p>
        </div>
      </b-message>
      <!-- Failing -->
      <b-message v-else-if="proposalDetail.status === 'draft' && !isPassing" type="is-danger" class="is-size-7">
        <div class="mb-lg">
          <p class="has-text-weight-bold">Missing or invalid attributes need to be resolved before we can submit your proposal.</p>
          <p>Use the information below to update your product data, or let us help make the corrections for you.</p>
        </div>
        <div class="mb-lg">
          <FailingAttributes
            :proposal="proposalDetail"
            :ruleset="vendorRuleset"
            @inspect:rule="inspectRule"
          />
        </div>
        <div class="columns">
          <div class="column">
            <b-button type="is-danger" expanded outlined @click="showImportFeedModal = true">I want to fix it myself</b-button>
          </div>
          <div class="column">
            <b-button type="is-danger" expanded outlined @click="showImportHelpModal = true">Get help from {{ app.platform }}</b-button>
          </div>
        </div>
      </b-message>
      <!-- Already Sent -->
      <b-message v-else-if="proposalDetail.status === 'proposed'" type="is-success" class="is-size-7">
        <div class="has-text-centered">
          <p class="has-text-weight-bold">Awaiting Approval</p>
          <p>
            Your products have been sent to {{ proposalDetail.connection.retailer.name }} for review and approval. We'll
            notify you as soon as your submission is actioned. </p>
        </div>
      </b-message>
    </div>
    <!-- Navigate -->
    <div class="mt-xl">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="saving || !isReadyToSubmit"
            :loading="saving"
            @click="submitProposal"
          >Submit for Approval</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Inspect Variants -->
  <b-modal
    has-modal-card
    :active.sync="showVariantsModal"
    :can-cancel="['escape', 'x']"
    @close="rule = null"
    ><VariantsModal :proposal="proposal" :rule="rule" />
  </b-modal>
  <!-- Import Feed Modal -->
  <b-modal
    has-modal-card
    :active.sync="showImportFeedModal"
    :can-cancel="['escape', 'x']"
    ><ImportFeedModal :proposal="proposalDetail" @feed:imported="refresh" />
  </b-modal>
  <!-- Support Task Modal -->
  <b-modal
    has-modal-card
    :active.sync="showImportHelpModal"
    :can-cancel="['escape', 'x']"
    ><ImportHelpModal :proposal="proposal" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FailingAttributes from './FailingAttributes'
import ImportFeedModal from '@/components/Models/Proposal/Modals/ImportFeed'
import ImportHelpModal from '@/components/Models/Proposal/Modals/ImportHelp'
import VariantsModal from './VariantsModal'

export default {
  components: {
    FailingAttributes,
    ImportFeedModal,
    ImportHelpModal,
    VariantsModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    vendorRuleset () {
      if (!this.proposalDetail || this.rulesets.length === 0) return null
      const ruleset = this.rulesets.find(ruleset => {
        return ruleset.id === this.proposalDetail.department.brand_ruleset.id
      })
      return (typeof ruleset !== 'undefined') ? ruleset : null
    },
    isProcessing () {
      if (this.proposalDetail === null) return false
      // always pass cost updates
      if (this.proposalDetail.proposal_type === 'cost_update') return false
      // check all others...
      if (this.vendorRuleset) {
        return Boolean(this.vendorRuleset.processing > 0)
      }
      return false
    },
    isPassing () {
      if (this.proposalDetail === null) return false
      // always pass cost updates
      if (this.proposalDetail.proposal_type === 'cost_update') return true
      // check all others...
      if (this.vendorRuleset) {
        return Boolean(
          this.vendorRuleset.failed === 0 &&
          this.vendorRuleset.processing === 0 &&
          this.vendorRuleset.failed + this.vendorRuleset.passed === this.vendorRuleset.count
        )
      }
      return false
    },
    isReadyToSubmit () {
      if (this.proposalDetail === null) return false
      // cost updates
      if (
        this.proposalDetail.status === 'draft' &&
        this.proposalDetail.proposal_type === 'cost_update' &&
        this.proposalDetail.item_count > 0 &&
        this.proposalDetail.item_count - this.proposal.item_rejected_count !== 0
      ) {
        return true
      }
      // all others...
      return (
        this.proposalDetail.status === 'draft' &&
        this.proposalDetail.item_count > 0 &&
        this.proposalDetail.item_failed_count === 0 &&
        this.proposalDetail.item_count - this.proposal.item_rejected_count !== 0 &&
        this.isPassing
      )
    },
    loading () {
      return (this.loadingProposal)
    },
    timeElapsed () {
      if (this.feedDetail && 'processed_at' in this.feedDetail) {
        const diff = this.$dates.utc().diff(this.$dates.utc(this.feedDetail.processed_at), 'second')
        if (Number(diff) > 0) {
          return Math.floor(Number(diff / 60))
        }
      }
      return 0
    }
  },
  data () {
    return {
      loadingProposal: true,
      loadingCompatibility: true,
      loadingFeed: true,
      revaluatingCompatibility: false,
      proposalDetail: null,
      feedDetail: null,
      rulesets: [],
      showDetails: false,
      saving: false,
      errors: {},
      retries: 0,
      rule: null,
      showImportFeedModal: false,
      showImportHelpModal: false,
      showVariantsModal: false
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Progress')
    },
    goForward () {
      this.$emit('navigate', 'ProposalSubmitted')
    },
    loadProposal (silent = false) {
      if (!silent) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      this.$http.get(endpoint).then(response => {
        this.proposalDetail = response.data
        this.loadingProposal = false
      })
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.feedDetail = response.data
        this.loadingFeed = false
      })
    },
    loadCompatibility (silent = false) {
      if (!silent) this.loadingCompatibility = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/rulesets-summary/'
      this.$http.get(endpoint).then(response => {
        this.rulesets = response.data
        this.loadingCompatibility = false
      })
    },
    rerunCompatibility () {
      this.revaluatingCompatibility = true
      this.retries++
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/rerun-compatibility/'
      this.$http.put(endpoint).then(response => {
        setTimeout(() => {
          this.revaluatingCompatibility = false
          this.loadCompatibility(false)
        }, 5000)
      })
    },
    inspectRule (rule) {
      this.rule = rule
      this.showVariantsModal = true
    },
    submitProposal () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'proposed'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal submitted!' })
        this.$router.push({ name: 'proposals.dashboard' })
      })
    },
    archiveProposal () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal archived!' })
        this.$router.push({ name: 'catalog' })
      })
    },
    refresh () {
      this.loadProposal()
      this.loadCompatibility()
    }
  },
  mounted () {
    this.loadProposal()
    this.loadFeed()
    this.loadCompatibility()
  },
  props: {
    feed: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
