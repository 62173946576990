<template>
<form @submit.prevent="save">
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <span v-if="mapping">Edit Template Mapping: {{ mapping.title }}</span>
        <span v-else>Add Template Mapping</span>
      </p>
    </header>
    <section class="modal-card-body">
      <div v-if="!loading" class="">
        <b-field label="Template Column Header">
          <b-input v-model="title" expanded />
        </b-field>
        <b-field label="Platform Attribute">
          <b-autocomplete
            v-model="search"
            placeholder="Select Attribute..."
            :keep-first="true"
            :open-on-focus="true"
            :data="filteredAttributes"
            field="name"
            @select="setAttribute"
            :clearable="true"
            expanded>
          </b-autocomplete>
        </b-field>
        <b-field label="Target">
          <b-select v-model="level" expanded>
            <option value="product">Parent</option>
            <option value="variant">Item</option>
          </b-select>
        </b-field>
        <div class="mb-lg">
          <b-field label="Priority">
            <b-input v-model="priority" expanded />
          </b-field>
        </div>
        <b-field>
          <b-checkbox v-model="isRequired">Mark as required?</b-checkbox>
        </b-field>
      </div>
      <!-- Loading -->
      <div v-else>
        <loading message="Loading Attributes..." />
      </div>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        native-type="submit"
        type="is-primary"
        :disabled="saving"
        :loading="saving"
        >Save</b-button>
    </footer>
  </div>
</form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    filteredAttributes () {
      if (!this.search) return this.attributes
      return this.attributes.filter(option => {
        return (option.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
      })
    },
    loading () {
      return (this.loadingAttributes)
    },
    ready () {
      return (this.attribute && this.level && this.priority && this.isRequired)
    },
    endpoint () {
      const base = this.context.route + '/templates/' + this.template.id + '/mappings/'
      return (this.mapping)
        ? base + this.mapping.id + '/'
        : base
    },
    method () {
      return (this.mapping) ? 'patch' : 'post'
    },
    body () {
      return {
        attribute: { id: this.attribute.id },
        title: this.title,
        priority: this.priority,
        target: this.level,
        is_required: this.isRequired
      }
    }
  },
  data () {
    return {
      loadingAttributes: true,
      attributes: [],
      search: null,
      title: null,
      attribute: null,
      level: 'variant',
      priority: null,
      isRequired: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadAttributes () {
      this.loadingAttributes = true
      const endpoint = this.api.baseRoute + '/attributes/'
      const query = {
        params: { common: 1, pagination: 0 }
      }
      this.$http.get(endpoint, query).then(response => {
        this.attributes = response.data.results
        this.loadingAttributes = false
      })
    },
    setAttribute (attribute = null) {
      this.attribute = attribute
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Mapping saved' })
        this.$emit('mapping:saved')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadAttributes()
    if (this.mapping) {
      this.search = this.mapping.attribute.name
      this.title = this.mapping.title
      this.attribute = this.mapping.attribute
      this.priority = this.mapping.priority
      this.level = this.mapping.target
      this.isRequired = this.mapping.is_required
    }
  },
  props: {
    template: {
      type: Object,
      required: true
    },
    mapping: {
      type: [Object, null]
    }
  }
}
</script>
