import { render, staticRenderFns } from "./Edi.vue?vue&type=template&id=59864dea&"
import script from "./Edi.vue?vue&type=script&lang=js&"
export * from "./Edi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports