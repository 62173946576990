<template>
  <a @click.prevent="downloadAs" class="has-text-weight-bold has-text-info">
    {{ getLabel }}
  </a>
</template>

<script>
export default {
  computed: {
    getLabel () {
      if (this.label) {
        return this.label
      } else {
        return this.$utils.upperFirst(this.originalFileName)
      }
    }
  },
  methods: {
    downloadAs () {
      const cloudFileName = this.cloudFileName
      const originalFileName = this.originalFileName
      this.$http.get(cloudFileName, {
        responseType: 'blob' // important
      }).then(response => {
        const blob = new Blob([response.data], { type: response.data.type })
        const cloudFileName = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = cloudFileName
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setTimeout(() => window.URL.revokeObjectURL(cloudFileName), 0)
      }).catch(error => {
        let errorMessage = 'An unexpected error occurred!'
        if (error.response && error.response.data.detail) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      })
    }
  },
  props: {
    label: {
      type: String,
      required: false
    },
    cloudFileName: {
      type: String,
      required: true
    },
    originalFileName: {
      type: String,
      required: true
    }
  }
}
</script>
