<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Export Items</p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-info" class="is-size-7">
        Please note that in case of search or filtered results, only items matching the criteria will be exported.
        You can choose the required template and apply an optional transformer on the export, if needed.
        The files will be delivered on your email.
      </b-message>
      <div class="mb-lg">
        <b-field
          label="Export Template"
          expanded>
          <TemplateSelect direction="export" datatype="products" v-model="template" />
        </b-field>
        <b-field
          label="Transformer"
          expanded>
          <TransformerSelect v-model="transformer" />
        </b-field>
      </div>
      <!-- Mappings -->
      <div v-if="template !== null" class="mt-lg">
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold has-text-black">Column Headers</p>
              <p class="has-text-grey-light is-size-7">
                <span v-if="loadingMappings">Loading mappings...</span>
                <span v-else-if="showMappings">Your file will contain the following {{ mappings.length }} column headers</span>
                <span v-else>Click 'show' to display the column headers on your export.</span>
              </p>
            </div>
            <div class="column is-narrow ">
              <a href="#" class="has-text-info" @click.prevent="showMappings = !showMappings" v-text="(showMappings) ? 'Hide Headers' : 'Show Headers'"></a>
            </div>
          </div>
        </div>
        <div v-if="!loadingMappings && mappings.length > 0 && showMappings">
          <div v-for="(mapping, index) in mappings" :key="mapping.id" >
            <div class="has-background-light rounded-lg px-md py-sm is-size-7" :class="(index !== 0) ? 'mt-xxs' : ''">
              <div class="columns is-vcentered">
                <div class="column">
                  <p class="has-text-weight-bold">
                    {{ mapping.title }}
                    <span v-if="mapping.is_required" class="has-text-danger has-text-weight-bold">*</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()" >Close</b-button>
      <b-button
        type="is-primary"
        @click="scheduleExport"
        :loading="exporting"
        :disabled="exporting || !ready"
      >Export</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TemplateSelect from '@/components/Models/Template/TemplateSelect'
import TransformerSelect from '@/components/Models/Transformers/TransformerSelect'
import Exportable from '@/mixins/Exportable'

export default {
  components: {
    TemplateSelect,
    TransformerSelect
  },
  computed: {
    ...mapGetters(['context']),
    exportEndpoint () {
      return this.context.route + '/variants/export/'
    },
    alwaysExport () {
      if (this.template === null) return {}
      const data = {
        template_id: this.template.id
      }
      if (this.transformer !== null) {
        data.transformer_ids = this.transformer.id
      }
      return data
    },
    ready () {
      return (this.template)
    }
  },
  data () {
    return {
      template: null,
      transformer: null,
      loadingMappings: false,
      mappings: [],
      showMappings: false
    }
  },
  methods: {
    loadMappings () {
      this.loadingMappings = true
      const endpoint = this.context.route + '/templates/' + this.template.id + '/mappings/'
      const body = {
        params: {
          pagination: 0
        }
      }
      this.$http.get(endpoint, body).then(response => {
        this.mappings = response.data.results
        if (response.data.length < 10) {
          this.showMappings = true
        }
        this.loadingMappings = false
      })
    }
  },
  mixins: [Exportable],
  props: {
    filters: {
      type: Object
    }
  },
  watch: {
    template (template) {
      if (template) {
        this.loadMappings()
      }
    }
  }
}
</script>
