<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div v-if="!loading && template !== null" class="pa-lg pb-xl">
            <!-- Template Name -->
            <div class="mb-lg">
              <b-field
                label="Template Name"
                :type="('name' in errors) ? 'is-danger' : ''"
                :message="('name' in errors) ? errors['name'][0] : ''">
                <b-input
                  name="name"
                  v-model="name"
                  @input="isDirty = true"
                ></b-input>
              </b-field>
            </div>
            <!-- Data Type & Direction -->
            <div class="columns">
              <div class="column is-half">
                <b-field label="Data Type">
                  <b-select
                    type="text"
                    v-model="dataType"
                    expanded
                    :disabled="true"
                    @input="isDirty = 1">
                    <option value="products">Products</option>
                    <option value="inventory">Inventory</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field label="Direction">
                  <b-select
                    type="text"
                    v-model="direction"
                    expanded
                    :disabled="true"
                    @input="isDirty = 1">
                    <option value="import">Import</option>
                    <option value="export">Export</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update Template' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    v-if="templateType !== 'shopify'"
                    type="is-light rounded-lg"
                    @click="downloadSample"
                  >Download Sample</b-button>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                    :loading="saving"
                  >Update Template</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return this.isDirty && this.name && this.dataType && this.direction
    },
    endpoint () {
      let endpoint = this.context.route + '/templates/' + this.template.id + '/'
      if (this.templateType === 'general') {
        return endpoint
      } else {
        endpoint = this.context.route + `/${this.templateType}/${this.$route.params.id}/templates/${this.$route.params.templateId}/`
        return endpoint
      }
    }
  },
  data () {
    return {
      isDirty: false,
      saving: false,
      name: null,
      dataType: null,
      direction: null,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const body = {
        name: this.name,
        data_type: this.dataType,
        direction: this.direction
      }
      this.$http.patch(this.endpoint, body).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Template Updated!' })
      }).finally(() => {
        this.saving = false
      })
    },
    downloadSample () {
      const link = document.createElement('a')
      link.target = '_blank'
      // Download sample location
      if (this.template.sample_location) {
        link.href = this.template.sample_location
        document.body.appendChild(link)
        link.click()
      } else {
        // Download template mapping, if sample location is not provided
        this.downloadTemplateMapping(link)
      }
    },
    downloadTemplateMapping (link) {
      const endpoint = this.api.baseRoute + '/' + this.context.route + '/templates/' + this.template.id + '/mappings/?format=xlsx'
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        link.href = url
        link.setAttribute('download', this.template.name + '.xlsx')
        document.body.appendChild(link)
        link.click()
        setTimeout(() => window.URL.revokeObjectURL(url), 0)
      })
    }
  },
  mounted () {
    this.name = this.template.name
    this.dataType = this.template.data_type
    this.direction = this.template.direction
  },
  props: {
    template: {
      type: Object,
      required: true
    },
    loading: {
      type: String
    },
    templateType: {
      type: String,
      enum: ['general', 'bigcommerce', 'shopify'],
      default: 'general'
    }
  }
}
</script>
