<template>
  <div>
    <b-select
      v-model="account"
      placeholder="Select Platform"
      expanded
      :loading="loading"
      :disabled="loading"
      @input="announce">
      <option v-for="account in accounts" :value="account" :key="account.id">{{ account.platform.name }}</option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      accounts: [],
      account: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/platform-accounts/'
    },
    query () {
      if (this.type === null) return null
      return {
        params: {
          platform: 'shopify,fabric'
        }
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.accounts = response.data.results
        if (this.accounts.length === 1) {
          this.account = this.accounts[0]
          this.announce()
        }
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.account)
    }
  },
  mounted () {
    this.load()
    if (this.value) {
      this.account = this.value
    }
  },
  props: ['type', 'value']
}
</script>
