<template>
  <div>
    <!-- Content -->
    <div class="has-text-grey ">
      <div class="has-text-centered">
        <p>...and congratulations on your new connection with </p>
        <p class="mb-md is-size-5"><strong>{{ connection.retailer.name }}!</strong></p>
      </div>
      <p class="mb-md">
        To get started, we need to complete our initial onboarding experience - at this
        stage, we just have a couple of questions.
      </p>
      <p class="mb-md">
        After you complete our initial questionnaire, we'll officially kick off onboarding
        with an introductory call to orient you
        to our {{ app.productName }}, introduce your support team, demonstrate our capabilities, and guide you through
        your onboarding options.
      </p>
      <p class="mb-md">
        Our goal is to design the most efficient
        workflow for your organization so that your business with {{ connection.retailer.name }}
        can launch and scale efficiently.
      </p>
      <p class="mb-md">
        Let's get started!
      </p>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            :disabled="true"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Get Started</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return Boolean(this.loadingTasks)
    }
  },
  data () {
    return {
      loadingTasks: true,
      tasks: [],
      saving: false
    }
  },
  methods: {
    loadTasks () {
      this.loadingTasks = true
      const endpoint = this.context.route + '/onboarding-tasks/?type=conditional'
      this.$http.get(endpoint).then(response => {
        this.tasks = response.data.results
        this.loadingTasks = false
      })
    },
    addTask (taskCode) {
      const task = this.$utils.find(this.tasks, ['code', taskCode])
      if (typeof task !== 'undefined') {
        const endpoint = this.context.route + '/assigned-tasks/'
        const json = { onboarding_task: { id: task.id } }
        return this.$http.post(endpoint, json)
      }
    },
    goBack () {

    },
    async goForward () {
      this.saving = true
      if (this.connection.level === 'products_only') {
        await this.addTask('terms-and-conditions')
      }
      if (this.connection.level === 'transactions_and_products') {
        await this.addTask('integration')
        await this.addTask('notifications')
      }
      this.saving = false
      this.$emit('navigate', 'Experience')
    }
  },
  mounted () {
    this.loadTasks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
