import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'lan',
  tooltip: 'Filter products by connection.'
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'calendar-outline',
  tooltip: 'Filter products based on when they were last updated.',
  options: dates
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter products based on when they were created.',
  options: dates
}

export const isOnShopify = {
  label: 'Is Synced with Shopify',
  key: 'is_on_shopify',
  icon: 'autorenew',
  tooltip: 'Filter products based on whether or not they have been synced with Shopify.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const isOnBigCommerce = {
  label: 'Is Synced with Big Commerce',
  key: 'is_on_bigcommerce',
  icon: 'autorenew',
  tooltip: 'Filter products based on whether or not they have been synced with Big Commerce.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}
