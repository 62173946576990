<template>
  <div>
    <b-button
      :size="size"
      :loading="saving"
      :disabled="!ready || saving"
      @click="approveRequest"
    >Approve</b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    ready () {
      return this.request.status === 'pending'
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    approveRequest () {
      this.saving = true
      const endpoint = this.context.route + '/feed-requests/' + this.request.id + '/approve/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Import request approved! Please allow up to 15 mins for your feed to process.' })
        this.$emit('request:saved')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    size: {
      type: String,
      default: null
    },
    request: {
      type: Object,
      required: true
    }
  }
}
</script>
