<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Products" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Products</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <ProductFilters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                  <option value="parent_name">Name (A-Z)</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-dropdown position="is-bottom-left">
                <button class="button is-medium" slot="trigger">
                  <b-icon icon="dots-vertical"></b-icon>
                  <span>More</span>
                </button>
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent="showExportShopifyModal = true">Download Shopify Export</a>
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <router-link :to="{ name: 'catalog.import.products' }">Import Product Data</router-link>
                </b-dropdown-item>
                <b-dropdown-item has-link v-if="context.isSupplier()">
                  <router-link :to="{ name: 'catalog.import.inventory' }">Update Inventory</router-link>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Toolbar -->
            <div class="has-background-white rounded-lg px-sm mb-xxs">
              <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                <!-- Dropdown -->
                <div class="column is-narrow">
                  <b-dropdown :disabled="loading || selected.length === 0">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Actions</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item has-link><a href="#" @click.prevent="showPublishProductsModal = true">Publish Products</a></b-dropdown-item>
                    <b-dropdown-item has-link v-if="context.isMerchant()"><a href="#" @click.prevent="showBulkApplyTransformModal = true">Apply Transformer</a></b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <!-- Counts -->
                    <p v-if="loading">Loading...</p>
                    <p v-else>{{ total }} products found.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Loaded -->
            <div v-if="!loading && results.length > 0">
              <div>
                <b-table
                  :data="results"
                  :checked-rows.sync="selected"
                  :mobile-cards="false"
                  :loading="loading || results.length === 0"
                  checkable
                  hoverable
                  class="is-size-7">
                  <template>
                    <!-- Identifiers -->
                    <b-table-column
                      label="Product"
                      cell-class="nowrap"
                      v-slot="props">
                      <Identifiers
                        :product="props.row"
                        :showImage="true"
                        nameInteraction="navigate"
                        imageInteraction="inspect"
                        @inspect="inspectProduct"
                        @navigate="navigateToDetail"
                      />
                    </b-table-column>
                    <!-- Supplier -->
                    <b-table-column
                      v-if="context.isMerchant()"
                      label="Supplier"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      width="200"
                      v-slot="props">
                      <p>{{ props.row.brand.name }}</p>
                    </b-table-column>
                    <!-- # Variants -->
                    <b-table-column
                      label="# Items"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="96"
                      v-slot="props">
                      <p>{{ props.row.num_variants }}</p>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
              <div class="mt-md">
                <b-pagination
                  :total="total"
                  :current.sync="currentPage"
                  :rounded="true"
                  :per-page="perPage"
                  @change="paginate">
                </b-pagination>
              </div>
            </div>
            <!-- No Results  -->
            <div v-else-if="!loading && results.length === 0">
              <div class="has-background-white rounded-xl has-text-grey-light has-text-centered py-3xl">
                <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
                <p>No products found.</p>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <div class="has-background-white rounded-lg pa-md mb-xxs" v-for="index in placeholders" :key="index">
                <div class="placeholder placeholder-title" style="width:66%"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Bulk Publish Orders -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showPublishProductsModal"
      :can-cancel="['x']"
      ><PublishProductsModal :products="selected" @reload="load" />
    </b-modal>
    <!-- Product Inspection Modal -->
    <b-modal
      :active="detail !== null"
      has-modal-card
      :can-cancel="['escape', 'x']"
      @close="detail = null" >
      <ProductModal :selected="detail" />
    </b-modal>
    <!-- Shopiy Export Modal -->
    <b-modal
      :active="showExportShopifyModal"
      has-modal-card
      :can-cancel="['escape', 'x']"
      @close="showExportShopifyModal = false" >
      <ShopifyExportModal :filterQuery="exportQuery" />
    </b-modal>
    <!-- Bulk: Apply transformer -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      scroll="keep"
      :active.sync="showBulkApplyTransformModal"
      :can-cancel=false
      ><ApplyTransformerModal :products="selected"/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Identifiers from '@/components/Models/Product/Identifiers'
import CdnTransform from '@/mixins/CdnTransform.js'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import ProductFilters from '../components/Products/ProductFilters'
import ProductModal from '@/components/Modals/Product/ProductModal'
import PublishProductsModal from '../components/Products/PublishProductsModal'
import ShopifyExportModal from '../components/Products/ShopifyExportModal'
import ApplyTransformerModal from '../components/Products/ApplyTransformerModal'

export default {
  components: {
    Identifiers,
    ProductFilters,
    ProductModal,
    ShopifyExportModal,
    ApplyTransformerModal,
    PublishProductsModal
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(15, this.perPage)
    },
    endpoint () {
      return this.context.route + '/products/'
    },
    json () {
      const ids = []
      this.selected.forEach(product => {
        ids.push(product.id)
      })
      return {
        product_ids: ids
      }
    }
  },
  data () {
    return {
      key: 'products',
      alwaysAttach: {
        short: 1,
        include_variantless: 1
      },
      selected: [],
      detail: null,
      showExportShopifyModal: false,
      showBulkApplyTransformModal: false,
      showPublishProductsModal: false
    }
  },
  methods: {
    inspectProduct (product) {
      this.detail = product
    },
    navigateToDetail (product) {
      this.$router.push({ name: 'catalog.product', params: { id: product.id } })
    },
    exportForShopify () {
      const endpoint = this.context.route + '/products/shopify-export/'
      this.exporting = true
      this.$http.get(endpoint, this.exportQuery).then(response => {
        this.exporting = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Export scheduled! Please allow 5-10 mins for delivery.' })
        this.showExportModal = false
      })
    }
  },
  mixins: [CdnTransform, Filterable, Exportable],
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
