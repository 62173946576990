<template>
  <div>
    <div class="pa-md">
      <!-- Task is loaded -->
      <div v-if="!loading && task !== null">
        <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">WooCommerce Integration</p>
        <div class="columns is-centered">
          <div class="column is-half-widescreen">
            <div class="box">
              <transition name="fade" mode="out-in">
                <component
                  :is="step"
                  :connection="connection"
                  :task="task"
                  :account="account"
                  @platform:saved="loadPlatformAccount(true)"
                  @navigate="navigate"
                ></component>
              </transition>
              <!-- Cancel Task Option -->
              <div>
                <p class="has-text-grey-light has-text-centered is-size-7">
                  This onboarding task was added based on your preferences. <br/>
                  If you no longer need this task, you can remove it
                  by <a href="#" @click.prevent="showDeleteTaskModal = true" class="has-text-info">clicking here.</a>
                </p>
              </div>
            </div>
            <!-- Return & Reset Links -->
            <div class="columns is-centered">
              <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7 column ml-sm" :class="!isCompleted && 'has-text-centered'">
                Back to Tasks
              </router-link>
              <a href="#" v-if="isCompleted" class="column is-size-7 has-text-right mr-sm" @click="showResetTaskModal=true">
                Reset Task
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading Skeleton -->
      <div v-else>
        <div class="mb-md">
          <div class="columns is-centered">
            <div class="column is-one-quarter">
              <b-skeleton />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box">
              <loading message="Loading..." />
            </div>
            <!-- Return Link -->
            <div class="has-text-centered">
              <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7">
                Back to Tasks
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Task Modal -->
    <b-modal
      v-if="!loading && task !== null"
      :has-modal-card="true"
      :active.sync="showDeleteTaskModal"
      :can-cancel="['x', 'esc']">
      <RemoveTaskModal :taskId="task.id" @task:removed="returnToTasks" />
    </b-modal>
    <!-- Reset Task Modal -->
    <b-modal
      v-if="!loading && task !== null"
      :has-modal-card="true"
      :active.sync="showResetTaskModal"
      :can-cancel="['x', 'esc']">
      <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
    </b-modal>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import RemoveTaskModal from '@/components/Models/Task/Modals/RemoveTaskModal'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
import Intro from '../components/WooCommerce/Intro'
import Install from '../components/WooCommerce/Install'
import Inventory from '../components/WooCommerce/Inventory'
import Orders from '../components/WooCommerce/Orders'
import Fulfillments from '../components/WooCommerce/Fulfillments'
import Confirmation from '../components/WooCommerce/Confirmation'

export default {
  components: {
    RemoveTaskModal,
    ResetTaskModal,
    Intro,
    Install,
    Inventory,
    Orders,
    Fulfillments,
    Confirmation
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return (this.loadingTask || this.loadingPlatformAccounts)
    },
    isCompleted () {
      return this.task && this.task.completed_at !== null
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingPlatformAccounts: true,
      task: null,
      account: null,
      step: 'Intro',
      showDeleteTaskModal: false,
      showResetTaskModal: false
    }
  },
  methods: {
    loadTask () {
      this.loadingTask = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.task = response.data
        this.loadingTask = false
      })
    },
    loadPlatformAccount (quiet = false) {
      if (!quiet) this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/?platform=woocommerce'
      this.$http.get(endpoint).then(response => {
        if (response.data.count > 0) {
          this.account = response.data.results[0]
        }
        console.log(this.account)
        this.loadingPlatformAccounts = false
      })
    },
    returnToTasks () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
      this.$buefy.toast.open({ type: 'is-info', message: 'Task removed.' })
    },
    navigate (step) {
      this.step = step
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  mounted () {
    this.loadTask()
    this.loadPlatformAccount()
  },
  props: ['connection']
}
</script>
