<template>
  <div>
    <Summary :start-date="startDate" :end-date="endDate" dimension="variant" :metric="metric"></Summary>
  </div>
</template>
<script>
import Summary from '@/modules/Performance/views/Summary.vue'
export default {
  data () {
    return {}
  },
  props: {
    startDate: Date,
    endDate: Date,
    metric: String
  },
  components: { Summary }
}
</script>
