<template>
  <div>
    <p class="has-text-centered is-size-3">{{ currentNum }}</p>
    <div class="is-size-4">
    <div v-if="name === 'score'">
      <div class="is-flex is-justify-content-center is-align-items-center">
        <b-icon :icon="this.currentNum > this.prevNum ? 'menu-up' : 'menu-down'"
                :class="getUpDownClass({ prevMonthData: this.prevNum, currentMonthData: this.currentNum })">
        </b-icon>
        <p :class="getUpDownClass({ prevMonthData: this.prevNum, currentMonthData: this.currentNum })">
          {{ getUpDownText({ prevMonthData: this.prevNum, currentMonthData: this.currentNum }) }}
        </p>
      </div>
    </div>
    <div v-if="name === 'rank'" class="columns">
      <p class="column is-half has-text-grey-light has-text-centered">Out of {{ this.chartData.count }}</p>
      <div class="column is-half has-text-centered">

        <div class="is-flex is-justify-content-center is-align-items-center has-text-grey-light">
          <b-icon :icon="this.currentNum < this.prevNum ? 'menu-up' : 'menu-down'"
                  :class="getUpDownClass({ prevMonthData: this.prevNum, currentMonthData: this.currentNum, reverse: true })">
          </b-icon>
          <p :class="getUpDownClass({ prevMonthData: this.prevNum, currentMonthData: this.currentNum, reverse: true })">
            {{ getUpDownText({ prevMonthData: this.prevNum, currentMonthData: this.currentNum, reverse: true }) }}
          </p>
        </div>
      </div>

      </div>
      <div class="is-flex is-justify-content-center is-align-items-center"
          @mouseover="isHoverNotes = true" @mouseleave="isHoverNotes = false">
        <line-chart
          :chart-data="graphObj"
          :options="options"
          :height="this.height || 250"
          :width="400"
        ></line-chart>
      </div>
      <div style="position: relative;">
        <!-- hover notes -->
        <!-- <div v-if="isHoverNotes" class="card p-3 is-size-7"
            style="width: 25vw; position: absolute; z-index: 1; top: -85px;"
            :style="(this.notePosition === 'right') ? { right: 0 } : { left: '-2rem' }">
          <p>{{ hoverNotes }}</p>
        </div> -->
      </div>
  </div>
  </div>
</template>

<script>

import LineChart from './LineChart'

export default {
  components: {
    LineChart
  },
  data () {
    return {
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      },
      prevMonth: 0,
      prevNum: 0,
      currentNum: 0,
      graphObj: {},
      isHoverNotes: false,
      hoverNotes: '',
      graphData: [],
      label: ''
    }
  },
  methods: {
    getSixMonthLabels () {
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const labelMonth = []
      for (let i = 0; i < 6; i++) {
        if (this.prevMonth - i < 0) {
          labelMonth.push(month[(this.prevMonth - i) + 12])
        } else {
          labelMonth.push(month[this.prevMonth - i])
        }
      }
      return labelMonth.reverse()
    },
    setData () {
      const data = []
      this.chartData.results.forEach(({ score, rank }) => {
        data.push((this.name === 'score') ? score : rank)
      })
      this.graphData = data
    },
    setUpChartInfo () {
      if (this.name === 'score') {
        this.options.scales.yAxes[0].ticks.min = 0
        this.label = 'scores'
        this.hoverNotes = 'Calculated monthly based on fulfillments within SLA, supplier at fault cancellations, invoicing within SLA, and supplier at fault replacements.'
        this.currentNum = parseFloat(this.chartData.results[this.chartData.results.length - 1].score)
        this.prevNum = parseFloat(this.chartData.results[this.chartData.results.length - 2].score)
      } else if (this.name === 'rank') {
        this.options.scales.yAxes[0].ticks.stepSize = 1
        this.options.scales.yAxes[0].ticks.reverse = true
        this.options.scales.yAxes[0].ticks.min = 1
        this.label = 'ranks'
        this.hoverNotes = 'Calculated monthly based on relative compliance scores across suppliers. The largest compliance score receives the top supplier rank.'
        this.currentNum = this.chartData.results[this.chartData.results.length - 1].rank
        this.prevNum = this.chartData.results[this.chartData.results.length - 2].rank
      }
    },
    graphInit () {
      this.prevMonth = this.month - 1
      const labelMonth = this.getSixMonthLabels()
      this.position = this.notePosition === 'right' ? { right: 0 } : { left: '2rem' }
      this.setData()
      this.setUpChartInfo()
      this.graphObj = {
        labels: labelMonth,
        datasets: [
          {
            label: this.label,
            data: this.graphData,
            fill: false,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      }
    },
    getUpDownClass ({ prevMonthData, currentMonthData, reverse }) {
      return {
        'has-text-danger': reverse ? prevMonthData < currentMonthData : prevMonthData > currentMonthData,
        'has-text-success': reverse ? prevMonthData > currentMonthData : prevMonthData < currentMonthData
      }
    },
    getUpDownText ({ prevMonthData, currentMonthData, reverse }) {
      const text = (prevMonthData === currentMonthData) ? '' : (reverse ? prevMonthData > currentMonthData : prevMonthData < currentMonthData) ? 'Up' : 'Down'
      const byNum = (prevMonthData < currentMonthData) ? (currentMonthData - prevMonthData) : (prevMonthData - currentMonthData)
      let byNumFormatted
      if (reverse) {
        byNumFormatted = this.$numbers(byNum).format({ mantissa: 0 })
      } else {
        byNumFormatted = this.$numbers(byNum).format({ mantissa: 2 })
      }
      return `${text} ${byNumFormatted}`
    }
  },
  mounted () {
    this.graphInit()
  },
  props: {
    name: {
      type: String,
      required: true
    },
    month: {
      type: Number,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    height: {
      type: Number
    },
    notePosition: {
      type: String
    }
  }
}
</script>
