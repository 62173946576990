<template>
  <div>
    <!-- Nav Bar -->
    <nav class="navbar">
      <div class="container">
        <div class="navbar-brand">
          <router-link :to="{ name: 'home'}" class="navbar-item" style="background-color:transparent !important" exact>
            <!-- Fabric Logo -->
            <Fabric v-if="app.platformCode === 'fabric'" color="white" :width="80" :height="26" />
            <!-- RC Logo -->
            <img v-else src="https://res.cloudinary.com/revcascade/image/upload/h_32,c_pad/static/logos/logo.png" height="16" style="height:16px">
          </router-link>
          <div class="navbar-burger burger" @click="$store.dispatch('hamburger')" :class="{'is-active': app.hamburger }" data-target="appnav">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="appnav" class="navbar-menu" :class="{'is-active': app.hamburger }">
          <!-- Left Side -->
          <div class="navbar-start">
            <!-- Retailer Nav -->
            <template v-if="context !== null && context.type === 'retailer'">
              <router-link v-if="hasTransactions" :to="{ name: 'orders.dashboard' }" class="navbar-item">Orders</router-link>
              <router-link :to="{ name: 'catalog.index' }" class="navbar-item">Products</router-link>
              <router-link :to="{ name: 'connections' }" class="navbar-item">Suppliers</router-link>
              <div v-if="hasTransactions" class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">Reports</a>
                <div class="navbar-dropdown">
                  <router-link :to="{ name: 'reports.performance.scorecard' }" class="navbar-item">Sales Reporting</router-link>
                  <router-link :to="{ name: 'reports.compliance.scorecard' }" class="navbar-item">Compliance</router-link>
                  <router-link v-if="auth.isStaff" :to="{ name: 'reports.compliance.score' }" class="navbar-item">Compliance Scorecard</router-link>
                  <router-link v-if="auth.isStaff" :to="{ name: 'payments' }" class="navbar-item">Payments</router-link>
                </div>
              </div>
              <!-- <router-link v-if="auth.isStaff" :to="{ name: 'payments' }" class="navbar-item"> Payments </router-link> -->
              <router-link v-if="!context.partner.isOnboarded" :to="{ name: 'retailer.onboarding' }" class="navbar-item has-text-success">Onboard</router-link>
            </template>
            <!-- Supplier Nav -->
            <template v-if="context !== null && context.type === 'vendor'">
              <router-link v-if="hasTransactions" :to="{ name: 'orders.dashboard' }" class="navbar-item">Orders</router-link>
              <router-link :to="{ name: (app.platformCode === 'fabric') ? 'catalog' : 'catalog.index' }" class="navbar-item">Products</router-link>
              <router-link :to="{ name: 'connections.list' }" class="navbar-item">Merchants</router-link>
              <div v-if="hasTransactions" class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">Reports</a>
                <div class="navbar-dropdown">
                  <router-link :to="{ name: 'reports.compliance.score' }" class="navbar-item">Compliance Scorecard</router-link>
                </div>
              </div>
              <router-link v-if="showOnboardingTab" :to="{ name: 'onboarding' }" class="navbar-item has-text-success">Onboard</router-link>
            </template>
          </div>
          <!-- Right Side -->
          <div class="navbar-end">
            <!-- Checking User -->
            <div v-if="auth.identifying" class="navbar-item">
              <b-icon icon="loading" custom-class="mdi-spin"></b-icon>
            </div>
            <!-- Support Link -->
            <b-dropdown v-if="context !== null && app.platformCode === 'fabric'" position="is-bottom-left">
              <a class="navbar-item" slot="trigger">
                <b-icon icon="lifebuoy" class="has-text-white pr-md" />
                Support
                <b-icon icon="menu-down"></b-icon>
              </a>
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="$store.dispatch('supportRequest')">File a Ticket</a>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <a :href="(context.isMerchant()) ? app.support.merchant.url : app.support.supplier.url" target="_blank">Visit Knowledge Base</a>
              </b-dropdown-item>
            </b-dropdown>
            <!-- Context -->
            <b-dropdown v-if="context !== null" position="is-bottom-left">
              <a class="navbar-item" slot="trigger">
                <span v-if="app.demoMode">Demo Account</span>
                <span v-else>{{ context.partner.name }}</span>
                <b-icon icon="menu-down"></b-icon>
              </a>
              <b-dropdown-item custom>
                <p class="text-grey">
                  <span v-if="app.demoMode">Demo Account</span>
                  <span v-else>{{ context.partner.name }}</span>
                </p>
                <p class="has-text-grey-light text-grey">{{ context.label }} ID: {{ context.partner.id }}</p>
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'settings' }" exact>{{ (context.type === 'vendor') ? 'Supplier' : 'Merchant'}} Settings</router-link>
                <router-link v-if="context.isSupplier()" :to="{ name: 'onboarding' }" exact>Onboarding</router-link>
                <router-link v-if="context.isMerchant()" :to="{ name: 'retailer.onboarding' }" exact>Onboarding</router-link>
                <router-link :to="{ name: 'jobs.list' }" exact>Jobs Report</router-link>
                <router-link :to="{ name: 'exceptions.list' }" exact>Exceptions Report</router-link>
                <router-link :to="{ name: 'settings.notifications' }" exact>Notifications</router-link>
              </b-dropdown-item>
              <template v-if="auth.hasContexts">
                <hr class="dropdown-divider">
                <b-dropdown-item has-link>
                  <router-link :to="{ name: 'context' }" exact>Change Context</router-link>
                </b-dropdown-item>
              </template>
              <template v-if="auth.isStaff">
                <hr class="dropdown-divider">
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent v-clipboard:copy="context.partner.id"  v-clipboard:success="copied">Copy ID</a>
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent v-clipboard:copy="context.partner.name"  v-clipboard:success="copied">Copy Name</a>
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent v-clipboard:copy="context.partner.code"  v-clipboard:success="copied">Copy Code</a>
                </b-dropdown-item>
              </template>
            </b-dropdown>
            <!-- User checked, but unknown... -->
            <router-link :to="{name: 'auth.login'}" v-if="auth.ready && !auth.identifying && auth.isGuest" class="navbar-item">Sign In</router-link>
            <router-link :to="{name: 'register'}" v-if="auth.ready && !auth.identifying && auth.isGuest" class="navbar-item">Register</router-link>
            <!-- User checked and user is known -->
            <b-dropdown v-if="auth.ready && auth.user !== null" position="is-bottom-left">
              <a class="navbar-item" slot="trigger">
                <span class="">Hi, {{ auth.user.firstName }}</span>
                <b-icon icon="menu-down"></b-icon>
              </a>
              <template v-if="auth.isStaff">
                <b-dropdown-item custom>
                  <p class="mb-sm">Presentation Mode</p>
                  <div class="field">
                    <b-switch
                      v-model="presentationMode"
                      true-value="On"
                      false-value="Off">
                      {{ presentationMode }}
                    </b-switch>
                  </div>
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>
              <b-dropdown-item custom>
                <p><span>{{ auth.user.fullName }}</span></p>
                <p><span class="has-text-grey-light">{{ auth.user.email }}</span></p>
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'me' }" exact>User Settings</router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'me.preferences' }" exact>User Preferences</router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'me.notifications' }" exact>User Notifications</router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="$store.dispatch('logout')">Logout</a>
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item custom>
                <span class="has-text-grey-light">App Version {{ app.version }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Fabric from '@/components/Logos/Fabric'
export default {
  components: {
    Fabric
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    presentationMode: {
      get () {
        return (this.app.demoMode) ? 'On' : 'Off'
      },
      set (value) {
        this.$store.dispatch('toggleDemoMode', (value === 'On'))
      }
    },
    hasTransactions () {
      return Boolean(this.auth.productMode === 'transactions_and_products')
    }
  },
  data () {
    return {
      loadingAnnouncements: false,
      loadingConnections: false,
      showOnboardingTab: false
    }
  },
  methods: {
    copied () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
    },
    showReleaseNotes () {
      this.$emit('toggle:release')
    },
    checkForNewConnections () {
      this.loadingConnections = true
      const endpoint = this.context.route + '/connections/'
      const query = {
        params: {
          fields: 'id',
          status: 'onboarding'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.count > 0) {
          const connections = []
          response.data.results.forEach(connection => {
            // if (connection.status === 'onboarding' && connection.onboarded_at === null) {
            if (connection.status === 'onboarding') {
              connections.push(connection)
            }
          })
          if (connections.length > 0) {
            this.showOnboardingTab = true
            // don't do anything if we're on onboarding pages...
            if (this.$route.path.includes('onboarding')) {
              return
            }
            if (this.$route.path === '/orders') {
              this.$emit('toggle:onboarding', connections)
            }
          }
        }
      }).finally(() => {
        this.loadingConnections = false
      })
    }
  },
  mounted () {
    if (this.context.isSupplier()) {
      this.checkForNewConnections()
    }
  }
}
</script>
