<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Return Proposal</p>
    </header>
    <section class="modal-card-body">
      <p class="py-xl has-text-centered">
        Are you sure you want to return this proposal?
      </p>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="saving"
        :loading="saving"
        @click="save">Return Proposal</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'revised'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal returned!' })
        this.$parent.close()
        this.$emit('proposal:revised')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['proposal']
}
</script>
