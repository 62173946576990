<template>
<div>
  <div>
    <div v-if="!loadingFeed && detail !== null">
      <div class="mb-lg">
        <Progress :feed="detail" size="is-large" :emitRefresh="true" @refresh="loadFeed(true)" />
      </div>
      <!-- Feed Details -->
      <div class="mb-lg">
        <p class="has-text-weight-bold has-text-centered mb-sm">Import Results</p>
        <div v-if="showDetails">
          <Results :feed="detail" />
        </div>
      </div>
    </div>
    <div v-else>
      <loading message="Loading import details..." />
    </div>
    <!-- Navigate -->
    <div class="pt-lg mb-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready"
            @click="goForward"
          >Return to Dashboard</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Convert to Proposal -->
  <b-modal
    has-modal-card
    :active.sync="showCreateProposalModal"
    :can-cancel="['escape', 'x']"
    ><CreateProposalFromImportModal :feed="detail" />
  </b-modal>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Progress from '@/components/Models/FeedQueue/Presenters/Progress'
import Results from '@/components/Models/FeedQueue/Presenters/Results'
import CreateProposalFromImportModal from './CreateProposalFromImportModal'

export default {
  components: {
    Progress,
    Results,
    CreateProposalFromImportModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.detail && this.detail.status === 'success')
    }
  },
  data () {
    return {
      loadingFeed: true,
      detail: null,
      showDetails: true,
      showCreateProposalModal: false
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.$router.push({ name: 'catalog' })
      // this.showCreateProposalModal = true
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.detail = response.data
        this.loadingFeed = false
      })
    }
  },
  mounted () {
    this.loadFeed()
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  }
}
</script>
