<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Platform Order</p>
    </header>
    <section class="modal-card-body">
      <!-- Platform Not Capable -->
      <div v-if="!endpoint || !query">
        <b-message type="is-warning">
          We do not support the ability to lookup Order ID {{ order.brand_identifier }}
          in {{ platformAccount.platform.name }} account #{{ platformAccount.id }}.
        </b-message>
      </div>
      <!-- Order Found -->
      <div v-else-if="!loading && platformOrder !== null">
        <!-- Disclaimer -->
        <b-message type="is-info">
          The data below represents this order as it exists in {{ platformAccount.platform.name }}.
        </b-message>
        <!-- Order Details -->
        <p class="has-text-weight-bold border-b pb-sm">Order Details</p>
        <table class="table is-narrow is-size-7 is-fullwidth is-striped">
          <tbody>
            <tr>
              <th style="width:256px">Platform</th>
              <td class="has-text-right">{{ platformAccount.platform.name }}</td>
            </tr>
            <tr>
              <th>{{ platformAccount.platform.name }} Order ID</th>
              <td class="has-text-right">{{ platformOrder.id }}</td>
            </tr>
            <tr>
              <th>{{ platformAccount.platform.name }} Order Number</th>
              <td class="has-text-right">{{ platformOrder.order_number }}</td>
            </tr>
            <tr>
              <th>Order Date</th>
              <td class="has-text-right">{{ $dates(platformOrder.created_at).local().format('MMM D, YYYY @ H:MM A') }}</td>
            </tr>
            <tr>
              <th>Tags</th>
              <td class="has-text-right">{{ platformOrder.tags }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Order Line Details -->
        <div class="mb-lg">
          <p class="has-text-weight-bold border-b pb-sm">Order Lines</p>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped is-vcentered">
            <tbody>
              <tr>
                <th>Line</th>
                <th style="width:128px" class="has-text-centered">Fulfillable Qty</th>
                <th style="width:64px" class="has-text-centered">Price</th>
              </tr>
              <tr v-for="line in platformOrder.line_items" :key="line.id">
                <td>
                  <p class="has-text-weight-bold">{{ line.name }}</p>
                  <div class="has-text-grey is-size-8">
                    <p>SKU {{ (line.sku) ? line.sku : '- Not Set -' }}</p>
                    <p>Variant ID: {{ line.variant_id }}</p>
                    <p>Line ID: {{ line.id }}</p>
                  </div>
                </td>
                <td style="width:96px" class="has-text-centered">{{ line.fulfillable_quantity }}</td>
                <td  class="has-text-centered"><Currency :value="line.price" /> </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Shipment Line Details -->
        <div class="mb-lg">
          <p class="has-text-weight-bold border-b pb-sm">Fulfillments</p>
          <div v-for="(fulfillment, index) in platformOrder.fulfillments" :key="fulfillment.id">
            <table class="table is-narrow is-size-7 is-fullwidth is-striped is-vcentered">
              <tbody>
                <tr>
                  <th>Fulfillment # {{ index + 1}}</th>
                  <th class="has-text-centered">Qty</th>
                </tr>
                <tr v-for="line in fulfillment.line_items" :key="line.id">
                  <td>
                    <p class="has-text-weight-bold">{{ line.name }}</p>
                    <div class="has-text-grey is-size-8">
                      <p>SKU {{ (line.sku) ? line.sku : '- Not Set -' }}</p>
                      <p>Variant ID: {{ line.variant_id }}</p>
                      <p>Line ID: {{ line.line_item_id }}</p>
                    </div>
                  </td>
                  <td class="has-text-centered">{{ line.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Cancels / Refunds -->
        <div class="mb-lg">
          <p class="has-text-weight-bold border-b pb-sm">Cancellations & Refunds</p>
          <div v-for="(refund, index) in platformOrder.refunds" :key="refund.id">
            <table class="table is-narrow is-size-7 is-fullwidth is-striped is-vcentered">
              <tbody>
                <tr>
                  <th>Refund # {{ index + 1}}</th>
                  <th class="has-text-centered">Qty</th>
                </tr>
                <tr v-for="line in refund.refund_line_items" :key="line.id">
                  <td>
                    <p class="has-text-weight-bold">{{ line.line_item.name }}</p>
                    <div class="has-text-grey is-size-8">
                      <p>SKU {{ (line.line_item.sku) ? line.line_item.sku : '- Not Set -' }}</p>
                      <p>Variant ID: {{ line.line_item.variant_id }}</p>
                      <p>Line ID: {{ line.line_item_id }}</p>
                    </div>
                  </td>
                  <td class="has-text-centered">{{ line.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Order Not Found -->
      <div v-else-if="!loading && platformOrder === null">
        <b-message type="is-warning">
          Order ID {{ order.brand_identifier }} could not be found
          in {{ platformAccount.platform.name }} account #{{ platformAccount.id }}.
        </b-message>
      </div>
      <!-- Loading -->
      <div v-else>
        <loading message="Loading..." />
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Close</b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (!this.platformAccount) return null
      switch (this.platformAccount.platform.code) {
        case 'shopify':
          return this.context.route + '/shopify/' + this.platformAccount.id + '/lookup-order/'
        default:
          return null
      }
    },
    query () {
      if (!this.platformAccount) return null
      if (this.context.isSupplier() && this.order.brand_identifier) {
        return { params: { order_number: this.order.brand_identifier } }
      }
      if (this.context.isMerchant() && this.order.retailer_order_number) {
        return { params: { order_number: this.order.retailer_order_number } }
      }
      return null
    },
    loading () {
      return Boolean(this.loadingPlatformOrder)
    }
  },
  data () {
    return {
      loadingPlatformOrder: true,
      platformOrder: null
    }
  },
  methods: {
    loadPlatformOrder () {
      this.loadingPlatformOrder = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.platformOrder = response.data.order
      }).catch(() => {
        this.platformOrder = null
      }).finally(() => {
        this.loadingPlatformOrder = false
      })
    }
  },
  mounted () {
    if (this.endpoint && this.query) {
      this.loadPlatformOrder()
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    platformAccount: {
      type: [Object, null]
    }
  }
}
</script>
