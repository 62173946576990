<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" current="List" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">
              <span v-if="context.isMerchant()">Suppliers</span>
              <span v-else>Merchants</span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <connection-filters @filter="filter" :applied-filters="filters" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="brand__name">Vendor (A-Z)</option>
              <option value="-brand__name">Vendor (Z-A)</option>
            </b-select>
          </div>
          <div class="column is-narrow" v-if="context.isMerchant()">
            <b-dropdown expanded position="is-bottom-left">
              <button class="button is-medium is-fullwidth" slot="trigger">
                <b-icon icon="dots-vertical"></b-icon>
                <span>More</span>
              </button>
              <b-dropdown-item has-link>
                <router-link :to="{ name: 'connection.locations' }" exact>Connection Locations</router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="is-size-7 has-text-grey-light">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 partner found</p>
              <p v-else>{{ total }} partners found</p>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="">
              <b-button size="is-small" @click="toggleView('list')" :disabled="view === 'list'" icon-left="format-list-bulleted"></b-button>
              <b-button size="is-small" @click="toggleView('grid')" :disabled="view === 'grid'" icon-left="grid"></b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div>
        <div v-if="view === 'list'">
          <b-table
            :data="results"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="rounded-lg is-size-7">
            <template>
              <!-- Name -->
              <b-table-column
                label="ID"
                cell-class="nowrap has-text-grey-light"
                width="96"
                v-slot="props">
                {{ props.row.id }}
              </b-table-column>
              <!-- Name -->
              <b-table-column
                :label="(context.isSupplier()) ? 'Merchant' : 'Supplier'"
                cell-class="nowrap"
                v-slot="props">
                <router-link class="has-text-info has-text-weight-bold" :to="{ name: 'connection.detail', params: { id: props.row.id } }">
                  <span v-if="context.isSupplier()">{{ props.row.retailer.name }}</span>
                  <span v-else>{{ props.row.brand.name }}</span>
                </router-link>
              </b-table-column>
              <!-- MKP-2811 remove Supplier column -->
              <!-- <b-table-column
                label="Supplier #"
                cell-class="nowrap"
                v-slot="props">
                <span v-if="props.row.retailer_identifier">{{ props.row.retailer_identifier }}</span>
                <span v-else class="has-text-grey-light is-italic">- Not Set -</span>
              </b-table-column> -->
              <!-- Recent Orders -->
              <b-table-column
                label="# Orders (Last 7)"
                header-class="nowrap is-hidden-mobile"
                cell-class="nowrap is-hidden-mobile"
                centered
                width="164"
                v-slot="props">
                <p>{{ props.row.orders_last_7_days_count }}</p>
              </b-table-column>
              <!-- Integration Type -->
              <b-table-column
                label="Integration"
                header-class="nowrap is-hidden-mobile"
                cell-class="nowrap is-hidden-mobile"
                centered
                width="200"
                v-slot="props">
                <Integration :connection="props.row" :showTooltip="false" />
              </b-table-column>
              <!-- Recent Orders -->
              <b-table-column
                label="Status"
                header-class="nowrap is-hidden-mobile"
                cell-class="nowrap is-hidden-mobile has-text-weight-bold"
                centered
                width="128"
                v-slot="props">
                <p v-if="props.row.status === 'active'" class="has-text-success">Active</p>
                <p v-else-if="props.row.status === 'onboarding'">
                  <span v-if="props.row.onboarding_status === 'complete'" class="has-text-warning">In Review</span>
                  <span v-else>Onboarding</span>
                </p>
                <p v-else class="has-text-grey-light">{{ $utils.upperFirst(props.row.status) }}</p>
              </b-table-column>
              <!-- Link to Detail -->
              <b-table-column
                label=""
                centered
                class="is-hidden-mobile"
                width="64"
                v-slot="props">
                <p class="has-text-centered">
                  <router-link :to="{ name: 'connection.detail', params: { id: props.row.id } }">
                    <b-icon icon="chevron-right" />
                  </router-link>
                </p>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No connections found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <!-- Grid View -->
        <div v-else class="my-lg">
          <div class="columns is-multiline">
            <div v-for="connection in results" :key="connection.id" class="column is-one-third">
              <router-link :to="{ name: 'connection.detail', params: { id: connection.id } }">
                <div class="has-background-white rounded-lg pa-md">
                  <div class="has-text-centered">
                    <div>
                      <img v-if="context.isSupplier()" :src="connection.retailer.logo_url"  height="60"  />
                      <img v-else :src="connection.brand.logo_url"  width="180" height="60"  />
                    </div>
                    <p class="has-text-centered  has-text-weight-bold">
                      <span v-if="context.isSupplier()">{{ connection.retailer.name }}</span>
                      <span v-else>{{ connection.brand.name }}</span>
                    </p>
                    <div class="has-text-grey is-size-7">
                      <span v-if="connection.retailer_identifier"># {{ connection.retailer_identifier }}</span>
                      <span v-else>--</span>
                    </div>
                    <div class="has-text-grey is-size-7 has-text-weight-bold">
                      <span v-if="connection.status === 'active'" class="has-text-success">Active</span>
                      <span v-else-if="connection.status === 'suspended'" class="has-text-grey-light">Suspended</span>
                      <span v-else>{{ $utils.upperFirst(connection.status) }}</span>
                      &bull;
                      <span class="has-text-weight-normal">
                        {{ connection.orders_last_7_days_count }} Orders</span>
                    </div>
                    <!-- <p><StatusPresenter :connection="connection" /></p> -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <p class="has-text-right is-size-7 has-text-grey-light">Order counts based on lasts 7 days</p>
        </div>
        <div class="mt-md">
          <b-pagination
            :total="total"
            :current.sync="currentPage"
            :rounded="true"
            :per-page="perPage"
            @change="paginate">
          </b-pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets/connections'
import Filterable from '@/mixins/Filterable'
import ConnectionFilters from '../components/List/ConnectionFilters'
import Integration from '@/components/Models/Connection/Presenters/Integration'

export default {
  components: {
    ConnectionFilters,
    Integration
  },
  data () {
    return {
      key: 'connections',
      orderBy: 'brand__name',
      presets: presets,
      // modals
      showInviteCodeModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      const breadcrumbs = {}
      if (this.context.isMerchant()) {
        breadcrumbs.history = [{ label: 'Suppliers', name: 'connections' }]
      } else {
        breadcrumbs.history = [{ label: 'Merchants', name: 'connections.list' }]
      }
      return breadcrumbs
    },
    placeholders () {
      return Math.min(5, this.perPage)
    },
    endpoint () {
      return this.context.route + '/connections/'
    }
  },
  methods: {
    addInviteCode () {

    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
